import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/sass/style.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from './Routes/routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-tooltip/dist/react-tooltip.css'
import 'flatpickr/dist/flatpickr.min.css';
import SellerSideBar from './View/SellerDashboard/SellerSideBar';
import axios from 'axios';
import { updateUser } from './Store/Slices/UserSlice';
import { frontEnd_API, header, storeCode, token } from './Config/Config';
import Loader from './Components/Loader';
import { storeLoader } from './Store/Slices/homeLoader';
import NotificationBell from './Components/NotificationBell';

function App() {
  const user = useSelector((state) => state.user.value);
  const localLoader = useSelector((state) => state.loader.value);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    console.log("localLoader::", localLoader);
    if (user) {
      dispatch(storeLoader(1))
      if (localLoader != 1) {
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
        }, 3000)
      }
      else {
        setLoader(false);
      }
    }
    else {
      setLoader(false);
    }
  }, [])

  useEffect(() => {
    if (!user) {
      const { pathname } = location;

      const isRestrictedPath =
        pathname.startsWith('/') ||
        pathname === '/create-store';

      const isSignUpOrSignInPage =
        pathname.endsWith('/signup') ||
        pathname.endsWith('/signin');

      if (isRestrictedPath && !isSignUpOrSignInPage) {
        navigate('/signin');
      }
    }
    else {
      const { pathname } = location;
      if (localLoader == 1 && (pathname.endsWith('/signup') || pathname.endsWith('/signin'))) {
        navigate('/');
      }
    }
  }, [user, location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showHeaderFooter = (!location.pathname.startsWith('/signin') && !location.pathname.startsWith('/signup') && user?.storeId !== 0 && !location.pathname.endsWith('/canvas') && !location.pathname.endsWith('/app/create-store'));
  const [childStateValue, setChildStateValue] = useState();

  const handleChildStateChange = (value) => {
    setChildStateValue(value);
  };

  const getWalletBalance = async () => {
    try {
      const { data } = await axios.get(frontEnd_API?.walletBalance, header)
      console.log("data::", data);
      dispatch(updateUser({ walletBalance: data?.data?.walletBalance }))
    }
    catch (e) {
      console.log("Err::", e);
    }
  }

  useEffect(() => {
    getWalletBalance();
  }, [])

  useEffect(() => {
    if (user) {
      if (user?.storeId === 0) {
        navigate('/app/create-store');
      }
    }
  }, [navigate, user]);

  return (
    <>
      {
        (loader) ?
          <div className='main-loader'>
            <Loader />
          </div>
          : <div className={(showHeaderFooter) && `d-lg-flex custom-dashboard-panel ${childStateValue ? 'is-collapsed' : ''}`}>
            {
              (showHeaderFooter) &&
              <div className='dashboard-panel-sidebar'>
                <SellerSideBar onStateChange={handleChildStateChange} />
              </div>
            }
            <div className={(showHeaderFooter) && 'dashboard-panel-content'}>
              <Routes>
                {
                  routes.map((item, index) => {
                    return (
                      <Route key={index} path={item.path} element={item.element} />
                    )
                  })
                }
              </Routes>
            </div>
            <NotificationBell />
          </div>
      }
    </>
  );
}

export default App;