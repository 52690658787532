import React, { useEffect } from 'react'
import { Col, Container, Placeholder, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API } from '../../Config/Config'
import { storeUser } from '../../Store/Slices/UserSlice'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addDomain } from '../../Store/Slices/DomainSlice'
import { PiStarFourFill } from "react-icons/pi";
import { CiAt } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";

function SigninPage() {
    const { domain } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localDomain = useSelector((state) => state.domain.value);

    useEffect(() => {
        if (location.pathname.startsWith('/from-home')) {
            navigate('/signin');
            console.log(domain);
            if (domain) {
                dispatch(addDomain(domain))
            }
        }
    }, [domain, location, navigate, dispatch])

    useEffect(() => {
        console.log("localDomain", localDomain);
    }, [localDomain])

    return (
        <div>
            <Container fluid className='d-grid align-items-center custom-signin-page'>
                <Row className='custom-signin-page-row'>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='p-4 p-md-5 custom-signin-page-img'>
                            <div className='custom-img-dark-color' />
                            <div className='custom-bg-dark-color' />
                            <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                            </a>
                            <div className='d-grid gap-4 content-holder'>
                                <h5 className='m-0 fw-semibold fs-sm-16 fs-md-18 '>
                                    “This software simplifies the website building process, making it a breeze to manage our online presence.”
                                </h5>
                                <div className='d-grid gap-1'>
                                    <h6 className='fw-semibold m-0 fs-sm-14 fs-md-16'>David Handerson</h6>
                                    <span className='fs-sm-12'>Founder & CEO</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='px-3 px-lg-5 py-5 custom-signin-page-form'>
                            <Container className='cs-container-sm d-grid gap-4 px-xl-5 custom-signin-content'>
                                <div className='custom-sticky-icons d-none d-md-block'>
                                    <CiAt className='first-icon' />
                                    <PiStarFourFill className='second-icon' />
                                    <IoDiamondOutline className='third-icon' />
                                    <PiStarFourFill className='fourth-icon' />
                                </div>
                                <h1 className='fw-bold m-0 text-center'>Sign in</h1>
                                <div className='flex-center-align px-sm-5 gap-2 btn-list'>
                                    <button className='git-btn'><i className="bi bi-github"></i></button>
                                    <button className='google-btn'><i className="bi bi-google"></i></button>
                                </div>
                                <span className='text-center p-color'>OR</span>
                                <div className='d-grid px-md-5'>
                                    <FormComponent
                                        action={frontEnd_API.signin}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={true}
                                        submitBTNAlign={'custom-submit-btn'}
                                        loadPage={true}
                                        button={'Log in'}
                                        loadFirst={localDomain ? true : false}
                                        formMessage={"Login Successfully !"}
                                        // reloadPageURL={(localDomain) ? '/app/create-store' : "/"}
                                        navigatePageURL={(localDomain) && '/app/create-store'}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'email',
                                                key: 'email',
                                                required: true,
                                                pattern: pattern.email,
                                                placeholder: "Your Email"
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                placeholder: "Password"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className='d-grid gap-3 pt-sm-3 pt-4'>
                                    <span className='fs-16 text-center'>
                                        Have no account yet? <Link className='text-decoration-none color-temp fw-semibold' to={'/signup'}>Sign up</Link>
                                    </span>
                                </div>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SigninPage