import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ErrorPage from '../../../Components/ErrorPage';
import { Accordion, Alert, Card, Col, Container, Form, ListGroup, OverlayTrigger, Row, Spinner } from 'react-bootstrap';
import { frontEnd_API, header, SERVER_URL, siteConfig, storeCode, token } from '../../../Config/Config';
import axios from 'axios';
import { GrBold } from "react-icons/gr";
import { GrItalic } from "react-icons/gr";
import { GrUnderline } from "react-icons/gr";
import { colorList, faceEmojis } from '../../../Data/localData';
import { arrayMove, List } from 'react-movable';
import { useDispatch, useSelector } from 'react-redux';
import { updateCanvas } from '../../../Store/Slices/canvasSlice';
import Redirection from '../../../Components/Redirection/Redirection';
import * as fabric from 'fabric'; // v6
import CanvasComponent from './CanvasComponent';
import { id } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip';

function ProductCanvas() {
  document.title = `Product Canvas | ${siteConfig?.name}`;

  const params = useParams();
  const location = useLocation();
  const localCanvas = useSelector((state) => state.canvas.value);
  const navigate = useNavigate();
  const [isRefresh, setIsRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [tabData, setTabData] = useState('product');
  const [variationData, setVariationData] = useState();
  const [productSideImage, setProductSideImage] = useState(null);
  const [productColorImage, setProductColorImage] = useState(null);
  const [tempTextValue, setTempTextValue] = useState({});
  const [canvasData, setCanvasData] = useState([]);
  const [currentCanvas, setCurrentCanvas] = useState(0);
  const [activeLayer, setActiveLayer] = useState(false);
  const dispatch = useDispatch();
  const [showPrice, setShowPrice] = useState();
  const [activePrice, setActivePrice] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [toggleStatus, setToggleStatus] = useState(false);
  const sliderRef = useRef();
  const [contentWidth, setContentWidth] = useState('inherit');
  const [deleteItem, setDeleteItem] = useState();
  const [canvasLoader, setCanvasLoader] = useState(true);

  // useEffect(() => {
  //   setTimeout(() =>
  //     console.log("sliderRef?.current?.offsetWidth::", sliderRef?.current?.clientWidth),
  //     1000
  //   )
  // }, [isRefresh])

  // useEffect(() => {
  //   setIsRefresh(!isRefresh)
  // }, [])

  useEffect(() => {
    if (location?.state?.data && !location?.state?.data?.editCanvas) {
      setData(location?.state?.data)
      const tempCode = location?.state?.data?.provider?.availableVariantData.find((e) => e.label === 'color');
      setProductColorImage(tempCode?.data[0].code)
    }
    else {
      axios.get(`${frontEnd_API.singleProduct}/${params.productId}`, header)
        .then((res) => {
          const providerCheck = res?.data?.data?.providerData.find((item) => item.providerId === parseInt(params.providerId));
          if (providerCheck) {
            const tempCode = providerCheck?.availableVariantData.find((e) => e.label === 'color');
            setProductColorImage(tempCode.data[0].code)
            setData({
              'product': res?.data?.data,
              'provider': providerCheck
            })
          }
        })
        .catch((e) => {
          console.log("err::", e);
        })
    }
  }, [location?.state?.data, params])

  useEffect(() => {
    if (data?.provider) {
      if (!location?.state?.data?.varientData) {
        for (let i = 0; i < data?.provider?.availableVariantData?.length; i++) {
          setVariationData((prevData) => ({
            ...prevData,
            [data?.provider?.availableVariantData[i].name]: [data?.provider?.availableVariantData[i].data[0]]
          }))
        }
      }
      setProductSideImage(data?.provider?.designSidesData[0]);
      if (location?.state?.data?.editCanvas) {
        setCanvasData(location?.state?.data?.editCanvas)
        console.log("location?.state?.data?.editCanvas::", location?.state?.data?.editCanvas);
      }
      else {
        const editableSides = data?.provider?.designSidesData?.map(e => ({ label: e?.label, value: e?.value, data: [], printingPrice: e?.designCharge, thumbnail: e?.thumbnail, ratioData: e?.ratioData }))
        setCanvasData([...editableSides])
      }
      setCurrentCanvas(0)
    }
  }, [data?.provider])

  useEffect(() => {
    if (location?.state?.data?.varientData) {
      const originalData = location?.state?.data?.varientData.reduce((acc, curr) => {
        acc[curr.name] = curr.data.map(({ isPrimary, ...rest }) => rest);
        return acc;
      }, {});
      setVariationData(originalData)
    }
  }, [location?.state?.data?.varientData])

  const handleVariationChange = (item) => {
    const { key, value } = item;
    setVariationData((prevData) => {
      let existingItems = [];
      if (variationData) {
        existingItems = prevData[key] || [];
      }

      // Check if the item already exists based on the 'value' property
      const itemExists = existingItems.some(
        (existingItem) => existingItem.value === value.value
      );

      let updatedItems;
      if (itemExists) {
        // Remove the item if it exists
        updatedItems = existingItems.filter(
          (existingItem) => existingItem.value !== value.value
        );
        if (prevData[key] && updatedItems.length === 0) {
          return prevData; // Return previous data without changes
        }
        setProductColorImage(updatedItems[updatedItems.length - 1].code);
      } else {
        // Add the item if it doesn't exist
        updatedItems = [...existingItems, value];
        setProductColorImage(value?.code);
      }
      return {
        ...prevData,
        [key]: updatedItems,
      };
    });
  };

  const handleCheckAll = (item = {}) => {
    if (item?.status) {
      setVariationData((prevData) => ({
        ...prevData,
        [item?.data?.name]: item?.data?.data
      }))
    }
    else {
      setVariationData((prevData) => ({
        ...prevData,
        [item?.data?.name]: [item?.data?.data[0]]
      }))
    }
  }

  const [dragOver, setDragOver] = useState(false);
  const fileInputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState();
  const [loader, setLoader] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      fileInputRef.current.files = files; // Set the dropped files to the file input
      const changeEvent = new Event('change', { bubbles: true });
      fileInputRef.current.dispatchEvent(changeEvent);
    }
  };

  const handleDragClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };


  // Handle Upload Data

  const [uploadData, setUploadData] = useState();

  const getUploadData = async () => {
    try {
      const { data } = await axios.put(frontEnd_API.document, {}, header)
      setUploadData(data?.data);
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUploadData();
  }, [])

  const handleFileChange = async (event) => {
    const files = event.target.files[0];

    if (files?.name?.length > 0) {

      const sizeValue = ((files?.size / 1024) / 1024)
      const trueSizeValue = sizeValue >= 2 && sizeValue <= 10;
      const fileType = files?.type;
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
      if (!trueSizeValue) {
        setErrorMsg('File size must between 2MB - 10MB')
        console.log('sizeValue is true ::', sizeValue);
      }
      if (!allowedTypes.includes(fileType)) {
        setErrorMsg('File type must be png, jpg or jpeg');
        console.log('sizeValue is true ::', fileType);
      }
      if (trueSizeValue && allowedTypes.includes(fileType)) {
        setLoader(true);
        try {
          const { data } = await axios.post(frontEnd_API.document,
            {
              link: files,
              type: 'img'
            }, {
            headers: {
              'token': token,
              'storecode': storeCode,
              'Content-Type': 'multipart/form-data'
            }
          })
          console.log(data);
          getUploadData();
          setLoader(false);
        }
        catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleDeleteImage = async (item) => {
    if (window.confirm("Are you sure delete image?")) {
      try {
        await axios.delete(`${frontEnd_API.document}/${item?.id}`, header)
        getUploadData();
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  // Layers Code
  const [newCanvasData, setNewCanvasData] = useState(null);

  const handleDraggingEnd = ({ oldIndex, newIndex }) => {
    const reversedData = canvasData[currentCanvas]?.data?.objects.slice().reverse();
    const updatedReversedData = arrayMove(reversedData, oldIndex, newIndex);
    const updatedData = updatedReversedData.slice().reverse();
    setCanvasData(prevData => {
      const updatedCanvasData = [...prevData];
      updatedCanvasData[currentCanvas] = {
        ...updatedCanvasData[currentCanvas],
        data: {
          objects: updatedData
        }
      };
      return updatedCanvasData;
    });
    setNewCanvasData(updatedData);
  };


  // 
  // Canvas code
  // 

  const handleTextChange = (item) => {
    setTempTextValue((prevData) => {
      const { value, label } = item;
      let newData;
      if (prevData?.id) {
        newData = {
          ...prevData,
          data: value,
          type: label
        };
      } else {
        const timestamp = Date.now();
        const randomNumber = Math.floor(Math.random() * 10000);
        const uniqueId = `${timestamp}${randomNumber}`;

        newData = {
          id: parseInt(uniqueId),
          type: label,
          data: value
        };
      }

      return newData;
    });
  };
  const [sendText, setSendText] = useState();

  const handleCanvasItems = (item) => {
    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 10000);
    const uniqueId = `${timestamp}${randomNumber}`;
    setSendText((prevData) => ({
      ...prevData,
      id: parseInt(uniqueId),
      type: item?.type,
      data: item?.data
    }));
  }


  const handleTextData = () => {
    const temp = tempTextValue;
    setSendText(temp);
    setTempTextValue();
  }

  useEffect(() => {
    setShowPrice([
      {
        label: "product price",
        value: data?.provider?.productPrice
      }
    ])
  }, [data?.provider?.productPrice])

  useEffect(() => {
    const newPriceData = canvasData
      .filter(row => row.data?.objects?.length > 0)
      .map(row => ({ label: row.label, value: row?.printingPrice }));
    setShowPrice([
      {
        label: "product price",
        value: data?.provider?.productPrice
      },
      ...newPriceData
    ]);
    console.log("canvasData::", canvasData);

  }, [toggleStatus, canvasData])

  useEffect(() => {
    let val = 0;
    for (let i = 0; i < showPrice?.length; i++) {
      val += showPrice[i].value;
    }
    setActivePrice(val);
  }, [showPrice, activePrice])


  useEffect(() => {
    setTimeout(() => {
      if (location?.state?.data?.canvasWidth) {
        setContentWidth(location?.state?.data?.canvasWidth)
      }
      else {
        setContentWidth(sliderRef?.current?.clientWidth)
      }
      setCanvasLoader(false)
    },
      500
    )

  }, [isRefresh]);

  useEffect(() => {
    setIsRefresh(!isRefresh);
  }, [])

  const handleResize = () => {
    console.log(`Current window width: ${window.innerWidth}`);
    // setToggle(false);
    setContentWidth(sliderRef?.current?.clientWidth)
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  // useEffect(() => {
  //   console.log("toggleStatus::", toggleStatus);
  // }, [toggleStatus])

  // useEffect(() => {
  //   console.log("canvasData From Canvas::", canvasData);
  // }, [canvasData])

  const handleCloseTab = () => {
    if (location?.state?.data?.updateProduct) {
      navigate(`/products`)
    }
    else if (location?.state?.data?.editCanvas) {
      navigate(`/product/${params?.productId}/${params?.providerId}/create-product`, {
        state: {
          data: {
            'canvas': canvasData,
            'product': data?.product,
            'provider': data?.provider,
            'variation': variationData,
            'priceList': showPrice,
            'canvasWidth': contentWidth,
            'updateProduct': location?.state?.data?.updateProduct ?? null
          }
        }
      })
    }
    else {
      navigate(`/product/${params?.productId}`)
    }
  }

  return (
    <div>
      {
        // (data) ?
          <>
            <Container fluid className='custom-canvas'>
              <Row className='custom-canvas-row'>
                {/* Canvas Header */}
                <Col className='col-12 p-3 custom-canvas-header'>
                  <div>
                    <h5 className='m-0 fw-semibold d-none d-md-block'>{data?.product?.name}</h5>
                    <h5 className='m-0 fw-semibold d-block d-md-none fs-18'>{data?.product?.name.substring(0, 12) + '...'}</h5>
                  </div>
                  <div className='flex-between-align justify-content-end pe-lg-3'>
                    {/* <div className='d-flex custom-undo-redo'>
                      <i
                        // onClick={handleUndo}
                        // disabled={historyStep === 0}
                        className="bi bi-arrow-counterclockwise p-2 fs-20 fs-md-16 fs-sm-14" />
                      <i
                        // onClick={handleRedo}
                        // disabled={historyStep === historyRef.current.length - 1} 
                        className="bi bi-arrow-clockwise p-2 fs-20 fs-md-16 fs-sm-14" />
                      <div className='flex-center-align px-2'>
                        <div className='custom-line'></div>
                      </div>
                    </div> */}
                    <div>
                      <span
                        className="bi bi-x-lg link-to-normal fs-20 fs-md-16 fs-sm-14"
                        style={{ cursor: 'pointer' }}
                        onClick={handleCloseTab}
                        data-tooltip-id="my-second"
                        data-tooltip-content="close"
                      />
                      <Tooltip id="my-second" place="left" />

                    </div>
                  </div>
                </Col>

                <Col className='col-12 p-0 custom-canvas-body'>
                  {/* Canvas Sidebar Data */}
                  <div className={`custom-canvas-body-sidebar ${(toggle) ? 'active-sidebar-content' : ''}`}>
                    <div className='custom-canvas-body-sidebar-header p-1 py-2 d-grid'>
                      <div
                        className={`custom-tab-list ${(tabData === 'product') && 'active-tab'}`}
                        onClick={() => {
                          setToggle(false);
                          setTabData('product')
                        }}
                      >
                        <i className={`bi fs-20 bi-tags`} />
                        <span className='fs-12 fw-semibold text-capitalize'>Product</span>
                      </div>
                      <hr className={`my-0`} />
                      <div
                        className={`custom-tab-list ${(tabData === 'text') && 'active-tab'}`}
                        onClick={() => {
                          setToggle(false);
                          setTabData('text')
                        }}
                      >
                        <i className={`bi fs-20 bi-fonts`} />
                        <span className='fs-12 fw-semibold text-capitalize'>text</span>
                      </div>
                      <div
                        className={`custom-tab-list ${(tabData === 'clipart') && 'active-tab'}`}
                        onClick={() => {
                          setToggle(false);
                          setTabData('clipart')
                        }}
                      >
                        <i className={`bi fs-20 bi-emoji-smile`} />
                        <span className='fs-12 fw-semibold text-capitalize'>clipart</span>
                      </div>
                      <div
                        className={`custom-tab-list ${(tabData === 'upload') && 'active-tab'}`}
                        onClick={() => {
                          setToggle(false);
                          setTabData('upload')
                        }}
                      >
                        <i className={`bi fs-20 bi-upload`} />
                        <span className='fs-12 fw-semibold text-capitalize'>Upload</span>
                      </div>
                      <div
                        className={`custom-tab-list ${(tabData === 'layers') && 'active-tab'}`}
                        onClick={() => {
                          setToggle(false);
                          setTabData('layers')
                        }}
                      >
                        <i className={`bi fs-20 bi-layers-half`} />
                        <span className='fs-12 fw-semibold text-capitalize'>layers</span>
                      </div>
                    </div>
                    <div className='custom-canvas-body-sidebar-content d-grid'>
                      <div
                        className='custom-toggle-btn'
                        onClick={() => setToggle(!toggle)}
                      >
                        <i className="bi bi-caret-right-fill" />
                      </div>
                      <div className={`custom-sidebar-all-content ${(!toggle) ? 'p-3' : ''}`}>
                        <div className='final-content-dis h-100'>
                          {
                            (tabData === 'product') &&
                            <div className='d-grid gap-4'>
                              {/* Product Info */}
                              <div className='d-grid gap-2'>
                                <div className='d-grid gap-1'>
                                  <h6 className='fw-semibold m-0'>{data?.product?.name}</h6>
                                  <span className='fs-14'>SKU : <span className='p-color'>{data?.product?.skuCode}</span></span>
                                </div>
                                <div className="d-grid">
                                  <span className='fs-14'>Provider Name : <span className='p-color'>{data?.provider?.providerName}</span></span>
                                  <span className='fs-14'>Production Time : <span className='p-color'>{data?.provider?.productionTime} Day</span></span>
                                </div>
                              </div>
                              <hr className='m-0' />
                              {/* Select Color Card */}
                              <div className='d-grid list-of-colors p-3'>
                                <span className='fw-semibold'>Select Color :</span>
                                <div className='d-flex gap-2 flex-wrap'>
                                  {
                                    data?.provider?.availableVariantData[1]?.data.map((item, index) => {
                                      return (
                                        <>
                                          <span
                                            style={{ backgroundColor: item?.code }}
                                            onClick={() => handleVariationChange({
                                              key: data?.provider?.availableVariantData[1].name,
                                              value: item
                                            })}
                                            data-tooltip-id="my-close"
                                            data-tooltip-content={`${item?.label} - ${item?.code}`}
                                          >
                                            <i className={`bi bi-check fs-25 ${variationData?.color?.find((e) => e.value === item?.value) ? 'd-flex' : 'd-none'}`} />
                                          </span>
                                          <Tooltip id="my-close" place="top" />
                                        </>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              {/* Select Size Card */}
                              <div className='d-grid p-3 list-of-sizes gap-2'>
                                <span className='fw-semibold'>Select Sizes :</span>
                                <label className='gap-1 flex-between-align justify-content-start'>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => handleCheckAll({ data: data?.provider?.availableVariantData[0], status: e.target.checked })}
                                  />
                                  Select all
                                </label>
                                <div className='d-flex gap-3 flex-wrap'>
                                  {
                                    data?.provider?.availableVariantData[0]?.data.map((item, index) => {
                                      return (
                                        <label className='fw-semibold gap-1 text-uppercase'>
                                          < input
                                            type="checkbox"
                                            checked={variationData?.size?.find((e) => e.value === item?.value) ? true : false
                                            }
                                            onChange={() => handleVariationChange({
                                              key: data?.provider?.availableVariantData[0].name,
                                              value: item
                                            })}
                                          />
                                          {item?.label}
                                        </label>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          }
                          {
                            (tabData === 'text') &&
                            <div className='d-grid h-100'>
                              <div className='custom-text-edit-content gap-3'>
                                <div className='d-grid gap-3'>
                                  <textarea
                                    name=""
                                    id=""
                                    placeholder='"Enter Your Text..."'
                                    rows={5}
                                    className='p-sm-2'
                                    value={tempTextValue?.data ?? ''}
                                    onChange={(e) => {
                                      handleTextChange({
                                        label: 'text',
                                        key: 'data',
                                        value: e.target.value
                                      })
                                    }
                                    }
                                  ></textarea>
                                  <Accordion defaultActiveKey={["0"]} alwaysOpen className='d-grid gap-3'>
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>
                                        <div>
                                          <span className='fw-semibold fs-18'>Font Style</span>
                                        </div>
                                        <i className="bi bi-plus-lg"></i>
                                      </Accordion.Header>
                                      <Accordion.Body className='py-3 pt-1'>
                                        <div className="d-grid gap-1">
                                          <div className='flex-between-align custom-font-size-tab gap-3'>
                                            <div className='w-100 d-grid gap-1'>
                                              <span className='fw-light fs-14'>Size :</span>
                                              <div className='w-100 custom-font-value flex-between-align'>
                                                <i className="bi bi-dash-lg" onClick={() => setTempTextValue((prevData) => ({
                                                  ...prevData,
                                                  style: {
                                                    ...prevData?.style,
                                                    fontSize: (tempTextValue?.style?.fontSize) ? (tempTextValue?.style?.fontSize !== 1) ? tempTextValue?.style?.fontSize - 1 : 1 : 15
                                                  }
                                                }))}></i>
                                                <input
                                                  type="number"
                                                  value={tempTextValue?.style?.fontSize ?? 16}
                                                  onChange={(e) => setTempTextValue((prevData) => ({
                                                    ...prevData,
                                                    style: {
                                                      ...prevData?.style,
                                                      fontSize: (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 99) ? parseInt(e.target.value) : 1
                                                    }
                                                  }))}
                                                />
                                                <i className="bi bi-plus-lg" onClick={() => setTempTextValue((prevData) => ({
                                                  ...prevData,
                                                  style: {
                                                    ...prevData?.style,
                                                    fontSize: (tempTextValue?.style?.fontSize) ? (tempTextValue?.style?.fontSize !== 1) ? tempTextValue?.style?.fontSize + 1 : 2 : 17
                                                  }
                                                }))}></i>
                                              </div>
                                            </div>
                                            <div className='w-100 d-grid gap-1'>
                                              <span className='fw-light fs-14'>Style :</span>
                                              <div className='custom-style-btn'>
                                                <div className={`custom-btns ${(tempTextValue?.style?.fontWeight === 'bold') ? 'active' : ''}`}
                                                  onClick={() => setTempTextValue((prevData) => ({
                                                    ...prevData,
                                                    style: {
                                                      ...prevData?.style,
                                                      fontWeight: (tempTextValue?.style?.fontWeight) ? (tempTextValue?.style?.fontWeight === 'normal') ? 'bold' : 'normal' : 'bold'
                                                    }
                                                  }))} >
                                                  <GrBold className='fs-25' />
                                                </div>
                                                <div className={`custom-btns ${(tempTextValue?.style?.fontStyle === 'italic') ? 'active' : ''}`}
                                                  onClick={() => setTempTextValue((prevData) => ({
                                                    ...prevData,
                                                    style: {
                                                      ...prevData?.style,
                                                      fontStyle: (tempTextValue?.style?.fontStyle) ? (tempTextValue?.style?.fontStyle === 'normal') ? 'italic' : 'normal' : 'italic'
                                                    }
                                                  }))} >
                                                  <GrItalic className='fs-25' />
                                                </div>
                                                <div className={`custom-btns ${(tempTextValue?.style?.textDecoration === 'underline') ? 'active' : ''}`} onClick={() => setTempTextValue((prevData) => ({
                                                  ...prevData,
                                                  style: {
                                                    ...prevData?.style,
                                                    textDecoration: (tempTextValue?.style?.textDecoration) ? (tempTextValue?.style?.textDecoration === 'none') ? 'underline' : 'none' : 'underline'
                                                  }
                                                }))}>
                                                  <GrUnderline className='fs-25' />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <span className='fs-10 text-danger fw-semibold'>* Only 1 to 99px are allowed</span>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                      <Accordion.Header className=''>
                                        <div>
                                          <span className='fs-18 fw-semibold'>Font Color</span>
                                        </div>
                                        <i className="bi bi-plus-lg"></i>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className='custom-font-color-tab d-grid gap-3'>
                                          <div className='custom-font-color-tab-list'>
                                            <input type="color" id='textColor' onChange={(e) => setTempTextValue((prevData) => ({
                                              ...prevData,
                                              style: {
                                                ...prevData?.style,
                                                color: (tempTextValue?.style?.color) ? (tempTextValue?.style?.color === e.target.value) ? '#000000' : e.target.value : e.target.value
                                              }
                                            }))} />
                                            {
                                              colorList.map((item, index) => {
                                                return (
                                                  <span
                                                    key={index}
                                                    style={{ backgroundColor: item }}
                                                    onClick={() => setTempTextValue((prevData) => ({
                                                      ...prevData,
                                                      style: {
                                                        ...prevData?.style,
                                                        color: (tempTextValue?.style?.color) ? (tempTextValue?.style?.color === item) ? '#000000' : item : item
                                                      }
                                                    }))}
                                                  >
                                                    <i className={`fs-25 bi bi-check ${((tempTextValue?.style?.color === item) ? 'd-flex' : 'd-none')}`}></i>
                                                  </span>
                                                )
                                              })
                                            }
                                          </div>
                                          <div className='color-code-show d-grid pb-2'>
                                            <input type="text" value={tempTextValue?.style?.color ?? '#000000'} readOnly />
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                      <Accordion.Header>
                                        <div>
                                          <span className='fs-18 fw-semibold'>Shadow</span>
                                        </div>
                                        <i className="bi bi-plus-lg"></i>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className='custom-font-color-tab d-grid gap-3'>
                                          <div className='custom-font-color-tab-list'>
                                            <input
                                              type="color"
                                              id='textColor'
                                              onChange={(e) => setTempTextValue((prevData) => ({
                                                ...prevData,
                                                style: {
                                                  ...prevData?.style,
                                                  shadow: {
                                                    ...prevData?.style?.shadow,
                                                    color: (tempTextValue?.style?.shodow?.olor) ? (tempTextValue?.style?.shodow?.color === e.target.value) ? '#000000' : e.target.value : e.target.value,
                                                    offsetX: 0,
                                                    offsetY: 0,
                                                    blur: ((tempTextValue?.style?.fontSize ?? 16) / 100) * 50
                                                  }
                                                }
                                              }))}
                                            />
                                            {
                                              colorList.map((item, index) => {
                                                return (
                                                  <span
                                                    key={index}
                                                    style={{ backgroundColor: item }}
                                                    onClick={() => setTempTextValue((prevData) => ({
                                                      ...prevData,
                                                      style: {
                                                        ...prevData?.style,
                                                        shadow: {
                                                          ...prevData?.style?.shadow,
                                                          color: (tempTextValue?.style?.shadow?.color) ? (tempTextValue?.style?.shadow?.color === item) ? '#000000' : item : item,
                                                          offsetX: 0,
                                                          offsetY: 0,
                                                          blur: ((tempTextValue?.style?.fontSize ?? 16) / 100) * 50
                                                        }
                                                      }
                                                    }))}
                                                  >
                                                    <i className={`fs-25 bi bi-check ${((tempTextValue?.style?.shadow?.color === item) ? 'd-flex' : 'd-none')}`}></i>
                                                  </span>
                                                )
                                              })
                                            }
                                          </div>
                                          <div className='d-grid gap-2 pb-3 custom-text-shadow-list'>
                                            <div className='d-grid gap-1 '>
                                              <Form.Label>Distance</Form.Label>
                                              <div className='flex-between-align gap-2'>
                                                <div className='w-100 range-div'>
                                                  <Form.Range
                                                    className="custom-range"
                                                    onChange={(e) => setTempTextValue((prevData) => ({
                                                      ...prevData,
                                                      style: {
                                                        ...prevData?.style,
                                                        shadow: {
                                                          ...prevData?.style?.shadow,
                                                          offsetX: ((tempTextValue?.style?.fontSize ?? 16) / 50) * (e.target.value - 50)
                                                        }
                                                      }
                                                    }))}
                                                  />
                                                </div>
                                                <div className='type-div'>
                                                  <input type="number" value={tempTextValue?.style?.shadow?.offsetX ?? 0} readOnly />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='d-grid gap-1'>
                                              <Form.Label>Angle</Form.Label>
                                              <div className='flex-between-align gap-2'>
                                                <div className='w-100 range-div'>
                                                  <Form.Range
                                                    className="custom-range"
                                                    onChange={(e) => setTempTextValue((prevData) => ({
                                                      ...prevData,
                                                      style: {
                                                        ...prevData?.style,
                                                        shadow: {
                                                          ...prevData?.style?.shadow,
                                                          offsetY: ((tempTextValue?.style?.fontSize ?? 16) / 50) * (e.target.value - 50)
                                                        }
                                                      }
                                                    }))} />
                                                </div>
                                                <div className='type-div'>
                                                  <input type="number" value={tempTextValue?.style?.shadow?.offsetY ?? 0} readOnly />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='d-grid gap-1'>
                                              <Form.Label>Blur</Form.Label>
                                              <div className='flex-between-align gap-2'>
                                                <div className='w-100 range-div'>
                                                  <Form.Range
                                                    className="custom-range"
                                                    onChange={(e) => setTempTextValue((prevData) => ({
                                                      ...prevData,
                                                      style: {
                                                        ...prevData?.style,
                                                        shadow: {
                                                          ...prevData?.style?.shadow,
                                                          blur: ((tempTextValue?.style?.fontSize ?? 16) / 100) * e.target.value
                                                        }
                                                      }
                                                    }))}
                                                  />
                                                </div>
                                                <div className='type-div'>
                                                  <input type="number"
                                                    value={tempTextValue?.style?.shadow?.blur
                                                      ? ((tempTextValue?.style?.shadow?.blur) * (100 / (tempTextValue?.style?.fontSize ?? 16)))
                                                      : ((tempTextValue?.style?.fontSize ?? 16) / 2) * (100 / (tempTextValue?.style?.fontSize ?? 16))} readOnly />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                                <div className='d-grid gap-3'>
                                  <div className='d-grid custom-preview-box'>
                                    <textarea name="" id="" placeholder='*Preview*' className='p-2' value={tempTextValue?.data ?? ''} style={{
                                      fontSize: tempTextValue?.style?.fontSize,
                                      fontWeight: tempTextValue?.style?.fontWeight,
                                      fontStyle: tempTextValue?.style?.fontStyle,
                                      textDecoration: tempTextValue?.style?.textDecoration,
                                      color: tempTextValue?.style?.color,
                                      textShadow: `${tempTextValue?.style?.shadow?.offsetX}px ${tempTextValue?.style?.shadow?.offsetY}px ${tempTextValue?.style?.shadow?.blur}px ${tempTextValue?.style?.shadow?.color}`,
                                    }} readOnly></textarea>
                                  </div>
                                  <div className='flex-between-align gap-3'>
                                    <button
                                      onClick={() => setTempTextValue('')}
                                      className='w-100 custom-clear-btn'
                                    >
                                      Clear
                                    </button>
                                    <button
                                      onClick={() => handleTextData({ type: 'text' })}
                                      className='w-100 custom-apply-btn'
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            (tabData === 'clipart') &&
                            <div className='h-100 d-grid custom-clipart-container'>
                              <Container className='p-0 custom-clipart'>
                                <Row className=''>
                                  {
                                    faceEmojis.map((item, index) => {
                                      return (
                                        <Col xl={2} lg={3} md={4} sm={6} xs={12} key={index} className='custom-img-holder'>
                                          <span
                                            className='fs-35 fs-lg-25 fs-md-20'
                                            dangerouslySetInnerHTML={{ __html: item.htmlEntity }}
                                            onClick={() => {
                                              handleCanvasItems({
                                                type: "clipart",
                                                data: item?.name
                                              })
                                            }}
                                          />
                                        </Col>
                                      )
                                    })
                                  }

                                </Row>
                              </Container>
                            </div>
                          }
                          {
                            (tabData === 'upload') &&
                            <div className='h-100 d-grid custom-upload-tab gap-3'>

                              <div className='d-grid'>
                                <span className='text-danger fs-12 fw-semibold d-flex gap-1 pb-1'><span>*</span> <span>File size must be <b>2MB - 10MB</b> & file type <b>png, jpg</b> and <b>jpeg</b> is allowed</span></span>
                                <form
                                  className={`file-upload-form ${dragOver ? 'dragover' : ''}`}
                                  onDragOver={handleDragOver}
                                  onDragLeave={handleDragLeave}
                                  onDrop={handleDrop}
                                  onClick={handleDragClick}
                                >
                                  <label className={`file-upload-label ${loader ? 'flex-center-align' : ''}`}>
                                    {
                                      (loader) ?
                                        <>
                                          <div className='d-grid gap-2'>
                                            <div className='flex-center-align'>
                                              <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                              </Spinner>
                                            </div>
                                            <span className='text-center fs-12 fw-semibold'>Loading...</span>
                                          </div>
                                        </>

                                        :
                                        <>
                                          <div className="file-upload-design">
                                            <svg viewBox="0 0 640 512" height="1em" className={`m-0 ${dragOver ? 'active' : ''}`}>
                                              <path
                                                d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                                              ></path>
                                            </svg>
                                            <p className='m-0 fs-14'>Drag and Drop</p>
                                            <p className='m-0 fs-14'>or</p>
                                            <span className="browse-button fs-14">Browse file</span>
                                          </div>
                                          <input
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={(handleFileChange)}
                                            style={{ display: 'none' }}
                                          />
                                        </>
                                    }
                                  </label>
                                </form>
                              </div>
                              <div className='d-grid  img-list-data align-content-start'>
                                {
                                  (errorMsg) &&
                                  <Alert variant="danger" className='fs-14 fw-semibold flex-between-align'>
                                    <span>{errorMsg}</span>
                                    <i className='bi bi-x-lg' onClick={() => setErrorMsg()} style={{ cursor: 'pointer' }}></i>
                                  </Alert>

                                }
                                <Container className='p-0'>
                                  <Row className='gy-2 m-0'>
                                    {
                                      (uploadData) &&
                                      uploadData?.map((item, index) => (
                                        <Col xl={6} md={6} xs={12} key={index} className='px-1 custom-img-list'>
                                          <div className='img-holder'>
                                            <img
                                              src={SERVER_URL + item?.link}
                                              alt=""
                                              onClick={() => {
                                                console.log("item::",item);
                                                handleCanvasItems({
                                                  type: "image",
                                                  data: item?.link
                                                })
                                              }}
                                            />
                                            <span onClick={() => handleDeleteImage(item)}>
                                              <i class="bi bi-trash-fill fs-16"></i>
                                            </span>
                                          </div>
                                        </Col>
                                      ))
                                    }
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          }
                          {
                            (tabData === 'layers') &&
                            <div className='h-100 d-grid canvas-body-sides-layer gap-3'>
                              <div className={`canvas-body-sides-layer-display py-2 d-grid align-content-between`}>
                                <div className='top-suggest-line flex-between-align pb-3'>
                                  <div className='w-100 line-first'></div>
                                  <span className='fw-semibold fs-14 px-2'>Front</span>
                                  <div className='w-100 line-second'></div>
                                </div>
                                <div className='h-100 d-grid align-content-start'>

                                  {
                                    (canvasData[currentCanvas]?.data?.objects?.length > 0) ?
                                      (<Card>
                                        <ListGroup variant="flush" className="d-grid gap-2 custom-list-items-container">
                                          <List
                                            values={canvasData[currentCanvas]?.data?.objects.slice().reverse()}
                                            onChange={handleDraggingEnd}
                                            renderList={({ children, props }) => (
                                              <ListGroup variant="flush" {...props} className="d-grid gap-2 custom-list-items-container">
                                                {children}
                                              </ListGroup>
                                            )}
                                            renderItem={({ value, props }) => (
                                              <ListGroup.Item {...props} className="custom-list-items flex-between-align px-2 py-1">
                                                <div><i className="bi bi-grip-vertical fs-20"></i></div>
                                                <div className="w-100 flex-center-align custom-list-item-display">
                                                  <span>
                                                    {
                                                      (value?.type === 'Image')
                                                        ? <div className='img-holder'>
                                                          <img src={value?.src} alt="" />
                                                        </div>
                                                        : (value?.type === 'Textbox')
                                                          ? value?.text
                                                          : 'Custom Item'
                                                    }
                                                  </span>
                                                </div>
                                                <div
                                                  className="delete-btn"
                                                  onMouseDown={(e) => e.stopPropagation()}
                                                  onClick={() => setDeleteItem(value?.id)}
                                                >
                                                  <i className="bi bi-trash fs-14"></i>
                                                </div>
                                              </ListGroup.Item>
                                            )}
                                          />
                                        </ListGroup>
                                      </Card>)
                                      :
                                      <h4 className='fw-semibold text-center'>No data added yet</h4>
                                  }


                                </div>
                                <div className='top-suggest-line flex-between-align pt-3'>
                                  <div className='w-100 line-first'></div>
                                  <span className='fw-semibold fs-14 px-2'>Back</span>
                                  <div className='w-100 line-second'></div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='custom-canvas-body-content position-relative d-grid' ref={sliderRef}>
                    <div className={` final-display-body-content p-sm-4`} style={{ width: (location?.state?.data?.canvasWidth) ?? contentWidth ?? "inherit" }}>
                      <CanvasComponent
                        editedCanvasData={location?.state?.data?.editCanvas}
                        currentIndex={currentIndex ?? 0}
                        canvasValue={sendText}
                        productColor={productColorImage}
                        canvasData={canvasData}
                        canvasDeleteItem={deleteItem}
                        newCanvasData={newCanvasData}
                        getDataValue={(e) => setTempTextValue((e.type == "text") && e)}
                        onSwitch={(obj) => {
                          setCurrentIndex(obj?.index)
                          setCurrentCanvas(obj?.index)
                          setToggleStatus(!toggleStatus)
                          setCanvasData(obj?.data)
                          console.log("obj::", obj);
                        }}
                        previewData={{
                          product: data?.product,
                          provider: data?.provider
                        }}
                      />
                      <div className='canvas-body-sides-color mt-4 gap-3'>
                        {
                          variationData?.color?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className='custom-img-holder-container text-center d-grid align-content-start gap-1'
                              >
                                <div className='custom-img-holder' style={{ backgroundColor: item?.code }} onClick={() => setProductColorImage(item?.code)}>
                                  <img src={SERVER_URL + productSideImage?.thumbnail} alt="" />
                                </div>
                                <span className='fs-12 text-capitalize' onClick={() => setProductColorImage(item?.code)}>{item?.label}</span>
                                <div
                                  className='custom-delete'
                                  onClick={() => {
                                    handleVariationChange({
                                      key: data?.provider?.availableVariantData[1].name,
                                      value: item
                                    });
                                  }}
                                >
                                  <i className='bi bi-x-lg fs-10' />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    {
                      (canvasLoader) &&
                      <div className='position-absolute h-100 w-100 top-0 flex-center-align bg-light'>
                        <Spinner animation="border" variant="success" />
                      </div>
                    }

                  </div>
                </Col>


                {/* Canvas Footer */}
                <Col className='col-12 p-3 custom-canvas-footer'>
                  <div className='d-flex custom-canvas-footer-product d-flex gap-2'>
                    <div className='d-grid img-holder'>
                      <img src={SERVER_URL + data?.product?.thumbnail} alt="" />
                    </div>
                    <div className='flex-center-align px-2'>
                      <div className='custom-line' />
                    </div>
                    <div className='d-grid align-content-center gap-1'>
                      <span className='p-color fs-14'>{data?.product?.name}</span>
                      <span className='fw-semibold w-100 d-flex justify-content-start gap-2'>
                        <span>₹{activePrice}</span>
                        <span className="bi bi-info-circle-fill fs-18 custom-price-show fw-light">
                          <div className='custom-price-box gap-2 p-2'>
                            {
                              showPrice?.map((item, index) => {
                                return (
                                  <div className='flex-between-align gap-1' key={index}>
                                    <span className='flex-between-align fs-14'>
                                      <span>{(index > 0) && '+'}</span>
                                      <span>₹{item.value}</span>
                                    </span>
                                    <span className='w-100 fs-12 text-end text-capitalize'>
                                      {item.label}
                                    </span>
                                  </div>
                                )
                              })
                            }
                            <hr className='my-1' />
                            <span className='fw-semibold text-center'>₹{activePrice}</span>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <Redirection
                      redirectValue={{
                        url: `/product/${params?.productId}/${params?.providerId}/create product`,
                        data: {
                          'canvas': canvasData,
                          'product': data?.product,
                          'provider': data?.provider,
                          'variation': variationData,
                          'priceList': showPrice,
                          'canvasWidth': contentWidth,
                          'updateProduct': location?.state?.data?.updateProduct ?? null
                        }
                      }}
                    >
                      <Link
                        className='custom-submit text-capitalize'
                        to={`/product/${params?.productId}/${params?.providerId}/create-product`}
                      >
                        save product
                      </Link>
                    </Redirection>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
          // : <ErrorPage />
      }
    </div >
  )
}

export default ProductCanvas;