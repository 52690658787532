import React, { useEffect, useState } from 'react'
import { Accordion, Alert, Button, Card, Col, Container, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API, header, SERVER_URL } from '../../Config/Config'
import axios from 'axios';
import CommonPagination from '../../Components/CommonPagination';
import { FaArrowLeftLong, FaRegCreditCard, FaTruckFast } from 'react-icons/fa6';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, deleteCart, removeCart, updateCart } from '../../Store/Slices/cartSlice';
import { toast, ToastContainer } from 'react-toastify';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { CiCirclePlus, CiWallet } from "react-icons/ci";
import Loader from '../../Components/Loader';
import { GiNotebook } from 'react-icons/gi';
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import { FaDotCircle } from "react-icons/fa";
import moment from 'moment';
import { PiNoteBlank } from "react-icons/pi";
import Swal from 'sweetalert2';
import { HiOutlineCreditCard } from "react-icons/hi2";
import { AiOutlineBank } from "react-icons/ai";
import { initializeRazorpay } from '../../Components/InitializeRazorPay';
import { IoShirtOutline } from 'react-icons/io5';
import { TbTruckReturn } from 'react-icons/tb';

function SellerCreateOrder() {

    const location = useLocation();
    // const { stateActiveTab, stateCartData } = location?.state;
    const { stateActiveTab = '', stateCartData = [] } = location?.state || {};
    console.log("location::", stateActiveTab);
    const [productList, setProductList] = useState();
    const [totalCount, setTotalCount] = useState();
    const [filterValue, setFilterValue] = useState({});
    const [tabHodlder, setTabHodlder] = useState(stateActiveTab?.length > 0 ? stateActiveTab : "selectProduct");
    // const [tabHodlder, setTabHodlder] = useState(stateActiveTab?.length > 0 ? stateActiveTab : "payment");
    const [product, setProduct] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    let cartData = useSelector((state) => state.cart.value);
    const storeData = useSelector((state) => state.store.value);
    const [backColor, setBackColor] = useState();
    const [loader, setLoader] = useState(true);
    const [userList, setUserList] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [apiData, setApiData] = useState();
    const [data, setData] = useState([]);
    const [paymentType, setPaymentType] = useState();
    const [orderData, setOrderData] = useState();
    const [newCartData, setNewCartData] = useState();
    const [show, setShow] = useState(false);
    const [otherPayment, setOtherPayment] = useState();

    useEffect(() => {
        if (stateCartData?.length > 0) {
            dispatch(addCart(stateCartData))
        }
    }, [location?.state])

    // 
    // Product List
    // 
    const getProductList = async () => {
        try {
            const response = await axios.put(frontEnd_API?.snapFilterProductList, filterValue, header)
            console.log("data::", response?.data);
            if (response?.status == 200) {
                setProductList(response?.data?.data)
                setTotalCount(response?.data?.totalCount);
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getProductList();
        console.log("filterValue::", filterValue);
    }, [filterValue])

    // 
    // Product Detail
    // 
    const getSingleProduct = async (id) => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.singleOrderProduct}/${id}`, header)
            console.log("data::", data);
            setProduct(data?.data);
            const check = data?.data?.variantData.find((item) => ["color", "colors", "colour"].includes(item?.name))
            setBackColor(check?.data[0]?.code)
            const updatedVariantData = data?.data?.variantData.reduce((acc, variant) => {
                const firstOption = variant?.data[0];
                acc[variant.name] = firstOption?.value;
                return acc;
            }, {});
            setSelectedProduct({
                variantData: updatedVariantData,
                productId: parseInt(data?.data?.productId),
                quantity: 1
            });
        }
        catch (e) {
            console.log("e::", e);
        }
    }

    const changeVariant = (obj = {}) => {
        if (obj?.name) {
            // console.log("obj::", obj);
            // console.log("obj?.data?.code::", obj?.data?.code);
            setBackColor(obj?.data?.code);
            setSelectedProduct((prevData) => ({
                ...prevData,
                variantData: {
                    ...prevData.variantData,
                    [obj?.name]: obj?.data?.value
                }
            }))
        }
    }

    useEffect(() => {
        console.log("selectedProduct::", selectedProduct);
    }, [selectedProduct])


    useEffect(() => {
        console.log("cartData::", cartData);
    }, [cartData])

    const updateCartLocal = (props = {}) => {
        dispatch(updateCart(selectedProduct))
        getSingleProduct();

        if (cartData?.length > 0) {
            const existId = cartData?.find((item) => item?.productId === selectedProduct?.productId)
            if (existId) {
                toast("Product Updated in Cart !")
            }
            else {
                toast("Product Added in Cart !")
            }
        }
        else {
            toast("Product Added in Cart !")
        }
        if (parseInt(props?.try) === 1) {
            setTabHodlder("selectUser")
        }
    }

    const priceIncrease = () => {

        const newData = (selectedProduct?.variantData) ? Object?.values(selectedProduct?.variantData) : [];

        const matchingItem = (product?.cartesianData?.length > 0) && product?.cartesianData.find(item => JSON.stringify(item.cartesian) === JSON.stringify(newData));

        const matchingPrice = matchingItem ? matchingItem.price : null;
        return matchingPrice;
    }

    const checkActive = (checkData) => {
        const preDataValues = Object.values(selectedProduct?.variantData);
        const isValueInPreData = preDataValues.includes(checkData.value);
        return isValueInPreData;
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // 
    // User List
    // 

    const getUserList = async () => {
        try {
            const response = await axios.put(frontEnd_API?.users, {}, header)
            console.log("response?.data::", response?.data);
            if (response?.status == 200) {
                setUserList(response?.data?.data)
                setLoader(false);
            }
        }
        catch (e) {
            console.log("Err::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getUserList();
    }, [])

    // 
    // Cart Data
    // 

    const getCartList = async () => {
        const petaTemp = cartData?.map((item) => (
            (item?.returnOrderChildId) ? {
                returnOrderChildId: item?.returnOrderChildId,
                returnOrderId: item?.returnOrderId,
            } : {
                "productId": item?.productId,
                "variantData": (item?.variantData) && Object.values(item?.variantData),
                "quantity": item?.quantity
            }
        ));
        console.log("petaTemp::", petaTemp);
        try {
            const { data } = await axios.put(frontEnd_API.getCartList, {
                "cartData": petaTemp,
                "couponCode": "",
                "couponAmount": 0
            }, header)
            setApiData(data?.data)
            const checkApplicable = data?.data?.couponData?.filter((item) => {
                return item?.limitCondition < data?.data?.subTotalAmount
            })
            const checkPending = (checkApplicable?.length > 0)
                ? data?.data?.couponData?.filter((item) => {
                    return !checkApplicable.includes(item);
                })
                : data?.data?.couponData
            console.log("data::", data);
        }
        catch (e) {
            console.log("e::", e);
        }
    }

    useEffect(() => {
        getCartList();
    }, [cartData])

    const deleteCartItem = (item) => {
        Swal.fire({
            title: `Are you sure to remove item from cart ?`,
            text: ``,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deleteCart(item))
                getCartList();
                toast("Item removed from cart ! ")
            }
        });
    }

    useEffect(() => {
        console.log("apiData::", apiData);
    }, [apiData])

    const handleCartClear = () => {
        Swal.fire({
            title: `Are sure to clean card data ?`,
            text: ``,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#12715B',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(removeCart());
            }
        });
    }

    useEffect(() => {
        axios.get(frontEnd_API.getGlobal, header)
            .then((res) => {
                setData(res?.data?.data);
            })
            .catch((err) => {
                console.log("err::", err);
            })
    }, [])

    useEffect(() => {
        console.log("selectedUser::", selectedUser);
    }, [selectedUser])

    // 
    // Payment code
    // 

    const handleOrderProcess = () => {
        const checkCouponId = newCartData?.couponData.find((item) => item?.label === newCartData?.couponCode)
        const storeData = {
            "userId": selectedUser?.id,
            "couponId": (newCartData?.couponCode) ? checkCouponId.value : 0,
            "couponCode": newCartData?.couponCode,
            "orderSubTotalAmount": orderData?.orderSubTotalAmount,
            "orderDiscountAmount": orderData?.orderDiscountAmount,
            "orderCouponAmount": newCartData?.couponAmount,
            "orderGrossAmount": orderData?.orderGrossAmount,
            "orderShippingChargeAmount": orderData?.orderShippingChargeAmount,
            "orderTaxAmount": orderData?.orderTaxAmount,
            "orderNetAmount": orderData?.orderNetAmount,
            "orderPaymentMode": (paymentType === "COD") ? "COD" : 'ONLINE',
            "orderPaymentModeName": (paymentType === "COD") ? "COD" : `${paymentType}`,
            // "orderPaymentTransactionNumber": (razorCode) ?? (Math.floor(1000000 + Math.random() * 9000000) + moment().format('YYYYMMDDhhmmss')).toString(),
            "orderPaymentTransactionNumber": "",

            "orderUPI": otherPayment?.orderUPI ?? "", // IF Mode Name is UPI
            "orderBankName": otherPayment?.orderBankName ?? "", // IF Mode Name is BANK
            "orderAccountHolderName": otherPayment?.orderAccountHolderName ?? "", // IF Mode Name is BANK
            "orderAccountNumber": otherPayment?.orderAccountNumber ?? "", // IF Mode Name is BANK
            "orderIfscCode": otherPayment?.orderIfscCode ?? "", // IF Mode Name is BANK

            "orderType": "CUSTOM",
            "orderBillingName": selectedUser?.address?.name,
            "orderBillingEmail": selectedUser?.address?.email,
            "orderBillingMobile": selectedUser?.address?.mobile,
            "orderBillingAddressLine1": selectedUser?.address?.addressLine1,
            "orderBillingAddressLine2": selectedUser?.address?.addressLine2,
            "orderBillingArea": selectedUser?.address?.addressLine2,
            "orderBillingCity": selectedUser?.address?.city,
            "orderBillingState": selectedUser?.address?.state,
            "orderBillingCountry": "INDIA",
            "orderBillingPincode": selectedUser?.address?.pinCode,
            "orderShippingName": selectedUser?.address?.name,
            "orderShippingEmail": selectedUser?.address?.email,
            "orderShippingMobile": selectedUser?.address?.mobile,
            "orderShippingAddressLine1": selectedUser?.address?.addressLine1,
            "orderShippingAddressLine2": selectedUser?.address?.addressLine2,
            "orderShippingArea": selectedUser?.address?.addressLine2,
            "orderShippingCity": selectedUser?.address?.city,
            "orderShippingState": selectedUser?.address?.state,
            "orderShippingCountry": "INDIA",
            "orderShippingPincode": selectedUser?.address?.pinCode,
            "orderChildData": orderData?.productData
        }
        postOrderDetails(storeData);
    }

    const postOrderDetails = (storeData) => {
        console.log("storeData::", storeData);
        axios.post(frontEnd_API.orders, storeData, header)
            .then((res) => {
                console.log("DataFromOrder::", res);
                dispatch(removeCart());
                setShow(true);
                setTimeout(() => {
                    navigate('/orders');
                }, 4200)
            })
            .catch((err) => {
                console.log("Err::", err);
            })
    }

    const handleTaxFromAPI = async () => {

        // const tempProductData = apiData?.cartData?.map((item) => ({
        //     productId: item?.productId,
        //     variantData: item?.variantData,
        //     quantity: item?.quantity
        // }))
        const tempProductData = apiData?.cartData?.map((item) => (
            (item?.returnOrderChildId) ? {
                returnOrderChildId: item?.returnOrderChildId,
                returnOrderId: item?.returnOrderId,
            } : {
                "productId": item?.productId,
                "variantData": item?.variantData,
                "quantity": item?.quantity
            }
        ));
        console.log("tempProductData::", tempProductData);
        console.log("tempCart::", apiData);
        try {
            const response = await axios.put(frontEnd_API?.getTaxList, {
                productData: tempProductData,
                state: selectedUser?.address?.state,
                couponCode: ""
            }, header)
            console.log("response::", response);
            if (response?.status == 200) {
                const data = response?.data?.data;
                setNewCartData({
                    ...apiData,
                    orderShippingChargeAmount: data?.orderShippingChargeAmount,
                    orderNetAmount: data?.orderNetAmount,
                    orderTaxAmount: data?.orderTaxAmount
                })
                setOrderData(data)
                setTabHodlder("payment");
            }
        }
        catch (e) {
            Swal.fire(
                `Request failed`,
                `${e?.response?.data?.message}`,
                'error'
            );
            console.log("err::", e);
        }
    }

    useEffect(() => {
        console.log("cartData::", cartData);
    }, [cartData])

    useEffect(() => {
        console.log("otherPayment::", otherPayment);
    }, [otherPayment])


    return (
        <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
            <Container className='seller-order-main-container pb-5'>
                <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
                    <h3 className='fw-semibold d-flex gap-2 align-items-center m-0'><IoShirtOutline className='color-temp' />Create manual order :</h3>
                </div>
                <div className='seller-order-container p-2 p-sm-3'>
                    <Tabs
                        activeKey={tabHodlder == "selectedProduct" ? "selectProduct" : tabHodlder}
                        id="fill-tab-example"
                        className="mb-3 custom-tabs custom-tabs-with-even justify-content-between"
                        onSelect={(k) => {
                            setTabHodlder(k);
                            if (k == "payment") {
                                handleTaxFromAPI();
                            }
                        }}
                        fill
                    >
                        <Tab eventKey="selectProduct" title="1. Select Product">
                            <Container className='py-3 seller-product-page'>
                                {
                                    (tabHodlder == "selectProduct") ?
                                        <Row className='gy-4'>
                                            <Container className='custom-seller-category-search px-0 pt-2 pt-sm-3'>
                                                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                    <label className='bi bi-search' htmlFor='text'></label>
                                                    <input
                                                        type="text"
                                                        className='py-2 fs-sm-14 pe-2'
                                                        id='text'
                                                        placeholder='Search by Product name'
                                                        autoComplete='off'
                                                        onChange={(e) => {
                                                            setFilterValue((prevData) => ({
                                                                ...prevData,
                                                                search: e?.target?.value
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </Container>
                                            {
                                                (productList?.length > 0) ?
                                                    productList.map((item, index) => (
                                                        <Col key={index} className='col-12 col-sm-6 col-lg-4 col-xl-3 px-0 px-sm-2 seller-product-detail'>
                                                            <div className='seller-product-detail-container d-grid gap-2'>
                                                                <div className='seller-product-detail-img-container'>
                                                                    <div onClick={() => {
                                                                        setTabHodlder("selectedProduct")
                                                                        getSingleProduct(item?.id)
                                                                    }}>
                                                                        <img src={SERVER_URL + item.thumbnail} className='img-one' alt="" />
                                                                        <img src={SERVER_URL + item.thumbnailSlide} className='img-two' alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className='seller-product-detail-cnt-container px-2 pb-2 d-grid gap-1'>
                                                                    <Link className='fw-semibold fs-sm-18' onClick={() => {
                                                                        setTabHodlder("selectedProduct")
                                                                        getSingleProduct(item?.id)
                                                                    }}>{item?.name}</Link>
                                                                    <div className='fw-semibold'>₹{item?.mrp}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                    : <h2 className='fw-semibold text-center d-grid d-sm-flex flex-center-align gap-sm-3'><i className="bi bi-slash-circle text-danger" />Not assigned</h2>
                                            }
                                            <Col className='col-12 d-grid'>
                                                <CommonPagination
                                                    totalCount={totalCount}
                                                    changePage={(data) => {
                                                        setFilterValue((prevData) => ({
                                                            ...prevData,
                                                            limit: data?.limit,
                                                            page: data?.page
                                                        }))
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className='col-12 overflow-hidden'>
                                                <div className='d-flex'>
                                                    <Link onClick={() => setTabHodlder("selectProduct")} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                                                </div>
                                                <div className='custom-product-page'>
                                                    <Container className='py-4'>
                                                        <Row className='gy-4 gy-lg-0'>
                                                            <Col className='col-12 col-lg-5 ps-lg-0'>
                                                                <Row style={{ position: 'sticky', top: '0' }}>
                                                                    <Col className='col-12 col-md-0 pe-lg-0 single-big-slider'>
                                                                        <div className="slider-container">
                                                                            <Slider {...settings}>
                                                                                {
                                                                                    (product?.imageData?.length > 0) &&
                                                                                    product?.imageData.map((item, index) => (
                                                                                        <div>
                                                                                            <div key={index} className='img-holder' style={{ backgroundColor: backColor }}>
                                                                                                {/* <img src={BASE_URL + item} alt="" /> */}
                                                                                                <ProductPreviewImage
                                                                                                    colorCode={backColor ?? '#ffffff'}
                                                                                                    objectData={{
                                                                                                        preview: item || null,
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </Slider>
                                                                            {/* <Slider
                                                                                className='pt-1'
                                                                                asNavFor={nav1}
                                                                                ref={slider => (sliderRef2 = slider)}
                                                                                swipeToSlide={true}
                                                                                focusOnSelect={true}
                                                                                {...settings}
                                                                            >
                                                                                {
                                                                                    (product?.imageData?.length > 0) &&
                                                                                    product?.imageData.map((item, index) => (
                                                                                        <div>
                                                                                            <div key={index} className='img-holder' style={{ backgroundColor: backColor }}>
                                                                                                <img src={SERVER_URL + item} alt="" />
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </Slider> */}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className='col-12 col-lg-7 custom-page-content ps-lg-5'>
                                                                <div className='d-grid custom-page-title gap-4'>
                                                                    <div className='flex-between-align'>
                                                                        <h3 className='fw-semibold m-0'>{product?.name}</h3>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle id="dropdown-basic" className='p-0'>
                                                                                <i className="bi bi-share-fill p-2 py-1"></i>
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-2">WhatsApp</Dropdown.Item>
                                                                                <Dropdown.Item href="#/action-3">Instagram</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                    <h2 className='fw-semibold mb-2'>₹{priceIncrease()} <span className='fw-semibold fs-14'>Excl. Tax</span></h2>
                                                                    {
                                                                        product?.variantData?.map((item, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                    className={`d-grid gap-1 
                                                ${(item?.name === 'size') ? 'custom-size' : ''}
                                                ${(item?.name === 'color') ? 'custom-color' : ''}`
                                                                                    }>
                                                                                    <div className='d-flex gap-1'>
                                                                                        <span className='p-color'>{item?.name}: </span>
                                                                                        <p className='fw-semibold m-0 text-capitalize'>
                                                                                            {product?.variantData[index].data.label}
                                                                                        </p>
                                                                                    </div>
                                                                                    {
                                                                                        (item?.name === "color") ?
                                                                                            <div className='d-flex custom-color-list gap-2'>
                                                                                                {
                                                                                                    item?.data.map((subItem, subIndex) => {
                                                                                                        return (
                                                                                                            <span
                                                                                                                onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                                                key={subIndex}
                                                                                                                className={`${(checkActive(subItem)) ? 'active' : ''}`}
                                                                                                                style={{ backgroundColor: subItem?.code }}
                                                                                                            >
                                                                                                            </span>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div className='d-flex custom-size-list gap-2'>
                                                                                                {
                                                                                                    item?.data.map((subItem, subIndex) => {
                                                                                                        return (
                                                                                                            <span
                                                                                                                onClick={() => changeVariant({ name: item?.name, data: subItem })}
                                                                                                                key={subIndex}
                                                                                                                className={`text-uppercase ${(checkActive(subItem)) ? 'active' : ''}`}
                                                                                                            >
                                                                                                                {subItem?.code}
                                                                                                            </span>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className='d-grid gap-1 custom-size'>
                                                                        <div className='d-flex gap-1'>
                                                                            <span className='p-color'>Quantity: </span>
                                                                            <p className='fw-semibold m-0 text-capitalize'>{selectedProduct?.quantity}</p>
                                                                        </div>
                                                                        <div className='d-flex custom-qty-list gap-0'>
                                                                            <button
                                                                                onClick={() => setSelectedProduct((prevData) => ({
                                                                                    ...prevData,
                                                                                    quantity: (selectedProduct?.quantity > 1) ? selectedProduct?.quantity - 1 : 1
                                                                                }))}
                                                                            >
                                                                                <i className="bi bi-dash-lg"></i>
                                                                            </button>
                                                                            <input type="number" value={selectedProduct?.quantity} className='text-center' readOnly />
                                                                            <button
                                                                                onClick={() => setSelectedProduct((prevData) => ({
                                                                                    ...prevData,
                                                                                    quantity: (selectedProduct?.quantity < 20) ? selectedProduct?.quantity + 1 : 20
                                                                                }))}
                                                                            >

                                                                                <i className="bi bi-plus-lg" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex-between-align gap-2 custom-cart-btn'>
                                                                        {/* <button
                                                                            className='fw-semibold flex-center-align gap-2'
                                                                            onClick={updateCartLocal}>
                                                                            <i className="bi bi-cart-plus-fill fs-16" />
                                                                            Add to Cart
                                                                        </button> */}
                                                                        <button className='fw-semibold' onClick={() => {
                                                                            updateCartLocal({ try: "1" })
                                                                            getCartList();
                                                                        }}>Buy Now</button>
                                                                    </div>
                                                                    <div className='d-grid'>
                                                                        <h4 className='my-2 theme-color'>About product</h4>
                                                                        <p className='fs-14'>{product?.description}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </Container>
                        </Tab>
                        <Tab eventKey="selectUser" title="2. User Detail" disabled={cartData?.length > 0 ? false : true}>
                            <Container className='py-3 seller-product-page'>
                                <Row className='gy-3 gy-sm-4'>
                                    <Col className='col-12 custom-create-order-accordion'>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18'>
                                                        <CiCirclePlus />Add new user
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <FormComponent
                                                        action={frontEnd_API.users}
                                                        submitFullWidth={false}
                                                        submitBTNAlign={'justify-content-center'}
                                                        formSubmit={"User Created Successfully !"}
                                                        button={'Create User'}
                                                        onSubmit={(res) => {
                                                            console.log("res from form::", res);
                                                            getUserList();
                                                            setSelectedUser(res?.data);
                                                            setTabHodlder("shipping")
                                                        }
                                                        }
                                                        fields={[
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'name',
                                                                key: 'name',
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'email',
                                                                key: 'email',
                                                                placeholder: 'sample@gmail.com',
                                                                required: true,
                                                                pattern: pattern.email,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'mobile',
                                                                key: 'mobile',
                                                                type: 'number',
                                                                placeholder: 'Enter 10 digit mobile number',
                                                                required: true,
                                                                pattern: pattern.mobile,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Password',
                                                                key: 'password',
                                                                type: 'password',
                                                                required: true,
                                                                pattern: pattern.password,
                                                            }]}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                    <Col className='col-12 d-grid gap-2'>
                                        <span className='ps-2 pb-2 ms-1 fw-semibold fs-20 fs-sm-14 fs-md-16'>Or select from existing user :</span>
                                        <Container className='custom-order-list'>
                                            <Row className='d-none d-md-flex custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0'>
                                                <Col className='col-2 text-center'>Select</Col>
                                                <Col className='col-2'>User Name</Col>
                                                <Col className='col-3'>Email</Col>
                                                <Col className='col-2'>Mobile</Col>
                                                <Col className='col-3'>Address</Col>
                                            </Row>
                                            {/* Order List Starts here... */}
                                            {
                                                loader ?
                                                    <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                        <Loader />
                                                    </div>
                                                    : (!userList?.length > 0)
                                                        ? <div className='py-5'>
                                                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                                <div>
                                                                    <GiNotebook />
                                                                </div>
                                                                <h5 className='fw-semibold'>No users yet</h5>
                                                                <p className='p-color fs-12'>users data will appear here after your users create account.</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                userList.map((item, index) => {
                                                                    return (
                                                                        <Row
                                                                            key={index}
                                                                            className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0 align-items-center'
                                                                        >
                                                                            <Col className='col-12 col-md-2 custom-user-list d-grid'>
                                                                                <button
                                                                                    className={`fw-semibold flex-center-align gap-2 
                                                                                        ${(selectedUser?.id === item.id) ? 'active' : ''}`}
                                                                                    onClick={() => {
                                                                                        setSelectedUser(item)
                                                                                        setTabHodlder("shipping")
                                                                                    }}
                                                                                >
                                                                                    <i className="bi bi-check"></i>
                                                                                    <span>{(selectedUser?.id === item.id) ? 'Selected' : 'Select'}</span>
                                                                                </button>
                                                                            </Col>
                                                                            <Col className='col-12 col-md-2 text-center text-md-start'>
                                                                                {item?.name}
                                                                            </Col>
                                                                            <Col className='col-12 col-md-3 fs-14 text-center text-md-start'>{item?.email}</Col>
                                                                            <Col className='col-12 col-md-2 text-center text-md-start'>{item?.mobile}</Col>
                                                                            <Col className='col-12 col-md-3 text-center text-md-start'>
                                                                                <address className='fs-12 m-0 fs-sm-10'>
                                                                                    {
                                                                                        (item?.address?.name) ?
                                                                                            <>
                                                                                                {item?.address?.addressLine1}, {item?.address?.addressLine2}, {item?.address?.city}, {item?.address?.state} - {item?.address?.pinCode}
                                                                                            </>
                                                                                            : <i className='fw-semibold'>
                                                                                                Address not added yet !
                                                                                            </i>
                                                                                    }
                                                                                </address>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                            }
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="shipping" title="3. Shipping" disabled={selectedUser?.id ? false : true}>
                            <Container className='py-3 seller-product-page'>
                                <Row className='gy-3 gy-sm-4'>
                                    <Col className='col-12 p-0 custom-create-order-accordion d-grid gap-3'>
                                        <Accordion defaultActiveKey={"0"}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-14'>
                                                        <FaDotCircle />Selected Product List :
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body className='p-2 ps-sm-3'>
                                                    <div className='cartDetail ff-lexend'>
                                                        <Container className='py-4 m-0 w-100'>
                                                            {
                                                                (cartData?.length > 0) ?
                                                                    <Row>
                                                                        <Col className='col-12 col-lg-8 pe-4'>
                                                                            <div style={{ position: 'sticky', top: '15px' }}>
                                                                                {
                                                                                    (apiData?.cartData?.length > 0) &&
                                                                                    <div className='d-grid gap-3'>{
                                                                                        apiData?.cartData.map((item, index) => {

                                                                                            const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                                                            return (
                                                                                                <>
                                                                                                    <Row
                                                                                                        key={index}
                                                                                                        className={`position-relative cart-card py-2 py-sm-3 ${(item?.returnOrderId > 0 ? "border-danger border-2" : "")}`}
                                                                                                        style={{ backgroundColor: (item?.returnOrderId > 0) && "#fff7f8" }}
                                                                                                    >
                                                                                                        <Col className='col-3 col-sm-2 ps-0 pe-1 pe-sm-2'>
                                                                                                            <div className='cart-img-holder position-relative'>
                                                                                                                <img src={SERVER_URL + item?.thumbnail} className='w-100' alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                                                                                <Link className='position-absolute top-0 start-0 w-100 h-100'
                                                                                                                    onClick={() => {
                                                                                                                        setTabHodlder("selectedProduct")
                                                                                                                        getSingleProduct(item?.productId)
                                                                                                                    }}
                                                                                                                ></Link>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col className='col-9 col-sm-10 p-0 ps-auto'>
                                                                                                            <div className='d-flex h-100'>
                                                                                                                <div className='w-100 d-grid align-items-start'>
                                                                                                                    <div className='d-grid d-lg-flex justify-content-between cartProductsize'>
                                                                                                                        <Link className='m-0' onClick={() => {
                                                                                                                            setTabHodlder("selectedProduct")
                                                                                                                            getSingleProduct(item?.productId)
                                                                                                                        }}>{item?.productName}</Link>
                                                                                                                        <h5 className='m-0 text-start text-lg-end theme-color'><span className='text-secondary'>MRP</span> ₹{item?.total}</h5>
                                                                                                                    </div>
                                                                                                                    <div className='d-grid d-sm-flex qtyPlusMinuse pt-2'>
                                                                                                                        {
                                                                                                                            (item?.variantDetail?.length > 0) &&
                                                                                                                            item?.variantDetail.map((varItem, varIndex) => (
                                                                                                                                <section className='p-1 px-lg-2' key={varIndex}>
                                                                                                                                    {varItem?.parentName}:
                                                                                                                                    <span className='ps-1 text-capitalize'>{varItem?.variantName}</span>
                                                                                                                                </section>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                        <section className='p-1 px-lg-2'>Qty: <span>{item?.quantity}</span></section>
                                                                                                                    </div>
                                                                                                                    <div className='fs-12 ps-1 p-color d-none d-sm-block'>Other Details </div>
                                                                                                                    <hr className='m-0 mb-2 d-none d-sm-block' />
                                                                                                                    <div className='d-none d-sm-flex align-items-center fs-14 theme-color'>
                                                                                                                        <FaTruckFast /><section className='ps-1'>Est Delivery: <span>{moment().add(7, 'days').format('dddd, DD MMM YYYY')}</span></section>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='px-2 px-sm-4 px-lg-4 fs-20 customHeartColor d-grid align-content-between'>
                                                                                                                    <Link onClick={() => deleteCartItem(index)}><i className="bi bi-trash3"></i></Link>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col className='cartItemPrd d-flex d-sm-none fs-12 py-2 mt-2 theme-color'>
                                                                                                            <FaTruckFast /><section className='ps-1'>Est Delivery: <span>Friday, 14 Sept 2024</span>.</section>
                                                                                                        </Col>
                                                                                                        {(item?.returnOrderId > 0) &&
                                                                                                            <Alert variant="danger" className="fw-semibold m-0 position-absolute bottom-0 end-0 fs-14 py-1" style={{ width: "auto" }}>
                                                                                                                Return Order
                                                                                                            </Alert>
                                                                                                        }
                                                                                                    </Row>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                        <div
                                                                                            className='text-danger fw-bold d-flex gap-1'
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={handleCartClear}
                                                                                        >
                                                                                            <i class="bi bi-trash3-fill"></i>Clear All
                                                                                        </div>
                                                                                    </div>

                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-12 col-lg-4 p-0 mt-5 mt-lg-0'>
                                                                            <div className='priceBox' style={{ position: 'sticky', top: '15px' }}>
                                                                                <div className='p-3'>
                                                                                    <h5 className='fs-18 pb-2'>Price Details</h5>
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className='fs-16 w-100'>Subtotal</span>
                                                                                        <span className='w-100 text-end'>₹{apiData?.subTotalAmount}</span>
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-between' pt-1>
                                                                                        <span className='fs-16 w-100'>Discount {`${apiData?.couponCode && `(${apiData?.couponCode})`}`}</span>
                                                                                        <span className='w-100 text-end'>- ₹{parseInt(apiData?.couponAmount)}</span>
                                                                                    </div>
                                                                                    <hr className='my-2' />
                                                                                    <div className='d-flex justify-content-between fw-semibold'>
                                                                                        <span className='fs-18 w-100'>Grand Total</span>
                                                                                        <span className='fs-18 w-100 text-end'>₹{apiData?.grossAmount}</span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    (apiData?.couponAmount) ? <div className='fs-12 py-1 text-center bgPink'>
                                                                                        <span>Congrats! You saved total <span className='text-success fs-14'>₹{apiData?.couponAmount}</span> on this order.</span>
                                                                                    </div>
                                                                                        : ''
                                                                                }
                                                                                {/* <hr className='my-0' />
                                                                                <div className='d-grid finalCheckOutBtn p-3 pt-1 pt-md-3 pb-0 pb-md-2 text-center footerForCart'>
                                                                                    <button
                                                                                        className='mt-2 half-border-rad fw-semibold'
                                                                                        onClick={handleCheckOut}
                                                                                    >Proceed to checkout</button>
                                                                                </div> */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    <div className='text-center d-grid align-items-center'
                                                                        style={{ height: '50vh' }}>
                                                                        <h1 className='flex-center-align gap-3 theme-color flex-wrap'><PiNoteBlank />Cart is Empty</h1>
                                                                    </div>
                                                            }
                                                        </Container>
                                                    </div >
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Accordion defaultActiveKey={!selectedUser?.address?.name ? "2" : "1"}>
                                            <Accordion.Item eventKey={!selectedUser?.address?.name ? "2" : "1"}>
                                                <Accordion.Header>
                                                    <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-12'>
                                                        <CiCirclePlus />Add New Shipping Address :
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <FormComponent
                                                        noAction={true}
                                                        onSubmit={(res) => {
                                                            setSelectedUser({
                                                                ...selectedUser,
                                                                address: {
                                                                    ...res
                                                                }
                                                            })
                                                            console.log("res::", selectedUser);
                                                        }}
                                                        submitFullWidth={false}
                                                        submitBTNAlign={'justify-content-start'}
                                                        formMessage={"New Address Added !"}
                                                        button={"Add Address"}
                                                        fields={[
                                                            {
                                                                divClass: 'col-12',
                                                                name: 'Name',
                                                                key: 'name',
                                                                required: true,
                                                            },
                                                            {
                                                                type: 'email',
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Email',
                                                                key: 'email',
                                                                required: true,
                                                                pattern: pattern.email
                                                            },
                                                            {
                                                                type: 'number',
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Mobile',
                                                                key: 'mobile',
                                                                required: true,
                                                                pattern: pattern.mobile
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Address Line 1',
                                                                key: 'addressLine1',
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-md-6',
                                                                name: 'Address Line 2',
                                                                key: 'addressLine2',
                                                            },
                                                            {
                                                                type: 'select',
                                                                options: data,
                                                                divClass: 'col-6 col-lg-4',
                                                                name: 'state',
                                                                key: 'state',
                                                                required: true,
                                                                onChange: {
                                                                    from: 'children',
                                                                    to: 'city',
                                                                    options: data
                                                                }
                                                            },
                                                            {
                                                                divClass: 'col-6 col-lg-4',
                                                                type: 'select',
                                                                name: 'city',
                                                                key: 'city',
                                                                required: true,
                                                            },
                                                            {
                                                                divClass: 'col-12 col-lg-4',
                                                                name: 'Pincode',
                                                                key: 'pinCode',
                                                                required: true,
                                                            },
                                                        ]}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        {(selectedUser?.address?.name) &&
                                            <Accordion defaultActiveKey={"0"}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <span className='fw-semibold d-flex gap-2 align-items-center fs-18 fs-sm-12'>
                                                            <FaDotCircle />Selected Shipping Address :
                                                        </span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Card>
                                                            <Card.Header className='fw-semibold'><i className="bi bi-geo-alt-fill fs-14"></i> {selectedUser?.address?.name}</Card.Header>
                                                            <Card.Body className='d-grid align-content-between'>
                                                                {/* <Card.Title>Special title treatment</Card.Title> */}
                                                                <Card.Text className='d-grid gap-1 gap-md-2'>
                                                                    <div className='d-flex gap-2 fs-14'>
                                                                        <i className="bi bi-envelope fs-14"></i>
                                                                        <span>{selectedUser?.address?.email}</span>
                                                                    </div>
                                                                    <div className='d-flex gap-2 fs-14'>
                                                                        <i className="bi bi-telephone-fill fs-14"></i>
                                                                        <span>{selectedUser?.address?.mobile}</span>
                                                                    </div>
                                                                    <div className='d-flex gap-2'>
                                                                        <i className="bi bi-geo-alt-fill fs-14"></i>
                                                                        <address className='fs-14 m-0 fs-sm-12'>
                                                                            {selectedUser?.address?.addressLine1}, {selectedUser?.address?.addressLine2}, {selectedUser?.address?.city}, {selectedUser?.address?.state} - {selectedUser?.address?.pinCode}
                                                                        </address>
                                                                    </div>
                                                                </Card.Text>
                                                                {/* <button
                                                                    className={`fw-semibold flex-center-align gap-2 
                                                                        ${(selectedUser?.id === selectedUser.id) ? 'active' : ''}`}
                                                                    // onClick={() => setSelectedAddress(selectedUser)}
                                                                >
                                                                    <i className="bi bi-check"></i>
                                                                    <span>{(selectedUser?.id === selectedUser.id) ? 'Selected' : 'Select'}</span>
                                                                </button> */}
                                                            </Card.Body>
                                                        </Card>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        }
                                        <Button
                                            variant={"success"}
                                            disabled={selectedUser?.address?.name ? false : true}
                                            onClick={() => {
                                                handleTaxFromAPI();
                                            }}
                                        >Next</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="payment" title="4. Payment" disabled={selectedUser?.address?.name ? false : true}>
                            <Container fluid className='custom-checkout-page'>
                                <Row className='pf-flex-lg-col-reverse'>
                                    <Col className='col-12 col-lg-6 custom-checkout-page-steps py-4 py-md-4 pb-0 d-grid position-relative'>
                                        <div className='position-sticky top-0'>
                                            <div className='custom-img-holder-container d-flex align-items-center gap-3'>
                                                <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-none d-md-block me-3'>{storeData?.name}</Link>
                                                <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-block d-md-none'>{storeData?.name.substring(0, 12)}{(storeData?.name.length > 12) && '...'}</Link>
                                            </div>
                                            <Container className='flex-center-align'>
                                                <div className='text-center d-grid align-content-start gap-5 custom-payment-tab py-4 py-sm-5'>
                                                    <div className='flex-center-align justify-content-start  flex-wrap gap-2 gap-md-3 gap-lg-4 px-0 px-sm-4'>
                                                        <div
                                                            className={`payment-list-item flex-center-align full-border-rad w-100 gap-2 gap-sm-3 ${(paymentType === "COD") ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setPaymentType("COD")
                                                                setOtherPayment()
                                                            }}
                                                        >
                                                            <div
                                                                className='h4 m-0 fs-sm-16 fs-20 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                <FaTruckFast />
                                                                <span>COD</span>
                                                            </div>
                                                        </div>
                                                        <div className='d-grid gap-3 w-100'>
                                                            <div
                                                                className={`m-0 payment-list-item flex-center-align full-border-rad gap-2 gap-sm-3 ${(paymentType === "UPI") ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    setPaymentType("UPI")
                                                                    setOtherPayment()
                                                                }}
                                                            >
                                                                <div
                                                                    className='h4 m-0 fs-sm-16 fs-20 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                    <CiWallet />
                                                                    <span>UPI</span>
                                                                    {
                                                                        (otherPayment?.orderUPI) &&
                                                                        <span className='fs-14 fs-sm-10 fw-semibold ps-1 lh-sm'>{`(${otherPayment?.orderUPI})`}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                (paymentType == "UPI" && !otherPayment) &&
                                                                <div className='d-grid text-start py-3'>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            {/* <Card.Title>Enter UPI ID :</Card.Title> */}
                                                                            <Card.Text>
                                                                                <FormComponent
                                                                                    noAction={true}
                                                                                    onSubmit={(res) => {
                                                                                        setOtherPayment({
                                                                                            ...res
                                                                                        })
                                                                                    }}
                                                                                    submitFullWidth={true}
                                                                                    formMessage={"UPI ID stored !"}
                                                                                    button={"Confirm"}
                                                                                    fields={[
                                                                                        {
                                                                                            divClass: 'col-12',
                                                                                            name: 'UPI ID',
                                                                                            key: 'orderUPI',
                                                                                            required: true,
                                                                                            pattern: pattern.upi
                                                                                        }
                                                                                    ]}
                                                                                />
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            }
                                                            <span className='text-center text-secondary fs-16 fs-md-14 fs-sm-12'>-- OR --</span>
                                                            <div
                                                                className={`m-0 payment-list-item flex-center-align full-border-rad gap-2 gap-sm-3 ${(paymentType === "BANK") ? 'active' : ''}`}
                                                                onClick={() => {
                                                                    setPaymentType("BANK");
                                                                    setOtherPayment();
                                                                }}
                                                            >
                                                                <div
                                                                    className='h4 m-0 fs-sm-16 fs-20 flex-center-align  gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                    <AiOutlineBank />
                                                                    <span>Bank Details</span>
                                                                    {
                                                                        (otherPayment?.orderBankName) &&
                                                                        <span className='fs-14 fs-sm-10 fw-semibold ps-1 lh-sm'>{`(${otherPayment?.orderBankName})`}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                (paymentType == "BANK" && !otherPayment) &&
                                                                <div className='d-grid text-start py-3'>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            {/* <Card.Title>Enter UPI ID :</Card.Title> */}
                                                                            <Card.Text>
                                                                                <FormComponent
                                                                                    noAction={true}
                                                                                    onSubmit={(res) => {
                                                                                        setOtherPayment({
                                                                                            ...res
                                                                                        })
                                                                                    }}
                                                                                    submitFullWidth={true}
                                                                                    formMessage={"Bank Details Added !"}
                                                                                    button={"Confirm"}
                                                                                    fields={[
                                                                                        {
                                                                                            divClass: 'col-12',
                                                                                            name: 'Bank Name',
                                                                                            key: 'orderBankName',
                                                                                            required: true,
                                                                                        },
                                                                                        {
                                                                                            divClass: 'col-12 col-md-6',
                                                                                            name: 'Holder Name',
                                                                                            key: 'orderAccountHolderName',
                                                                                            required: true,
                                                                                        },
                                                                                        {
                                                                                            divClass: 'col-12 col-md-6',
                                                                                            name: 'Account No.',
                                                                                            key: 'orderAccountNumber',
                                                                                            required: true,
                                                                                        },
                                                                                        {
                                                                                            divClass: 'col-12',
                                                                                            name: 'IFSC Code',
                                                                                            key: 'orderIfscCode',
                                                                                            required: true,
                                                                                        }
                                                                                    ]}
                                                                                />
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='px-3 d-grid'>
                                                        <button
                                                            className={`custom-final-btn fs-18 fw-semibold ${(paymentType == "COD" || (paymentType == "UPI" && otherPayment?.orderUPI) || (paymentType == "BANK" && otherPayment?.orderBankName)) ? 'active' : ''}`}
                                                            onClick={handleOrderProcess}
                                                            disabled={(paymentType == "COD" || (paymentType == "UPI" && otherPayment?.orderUPI) || (paymentType == "BANK" && otherPayment?.orderBankName))
                                                                ? false : true}
                                                        >
                                                            Confirm Order
                                                        </button>
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-lg-6 custom-checkout-page-summary p-4 d-grid align-items-center'>
                                        <div className='py-4 py-sm-5 px-0 px-sm-4 px-lg-0 px-xl-4'>
                                            <div className='d-grid gap-4 gap-sm-5'>
                                                <h3 className='fw-semibpold m-0'>Order summary</h3>
                                                <div className='d-grid gap-3'>
                                                    {
                                                        (newCartData?.cartData?.length > 0) &&
                                                        newCartData?.cartData?.map((item, index) => {
                                                            const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                            return (
                                                                <div className='order-product-list gap-2' key={index}>
                                                                    <div className='img-holder'>
                                                                        <img src={SERVER_URL + item?.thumbnail} alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between flex-wrap gap-2 w-100'>
                                                                        <div className='d-grid gap-2 gap-sm-3 align-content-start'>
                                                                            <h5 className='fw-semibold m-0 fs-sm-14'>{item?.productName}</h5>
                                                                            <div className='d-flex gap-2 varient-list-data flex-wrap'>
                                                                                {
                                                                                    (item?.variantDetail?.length > 0) &&
                                                                                    item?.variantDetail.map((varItem, varIndex) => (
                                                                                        <span key={varIndex} className='px-2 py-1 fs-14 fs-sm-10 text-capitalize'>
                                                                                            {varItem?.parentName}: {varItem?.variantName}
                                                                                        </span>))
                                                                                }
                                                                                <span className='px-2 py-1 fs-14 fs-sm-10'>Qty : {item?.quantity}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className='fw-semibold m-0 fs-sm-14'>₹{item?.total}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='d-grid gap-2 gap-sm-4'>
                                                    <div className='list-calc d-grid gap-2'>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Items ({newCartData?.cartData?.length})</span>
                                                            <span>₹{newCartData?.subTotalAmount}</span>
                                                        </div>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Discount <span className='fs-14 fs-sm-10 fs-lg-12'>{newCartData?.couponCode ? `(${newCartData?.couponCode})` : ''}</span></span>
                                                            <span>- ₹{newCartData?.couponAmount}</span>
                                                        </div>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Shipping <span className={`fs-14 fs-sm-10 fs-lg-12 ${newCartData?.orderShippingChargeAmount ? "text-success fw-semibold" : "d-none"}`}>(Free Shipping)</span></span>
                                                            <span className='d-flex gap-2 align-items-center'>
                                                                <span className={`fs-sm-12 ${(!newCartData?.orderShippingChargeAmount) ? 'fs-16' : ''}`}>
                                                                    {
                                                                        (newCartData?.orderShippingChargeAmount) ?
                                                                            <>- <span className='text-decoration-line-through'>₹{newCartData?.orderShippingChargeAmount}</span></>
                                                                            : "Calculated at next step"
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                                            <span>Tax</span>
                                                            <span className='d-flex gap-2 align-items-center'>
                                                                <span className={`fs-sm-12 ${(!newCartData?.orderTaxAmount) ? 'fs-16' : ''}`}>
                                                                    {
                                                                        (newCartData?.orderTaxAmount) ?
                                                                            `+ ₹${newCartData?.orderTaxAmount}`
                                                                            : "Calculated at next step"
                                                                    }
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <hr className='mb-2 mb-sm-0' />
                                                    </div>
                                                    <div className='flex-between-align gap-2'>
                                                        <h2 className='fw-semibold m-0 fs-sm-20'>Total (Inc. Tax)</h2>
                                                        <h2 className='fw-semibold m-0 fs-sm-20'>₹{newCartData?.orderNetAmount ?? newCartData?.grossAmount}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <Modal show={show}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <Container>
                                        <Row>
                                            <Col className='col-12 flex-center-align'>
                                                <div style={{ width: "300px", aspectRatio: "4/3" }}>
                                                    <img src={require('../../Assets/Gif/Animation - 1725267698496.gif')} alt="Description of the GIF" style={{
                                                        objectFit: "contain",
                                                        width: "100%",
                                                        height: "100%"
                                                    }} />
                                                </div>
                                            </Col>
                                            <Col className='col-12 d-grid text-center'>
                                                <h2 className='text-success fw-bold'>Order Placed Successfully !</h2>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </Modal>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SellerCreateOrder