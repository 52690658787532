import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

function OrderSubCategoryList(props) {
    const params = useParams();
    const [handleCat, setHandleCat] = useState(props?.listData?.length > 0 && props?.listData[0]?.value);

    useEffect(() => {
        if (params?.orderStatus) {
            console.log("params?.orderStatus::", params?.orderStatus);
        }
    }, [props?.onTabHandleChange])

    useEffect(() => {
        setHandleCat(props?.listData?.length > 0 && props?.listData[0]?.value)
    }, [params?.groupStatus])

    useEffect(() => {
        if (props?.onCategoryChange && handleCat) {
            return props?.onCategoryChange(handleCat);
        }
    }, [handleCat])

    return (
        <div className='main-custom-order-sub-category'>
            <div className='d-flex custom-order-sub-category'>
                {
                    (props?.listData?.length > 0) &&
                    props?.listData.map((item, index) => (
                        <span
                            key={index}
                            className={`pointer text-center text-capitalize px-2 px-sm-3 py-1 fs-14 fs-md-12 fs-sm-10 ${(item?.value == handleCat) ? "active" : ""}`}
                            onClick={() => setHandleCat(item?.value)}
                            title={item?.label}
                        >
                            {item?.label}
                        </span>
                    ))
                }
            </div>
        </div>
    )
}

export default OrderSubCategoryList