import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap';
import { FaArrowLeftLong, FaLocationCrosshairs } from "react-icons/fa6";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdOutlineDone } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { BsDownload } from "react-icons/bs";
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import ProductPreviewImage from './Products/ProductPreviewImageComponent';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoices from '../../Components/Invoices';
import Loader from '../../Components/Loader';

function SellerSingleOrderPage() {
    const params = useParams();
    const [orderData, setOrderData] = useState();
    const [loader, setLoader] = useState(true);
    const moment = require('moment');

    const getSingleOrder = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.orders}/${params.orderId}`, header)
            console.log("data::", data);
            setOrderData(data?.data)
            if (data?.status === 400) {
                window.location = '/orders'
            }
            setLoader(false);
        }
        catch (e) {
            console.log("e::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getSingleOrder();
    }, [])

    const statusBar = [
        {
            label: 'created',
            value: 1,
        },
        {
            label: 'Processing',
            value: 1,
        },
        {
            label: 'Shipped',
            value: 0,
        },
        {
            label: 'Delivered',
            value: 0,
        }
    ];

    document.title = `#${orderData?.orderId} | ${siteConfig?.name}`;

    return (
        <div className='py-2 custom-single-order-container'>
            <Container className='custom-single-order py-4'>
                <div className='d-grid'>
                    <div className='d-flex'>
                        <Link 
                        to={(params?.orderType) ? `/orders/${params?.orderType}` : `/orders`} className='custom-single-order-back-link'><FaArrowLeftLong />Back</Link>
                    </div>
                    <div className='d-grid gap-2 gap-lg-0 d-lg-flex justify-content-sm-between align-items-center pt-3'>
                        <div className='d-grid'>
                            <span className='h3 fw-semibold m-0 fs-sm-20 text-capitalize'>{orderData?.orderType} Order #{orderData?.orderId}</span>
                            <span className='p-color fs-14 fs-sm-12'>Created {moment(orderData?.orderDate).format('MMM D, YYYY h:mm A')}</span>
                        </div>
                        <div className='d-none d-sm-flex gap-2 custom-order-header-btn justify-content-sm-end'>
                            {/* <Link className='flex-between-align gap-2 fs-14'><RiDeleteBin6Line />Cancel order</Link> */}
                            {/* <Link className='flex-between-align gap-2 fs-14'><IoDuplicate />Duplicate</Link> */}
                            {/* <Link className='flex-between-align gap-2 fs-14'><BsDownload />Download Invoice</Link> */}
                            <PDFDownloadLink
                                document={<Invoices data={
                                    {
                                        invoiceTitle: "Order Invoice",
                                        customerName: orderData?.userData?.name,
                                        customerAddress: {
                                            addressLine1: orderData?.orderBillingAddressLine1,
                                            addressLine2: orderData?.orderBillingAddressLine2,
                                            area: orderData?.orderBillingArea,
                                            city: orderData?.orderBillingCity,
                                            state: orderData?.orderBillingState,
                                            pinCode: orderData?.orderBillingPincode,
                                        },
                                        customerMobileNumber: orderData?.userData?.mobile,
                                        customerShippingAddressState: orderData?.orderShippingCity,
                                        invoiceNumber: orderData?.orderNumber,
                                        purchaseOrderNumber: orderData?.orderId,
                                    }
                                } />}
                                fileName="myDocument.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : <span className='flex-center-align gap-2 fs-14'><BsDownload className='me-1' /> Download Invoice</span>
                                }
                            </PDFDownloadLink>
                            {/* <PDFViewer width="100%" height="600">
                                <Invoices />
                            </PDFViewer> */}
                        </div>
                        <div className='d-grid d-sm-none w-100'>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100' style={{ backgroundColor: "white" }}>
                                    More
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    <Dropdown.Item className='d-flex align-items-center gap-2'><BsDownload />Download Invoice</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {
                    loader ?
                        <div className='d-grid align-content-center' style={{ minHeight: "80vh" }}>
                            <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                <Loader />
                            </div>
                        </div>
                        : <Row className='pt-3'>
                            <Col className='col-12 col-md-8 d-grid gap-3 align-content-start' >
                                {
                                    (orderData?.orderChildData?.length > 0) &&
                                    orderData?.orderChildData.map((row, pIndex) => {
                                        const colorCode = row?.childOrderVariantData?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                        let cnt = 0;
                                        cnt--;
                                        if (["pending", "seller-accepted"].includes(row?.orderChildStatus)) {
                                            cnt = 1;
                                        }
                                        else if (["provider-accepted", "provider-completed", "provider-packed"].includes(row?.orderChildStatus)) {
                                            cnt = 2;
                                        }
                                        else if (["dispatch", "on-way"].includes(row?.orderChildStatus)) {
                                            cnt = 3;
                                        }
                                        else if (["delivered"].includes(row?.orderChildStatus)) {
                                            cnt = 4;
                                        }
                                        else if (["return-by-user", "cancel-by-user"].includes(row?.orderChildStatus)) {
                                            cnt = 0;
                                        }
                                        else if (["seller-cancel", "provider-cancel"].includes(row?.orderChildStatus)) {
                                            cnt = -1;
                                        }
                                        else {
                                            cnt = -2;
                                        }

                                        return (
                                            <div className='d-grid seller-box p-3 p-lg-4 gap-3' key={pIndex}>

                                                {/* Product Title */}
                                                <div className='d-flex gap-2'>
                                                    <div className='d-grid w-100 align-content-start gap-1 gap-sm-2'>
                                                        <b className='fs-20 fs-lg-18 fs-sm-16 text-capitalize d-none d-sm-block'>{row?.productName}</b>
                                                        <b className='fs-20 fs-lg-18 fs-sm-16 text-capitalize d-block d-sm-none'>{row?.productName.substring(0, 12) + '...'}</b>
                                                        <div className='d-flex align-items-center gap-1 fs-14 fs-sm-12'>
                                                            <span className='text-capitalize'>Price : ₹{parseInt(row?.productMrp)}</span>
                                                            <span className='text-capitalize pe-2'>Qty : {parseInt(row?.productQuantity)}</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-grid custom-order-product-img border-card'>
                                                        <ProductPreviewImage
                                                            colorCode={colorCode ?? '#ffffff'}
                                                            isButtonShow={false}
                                                            customScale={10}
                                                            objectData={{
                                                                preview: row?.productThumbnail || null,
                                                            }} />
                                                    </div>
                                                </div>

                                                {/* Order Status Bar */}
                                                <div className={`d-block d-sm-flex flex-between-align custom-order-status-bar mb-sm-3 ${(cnt <= 0) ? "d-flex w-100" : ""}`}>
                                                    {
                                                        (cnt > 0) ?
                                                            statusBar.map((item, index) => {
                                                                return (
                                                                    <div key={index}
                                                                        className={`w-100 d-flex align-items-center 
                                                                custom-order-status-bar-div gap-2 fw-semibold fs-14 
                                                    ${(cnt > index) ? 'custom-bg-info text-light ' : ''}
                                                    ${((cnt - 1) == index) ? 'last-child-style' : ''}`}
                                                                    >
                                                                        {(cnt > index)
                                                                            ? < MdOutlineDone />
                                                                            : <GoDotFill />
                                                                        }
                                                                        <span className='text-capitalize'>{item.label}</span>
                                                                        <section className='fw-meduim fs-12 fs-sm-10'>{item.date}</section>
                                                                    </div>
                                                                )
                                                            })
                                                            : (cnt == 0) ?
                                                                <div className={`w-100 align-items-center 
                                                        custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light text-capitalize `} style={{ borderRadius: "2rem" }}>
                                                                    {row?.orderChildStatus.replace("-", " ")}
                                                                </div>
                                                                : (cnt == -1) ?
                                                                    <div className={`w-100 align-items-center 
                                                            custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light text-capitalize `} style={{ borderRadius: "2rem" }}>
                                                                        {row?.orderChildStatus.replace("-", " ")}
                                                                    </div>
                                                                    : (cnt == -2) &&
                                                                    <div className={`w-100 align-items-center 
                                                                custom-order-status-bar-div gap-2 fw-semibold fs-14 custom-bg-info bg-danger text-center text-light text-capitalize `} style={{ borderRadius: "2rem" }}>
                                                                        Tracking data not found. Please try again later.
                                                                    </div>
                                                    }
                                                </div>

                                                {/* Price List */}
                                                <div className='d-grid pt-2 pt-sm-4 gap-1 gap-sm-2'>
                                                    <div className='custom-price-list-table'>
                                                        <div>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='fs-14 text-center'>Product name</th>
                                                                        <th className='fs-14 text-center'>Qty</th>
                                                                        <th className='fs-14 text-center'>Price</th>
                                                                        <th className='fs-14 text-center'>Total</th>
                                                                        <th className='fs-14 text-center'>Shipping</th>
                                                                        <th className='fs-14 text-center'>Discount</th>
                                                                        <th className='fs-14 text-center'>Sub Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className='fs-14'>
                                                                        <td className='ps-2'>{row?.productName.substring(0, 12) + '...'}</td>
                                                                        <td className='text-center'>{parseInt(row?.productQuantity)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productPrice)}</td>
                                                                        <td className='text-center'>{parseInt(row?.productSubTotal)}</td>
                                                                        <td className='text-center'>{((parseInt(row?.productShippingCharge) !== 0) ? '-' : '') + parseInt(row?.productShippingCharge)}</td>
                                                                        <td className='text-center'>{((parseInt(row?.productDiscount) !== 0) ? '-' : '') + parseInt(row?.productDiscount)}</td>
                                                                        <td className='text-center fw-semibold'>₹{parseInt(row?.productSubTotal) - (parseInt(row?.productShippingCharge)) - parseInt(row?.productDiscount)}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th className='fs-16 text-center' colSpan={6}>Grand Total</th>
                                                                        <th className='fs-16 text-center'>₹{parseInt(row?.productSubTotal) - (parseInt(row?.productShippingCharge)) - parseInt(row?.productDiscount)}</th>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {/* Order Main Tracking */}

                                <div className='d-grid seller-box p-3 p-lg-4 gap-2'>
                                    <h5 className='my-2 fw-semibold color-temp d-flex align-items-center gap-3'><FaLocationCrosshairs /> Order Tracking :</h5>
                                    <div className='d-grid timeline'>
                                        {
                                            (orderData?.orderTrackingData?.length > 0) &&
                                            orderData?.orderTrackingData.map((item, index) => {
                                                return (
                                                    <div key={index} className={`timeline-item ${((orderData?.orderTrackingData?.length - 1) === index) ? 'lastItem' : ''}`}>
                                                        <div
                                                            className={`timeline-icon circle-one mt-1 ms-1`}
                                                            style={{
                                                                backgroundColor: (index === 0) ? 'darkblue' : 'grey',
                                                                outlineColor: (index === 0) ? 'darkblue' : 'grey',
                                                            }}
                                                        ></div>
                                                        <div className='timeline-content'>
                                                            <span className='timestamp'>{moment(item?.updatedAt).format('MMM D, YYYY h:mm A')}</span>
                                                            <div>
                                                                <b className='text-capitalize'>{item?.trackingStatus}</b>
                                                                <span className='description'>{item?.trackingRemark}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-4 gap-2 pt-4 pt-md-0 '>
                                <div className='position-sticky ' style={{ top: "25px" }}>
                                    <div className='d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Customer</h5>
                                            {/* <Link className='fs-18 fs-lg-14 link-to-normal flex-between-align gap-2'><MdEdit />Edit</Link> */}
                                        </div>
                                        <div className='d-grid p-3 border-bottom'>
                                            <b className='fw-semibold fs-lg-16 fs-18 pb-1'>Personal details</b>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1 fw-semibold'>
                                                <i className="bi bi-person-circle"></i>
                                                {orderData?.orderBillingName}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-envelope"></i>
                                                {orderData?.orderBillingEmail}
                                            </span>
                                            <span className='fs-lg-12 fs-14 d-flex gap-1'>
                                                <i className="bi bi-telephone-fill"></i>
                                                {orderData?.orderBillingMobile}
                                            </span>
                                        </div>
                                        <div className='d-grid p-3'>
                                            <b className='fw-semibold pb-1 fs-lg-16 fs-18'>Shipping address</b>
                                            <p className='m-0 fs-lg-12 fs-14 p-color d-flex flex-wrap text-capitalize'>
                                                {orderData?.orderBillingAddressLine1}, {orderData?.orderBillingAddressLine2}, {orderData?.orderBillingArea}, {orderData?.orderBillingCity}, {orderData?.orderBillingState}, {orderData?.orderBillingCountry} - {orderData?.orderBillingPincode}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-4 d-grid seller-box align-content-start' style={{ overflow: 'hidden' }}>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <h5 className='fw-semibold m-0 fs-lg-16'>Billing</h5>
                                            {/* <Link className='fs-16 fs-lg-14 fw-semibold link-to-normal seller-box flex-between-align  px-lg-3 py-lg-1 px-md-2 px-3 py-1 gap-2'><BsDownload />Download</Link> */}
                                        </div>
                                        <div className='flex-between-align p-3 border-bottom'>
                                            <span className='fs-lg-14'>Total Products value</span>
                                            <b className='fs-lg-14 text-success'>₹{orderData?.orderSubTotalAmount}</b>
                                        </div>
                                        <div className='d-grid gap-1 p-3 border-bottom'>
                                            <div className='flex-between-align'>
                                                <span className='fs-lg-14'>Total Shipping cost</span>
                                                <b className='fs-lg-14 text-danger'>- ₹{orderData?.orderShippingChargeAmount}</b>
                                            </div>
                                        </div>
                                        <div className='flex-between-align p-3'>
                                            <b className='fs-lg-14'>Total costs</b>
                                            <b className='color-info fs-lg-16 fs-18'>₹{orderData?.orderNetAmount}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
        </div>
    )
}

export default SellerSingleOrderPage