const ProductSliderList = [
    {
        id: 1,
        name: "Urban Groove: City Lights and Cool Vibes",
        shortName: 'Urban Groove',
        link: '/self',
        thumbnail: require('../Assets/Images/654de688e68ada3dc707f26d.png'),
        thumbnailSlide: require('../Assets/Images/654b9e92bd3091aa8a08a5b9.png'),
        price: 5499,
        mrp: 7499,
        productFromPrice: 7499,
        variation:
        {
            name: "color",
            data: ['red', 'blue', 'yellow', 'black', 'grey']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 2,
        name: "Cosmic Threads: The Universe Wears This",
        shortName: 'Cosmic Threads',
        link: '/self',
        thumbnail: require('../Assets/Images/65439a6b875d6752d6056b52.png'),
        thumbnailSlide: require('../Assets/Images/65439a7db56afc593101e296.png'),
        price: 4499,
        mrp: 6499,
        startPrice: 4499,
        productFromPrice: 6499,
        variation:
        {
            name: "color",
            data: ['blue', 'black', 'white', 'black',]
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 3,
        name: "Rebel Chic: Fashion with an Attitude",
        shortName: 'Rebel Chic',
        link: '/self',
        thumbnail: require('../Assets/Images/654e2446c4fbc0eeb2052d24.png'),
        thumbnailSlide: require('../Assets/Images/654e2447bdde291499078577.png'),
        price: 7999,
        mrp: 9999,
        startPrice: 7999,
        productFromPrice: 9999,
        variation:
        {
            name: "color",
            data: ['red', 'black', 'blue', 'yellow', 'white',]
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 4,
        name: "Chill Vibes: Relaxed and Ready to Roll",
        shortName: 'Chill Vibes',
        link: '/self',
        thumbnail: require('../Assets/Images/6596da61addbfbabd207c438.png'),
        thumbnailSlide: require('../Assets/Images/6596da645b637814630fa225.png'),
        price: 4550,
        mrp: 5050,
        startPrice: 4550,
        productFromPrice: 5050,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'yellow']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 5,
        name: "Mystic Harmony: Spiritual and Stylish",
        shortName: 'Mystic Harmony',
        link: '/self',
        thumbnail: require('../Assets/Images/6373c097a8189010e801fc83.png'),
        thumbnailSlide: require('../Assets/Images/6358d706f5f53c73470fcf6c.png'),
        price: 7450,
        mrp: 8550,
        startPrice: 7450,
        productFromPrice: 8550,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'blue']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 6,
        name: "Sunset Drifter: Riding the Horizon Waves",
        shortName: 'Sunset Drifter',
        link: '/self',
        thumbnail: require('../Assets/Images/6295ce24da5a4ace6e067552.png'),
        thumbnailSlide: require('../Assets/Images/634693f90175c940820995a3.png'),
        price: 2150,
        mrp: 3450,
        startPrice: 2150,
        productFromPrice: 3450,
        variation:
        {
            name: "color",
            data: ['red', 'cream', 'grey']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
]

const ProductSliderListTwo = [
    {
        id: 1,
        name: "Urban Groove: City Lights and Cool Vibes",
        shortName: 'Urban Groove',
        link: '/self',
        thumbnail: require('../Assets/Images/654de688e68ada3dc707f26d.png'),
        thumbnailSlide: require('../Assets/Images/654b9e92bd3091aa8a08a5b9.png'),
        price: 5499,
        mrp: 7499,
        productFromPrice: 7499,
        variation:
        {
            name: "color",
            data: ['red', 'blue', 'yellow', 'black', 'grey']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 2,
        name: "Cosmic Threads: The Universe Wears This",
        shortName: 'Cosmic Threads',
        link: '/self',
        thumbnail: require('../Assets/Images/65439a6b875d6752d6056b52.png'),
        thumbnailSlide: require('../Assets/Images/65439a7db56afc593101e296.png'),
        price: 4499,
        mrp: 6499,
        startPrice: 4499,
        productFromPrice: 6499,
        variation:
        {
            name: "color",
            data: ['blue', 'black', 'white', 'black',]
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 3,
        name: "Rebel Chic: Fashion with an Attitude",
        shortName: 'Rebel Chic',
        link: '/self',
        thumbnail: require('../Assets/Images/654e2446c4fbc0eeb2052d24.png'),
        thumbnailSlide: require('../Assets/Images/654e2447bdde291499078577.png'),
        price: 7999,
        mrp: 9999,
        startPrice: 7999,
        productFromPrice: 9999,
        variation:
        {
            name: "color",
            data: ['red', 'black', 'blue', 'yellow', 'white',]
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 4,
        name: "Chill Vibes: Relaxed and Ready to Roll",
        shortName: 'Chill Vibes',
        link: '/self',
        thumbnail: require('../Assets/Images/6596da61addbfbabd207c438.png'),
        thumbnailSlide: require('../Assets/Images/6596da645b637814630fa225.png'),
        price: 4550,
        mrp: 5050,
        startPrice: 4550,
        productFromPrice: 5050,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'yellow']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 5,
        name: "Mystic Harmony: Spiritual and Stylish",
        shortName: 'Mystic Harmony',
        link: '/self',
        thumbnail: require('../Assets/Images/6373c097a8189010e801fc83.png'),
        thumbnailSlide: require('../Assets/Images/6358d706f5f53c73470fcf6c.png'),
        price: 7450,
        mrp: 8550,
        startPrice: 7450,
        productFromPrice: 8550,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'blue']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
    {
        id: 6,
        name: "Sunset Drifter: Riding the Horizon Waves",
        shortName: 'Sunset Drifter',
        link: '/self',
        thumbnail: require('../Assets/Images/6295ce24da5a4ace6e067552.png'),
        thumbnailSlide: require('../Assets/Images/634693f90175c940820995a3.png'),
        price: 2150,
        mrp: 3450,
        startPrice: 2150,
        productFromPrice: 3450,
        variation:
        {
            name: "color",
            data: ['red', 'cream', 'grey']
        },
        variantData: [
            {
                name: 'color',
                total: 6
            },
            {
                name: 'sizes',
                data: 8
            },
        ]
    },
]
const sellerSideBarData = [
    {
        icon: 'bi-house-door',
        label: 'Dashboard',
        link: '/'
    },
    {
        icon: 'bi-diagram-3',
        label: 'category',
        link: '/category',
        // data: []
    },
    {
        icon: 'bi-tag',
        label: 'My Products',
        link: '/products'
    },
    {
        icon: 'bi-truck',
        label: 'Orders',
        link: '/orders'
    },
    {
        icon: 'bi-arrow-counterclockwise',
        label: 'Returns',
        link: '/returns'
    },
    {
        icon: 'bi-currency-rupee',
        label: 'Wallet',
        link: '/payment',
    },
    // {
    //     icon: 'bi-bar-chart',
    //     label: 'Insights',
    //     link: 'https://printfuse.in/insights'
    // },
    {
        icon: 'bi-gear',
        label: 'Store setting',
        link: '/store-setting'
    },
    {
        icon: 'bi-bookmark-star',
        label: 'Subscriptions',
        link: '/subscription'
    },
    {
        icon: 'bi-question-circle',
        label: 'Need Help?',
        link: '',
        data: [
            {
                label: 'Contact us',
                link: 'https://printfuse.in/contact-us',
            },
            {
                label: 'Help Center',
                link: 'https://printfuse.in/help-center',
            },
            {
                label: 'My request',
                link: 'https://printfuse.in/my-requests',
            },
        ]
    }
];

const identityData = [
    {
        id: 1,
        name: 'Artist / Designer',
        shortDescription: 'I design my own products or crafts'
    },
    {
        id: 2,
        name: 'Content Creator',
        shortDescription: 'I creator content for social media'
    },
    {
        id: 3,
        name: 'Business',
        shortDescription: "I'm a company that sells products"
    },
    {
        id: 4,
        name: 'Individual',
        shortDescription: 'I make money selling online'
    },
    {
        id: 5,
        name: 'Other',
        shortDescription: 'Nothing from above mentions'
    },
];

const sellerSocialLinks = [
    {
        name: 'twitter',
        icon: 'twitter',
        link: 'https://twitter.com/'
    },
    {
        name: 'instagram',
        icon: 'instagram',
        link: 'https://instagram.com/'
    },
    {
        name: 'facebook',
        icon: 'facebook',
        link: 'https://facebook.com/'
    },
    {
        name: 'google',
        icon: 'google',
        link: 'https://google.com/'
    },
    {
        name: 'youtube',
        icon: 'youtube',
        link: 'https://youtube.com/'
    },
    {
        name: 'linkedin',
        icon: 'linkedin',
        link: 'https://linkedin.com/'
    },
    {
        name: 'pinterest',
        icon: 'pinterest',
        link: 'https://pinterest.com/'
    },

];

const customFilter = [
    {
        title: 'type',
        data: [
            { value: 'orderInvoice', label: 'Order invoice' },
            { value: 'creditNote', label: 'Credit note' },
            { value: 'subscriptionInvoice', label: 'Subscription invoice' }
        ],
    },
    {
        title: 'state',
        data: [
            { value: 'gujarat', label: 'Gujarat' },
            { value: 'maharashtra', label: 'Maharashtra' },
            { value: 'karnataka', label: 'Karnataka' },
            { value: 'tamil_nadu', label: 'Tamil Nadu' },
            { value: 'kerala', label: 'Kerala' },
            { value: 'rajasthan', label: 'Rajasthan' },
            { value: 'uttar_pradesh', label: 'Uttar Pradesh' },
            { value: 'punjab', label: 'Punjab' },
            { value: 'west_bengal', label: 'West Bengal' },
            { value: 'andhra_pradesh', label: 'Andhra Pradesh' }
        ],
    },
    {
        title: 'Store',
        data: [
            { value: 'newcreated', label: 'New Created' },
            { value: 'myShirtFactory', label: 'My Shirt Factory' }
        ]
    }
]

const orderList = [
    {
        orderId: 123456,
        orderType: 'manual',
        orderDate: 'Jul 10, 2024 at 06:10 PM',
        orderBillingName: 'user',
        userMobile: '1234567890',
        userEmail: 'user@gmail.com',
        productionCost: '600',
        shippingCost: '149',
        totalCost: '749',
        productName: 'Lame Black Full-sleeve t-shirt',
        productImg: require('../Assets/Images/download (1).jpeg'),
        productSize: 'M',
        productColor: 'Black',
        productSKU: 'SKU 123456',
        tracking: { value: 'pending', label: 'pending', status: 1 },
        orderStatus: { value: 'on hold', label: 'on hold', status: 1 },
        productionStatus: { value: 'pending', label: 'pending', status: 1 }
    },
    {
        orderId: 123457,
        orderType: 'sample',
        orderDate: 'Jul 08, 2024 at 11:10 AM',
        orderBillingName: 'sample user',
        userMobile: '9185456564',
        userEmail: 'sampleuser@gmail.com',
        productionCost: '744',
        shippingCost: '241',
        totalCost: '985',
        productName: 'Lame Black Full-sleeve t-shirt',
        productImg: require('../Assets/Images/download (2).jpeg'),
        productSize: 'M',
        productColor: 'Black',
        productSKU: 'SKU 123456',
        tracking: { value: 'pending', label: 'pending', status: 1 },
        orderStatus: { value: 'on hold', label: 'on hold', status: 1 },
        productionStatus: { value: 'pending', label: 'pending', status: 1 }
    }
]

const tempSingleOrder = {
    "orderId": 1,
    "id": 1,
    "storeId": 31,
    "sellerId": 26,
    "userId": 17,
    "providerId": 2,
    "oldProviderId": 0,
    "orderStatusId": 0,
    "couponId": 0,
    "couponCode": "",
    "orderDate": "2024-08-22T18:30:00.000Z",
    "orderNumber": "17243932735523117",
    "orderTrackNumber": "4aa11162-352d-49d5-a1be-c5b587a23d61",
    "orderSubTotalAmount": "1412.00",
    "orderDiscountAmount": "0.00",
    "orderCouponAmount": "0.00",
    "orderGrossAmount": "1412.00",
    "orderShippingChargeAmount": "100.00",
    "orderNetAmount": "1512.00",
    "orderBillingName": "Bhushan Borse",
    "orderBillingEmail": "bhushan@gmail.com",
    "orderBillingMobile": "9988556633",
    "orderBillingAddressLine1": "105, Dattatray nagar, nilgiri",
    "orderBillingAddressLine2": "near bajrang dal temple",
    "orderBillingArea": "limbayat",
    "orderBillingCity": "surat",
    "orderBillingState": "gujarat",
    "orderBillingCountry": "india",
    "orderBillingPincode": "394210",
    "orderShippingName": "Dipak borse",
    "orderShippingEmail": "dipak@gmail.com",
    "orderShippingMobile": "7744552211",
    "orderShippingAddressLine1": "C-102, SKY-p LIVING, Godadara",
    "orderShippingAddressLine2": "NEW BRTS ROAD",
    "orderShippingArea": "limbayat",
    "orderShippingCity": "surat",
    "orderShippingState": "gujarat",
    "orderShippingCountry": "india",
    "orderShippingPincode": "394210",
    "orderPaymentMode": "COD",
    "orderPaymentModeName": "COD",
    "orderPaymentTransactionNumber": "COD1673738",
    "orderStatus": "pending",
    "createdAt": "2024-08-23T06:07:53.554Z",
    "updatedAt": "2024-08-23T06:07:53.554Z",
    "orderChildData": [
        {
            "orderChildId": 1,
            "id": 1,
            "orderId": 1,
            "productId": 1,
            "storeId": 31,
            "providerId": 2,
            "providerBusinessId": 2,
            "oldProviderId": 0,
            "oldProviderBusinessId": 0,
            "providerSwitchRemark": null,
            "productThumbnail": "null",
            "productName": "Unisex Heavy Blend™ Hooded Sweatshirt",
            "productMrp": "706.00",
            "productPrice": "706.00",
            "productDiscount": "0.00",
            "productQuantity": "2.00",
            "productSubTotal": "1412.00",
            "orderChildStatus": null,
            "createdAt": "2024-08-23T06:07:53.565Z",
            "updatedAt": "2024-08-23T06:07:53.565Z",
            "productShippingCharge": 0,
            "childOrderVariantData": [
                {
                    "variantId": 5,
                    "variantName": "S",
                    "variantPrice": "0.00",
                    "isColor": 0
                },
                {
                    "variantId": 11,
                    "variantName": "white",
                    "variantPrice": "0.00",
                    "isColor": 0
                }
            ]
        }
    ],
    "orderTrackingData": [
        {
            "id": 2,
            "trackingStatus": "order-create",
            "trackingRemark": "order created by bhushan borse(user) at Fri Aug 23 2024 11:37:53 GMT+0530 from sample-shop.printfuse.in",
            "updatedAt": "2024-08-23T06:07:53.576Z"
        }
    ]
}

const subscriptionProduct = [
    {
        thumbnail: require('../Assets/Images/1.jpg'),
        title: 'AOP Tote Bag',
        regularPrice: 699,
        pPrice: 559,
    },
    {
        thumbnail: require('../Assets/Images/2.jpg'),
        title: 'Women’s The Boyfriend Tee',
        regularPrice: 800,
        pPrice: 640,
    },
    {
        thumbnail: require('../Assets/Images/3.jpg'),
        title: 'Mugs',
        regularPrice: 620,
        pPrice: 496,
    },
    {
        thumbnail: require('../Assets/Images/4.jpg'),
        title: 'Shower Curtains',
        regularPrice: 2800,
        pPrice: 2240,
    },
]

const canvasSidebarData = [
    {
        title: 'Product',
        icon: 'bi-tags'
    },
    {
        title: 'layers',
        icon: 'bi-layers-half'
    },
    {
        title: 'Uploads',
        icon: 'bi-upload'
    },
    {
        title: 'Text',
        icon: 'bi-fonts'
    },
    {
        title: 'Clipart',
        icon: 'bi-emoji-smile'
    },
    {
        title: 'Quick Designs',
        icon: 'bi-magic'
    },
    {
        title: 'Premium',
        icon: 'bi-shield-shaded'
    },
    {
        title: 'Fill',
        icon: 'bi-paint-bucket'
    },
    {
        title: 'Help',
        icon: 'bi-question-square-fill'
    },
]

const productSingleData = {
    id: 1,
    name: 'Urban Groove: City Lights and Cool Vibes',
    skuCode: 'Gildan5000',
    thumbnail: require('../Assets/Images/654e2446c4fbc0eeb2052d24 (2).png'),
    images: [
        require('../Assets/Images/654e2446c4fbc0eeb2052d24 (2).png'),
        require('../Assets/Images/654e2447bdde291499078577 (1).png'),
        require('../Assets/Images/654b9e92bd3091aa8a08a5b9 (1).png'),
        require('../Assets/Images/654de688e68ada3dc707f26d (1).png'),
        require('../Assets/Images/257f60bfec8aee8d747a980f3c8a220c_l.png'),
        require('../Assets/Images/75cabe24ecebd907bff91df515cdf9e4_l.png'),
    ],
    providers: [
        {
            id: 1,
            name: 'Marco-Fine Arts',
            location: 'Madhya Pradesh',
            price: 5499,
            shipping: [
                {
                    label: 'economy',
                    value: 125,
                    time: '4-5 days'
                },
                {
                    label: 'standard',
                    value: 200,
                    time: '2-3 days'
                },
                {
                    label: 'express',
                    value: 400,
                    time: '1-2 days'
                }
            ],
            productionTime: 1.2,
            printArea: [
                'Back Side',
                'Front Side',
                'Neck label inner',
            ],
            variant: {
                color: [
                    { label: 'Red', code: '#FF0000', id: 1 },
                    { label: 'Green', code: '#008000', id: 2 },
                    { label: 'Blue', code: '#0000FF', id: 3 },
                    { label: 'Yellow', code: '#FFFF00', id: 4 },
                    { label: 'Orange', code: '#FFA500', id: 5 },
                    { label: 'Purple', code: '#800080', id: 6 },
                    { label: 'Pink', code: '#FFC0CB', id: 7 },
                    { label: 'Brown', code: '#A52A2A', id: 8 },
                    { label: 'Gray', code: '#808080', id: 9 },
                    { label: 'Black', code: '#000000', id: 10 },
                    { label: 'White', code: '#FFFFFF', id: 11 },
                    { label: 'Cyan', code: '#00FFFF', id: 12 },
                    { label: 'Magenta', code: '#FF00FF', id: 13 },
                    { label: 'Lime', code: '#00FF00', id: 14 },
                    { label: 'Maroon', code: '#800000', id: 15 },
                    { label: 'Navy', code: '#000080', id: 16 },
                    { label: 'Olive', code: '#808000', id: 17 },
                    { label: 'Teal', code: '#008080', id: 18 },
                    { label: 'Silver', code: '#C0C0C0', id: 19 },
                    { label: 'Gold', code: '#FFD700', id: 20 }
                ],
                sizes: [
                    { label: 'Small', code: 'S', id: 1 },
                    { label: 'Medium', code: 'M', id: 2 },
                    { label: 'Large', code: 'L', id: 3 },
                    { label: 'X-Large', code: 'X', id: 4 },
                    { label: 'XX-Large', code: 'XL', id: 5 }
                ]
            },
            rating: [
                {
                    label: 'quality',
                    value: 4.7
                },
                {
                    label: 'production',
                    value: 4.5
                },
                {
                    label: 'stock',
                    value: 5
                }
            ]
        }
    ],
    features: [
        {
            title: 'Shoulder tape',
            description: 'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching'
        },
        {
            title: 'Without side seams',
            description: 'Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive'
        },
        {
            title: 'Ribbed knit collar without seam',
            description: 'Ribbed knit makes the collar highly elastic and helps retain its shape'
        },
        {
            title: 'Fabric',
            description: 'Made from specially spun fibers that make a very strong and smooth fabric that is perfect for printing. The "Natural" color is made with unprocessed cotton, which results in small black flecks throughout the fabric'
        },
        {
            title: 'Fiber composition',
            description: 'Solid colors are 100% cotton; Heather colors and Tweed are 50% cotton, 50% polyester; Sport Grey and Antique colors are 90% cotton, 10% polyester;'
        },
    ],
    description: "The unisex heavy cotton tee is the basic staple of any wardrobe. It is the foundation upon which casual fashion grows. All it needs is a personalized design to elevate things to profitability. The specially spun fibers provide a smooth surface for premium printing vividity and sharpness. No side seams mean there are no itchy interruptions under the arms. The shoulders have tape for improved durability.",
    care: [
        {
            label: 'Machine wash',
            value: 'warm (max 40C or 105F)'
        },
        {
            label: 'Non-chlorine',
            value: 'bleach as needed'
        },
        {
            label: 'Tumble dry',
            value: 'medium'
        },
        {
            label: 'Do not iron'
        },
        {
            label: 'Do not dryclean'
        }
    ]
};

const sizeChart = [
    {
        label: 'S',
        value: [18.00, 28.00, 15.10, 1.50]
    },
    {
        label: 'M',
        value: [20.00, 28.00, 16.50, 1.50]
    },
    {
        label: 'L',
        value: [22.00, 28.00, 18.00, 1.50]
    },
    {
        label: 'XL',
        value: [24.00, 28.00, 19.50, 1.50]
    },
    {
        label: 'XXL',
        value: [26.00, 29.00, 21.00, 1.50]
    },
    {
        label: '3XL',
        value: [28.00, 30.00, 22.40, 1.50]
    },
    {
        label: '4XL',
        value: [30.00, 31.00, 23.70, 1.50]
    },
    {
        label: '5XL',
        value: [32.00, 32.00, 25.00, 1.50]
    },
]

const colorList = ['#FFFFFF', '#808080', '#000000', '#138808', '#000080', '#FF0000', '#FFD700'];

const faceEmojis = [
    {
        name: "😊",
        unicode: "U+1F60A",
        htmlEntity: "&#128522;"
    },
    {
        name: "😂",
        unicode: "U+1F602",
        htmlEntity: "&#128514;"
    },
    {
        name: "😍",
        unicode: "U+1F60D",
        htmlEntity: "&#128525;"
    },
    {
        name: "🤣",
        unicode: "U+1F923",
        htmlEntity: "&#129315;"
    },
    {
        name: "😃",
        unicode: "U+1F603",
        htmlEntity: "&#128515;"
    },
    {
        name: "😅",
        unicode: "U+1F605",
        htmlEntity: "&#128517;"
    },
    {
        name: "😎",
        unicode: "U+1F60E",
        htmlEntity: "&#128526;"
    },
    {
        name: "😐",
        unicode: "U+1F610",
        htmlEntity: "&#128528;"
    },
    {
        name: "😠",
        unicode: "U+1F620",
        htmlEntity: "&#128544;"
    },
    {
        name: "😢",
        unicode: "U+1F622",
        htmlEntity: "&#128546;"
    },
    {
        name: "😴",
        unicode: "U+1F634",
        htmlEntity: "&#128564;"
    },
    {
        name: "😜",
        unicode: "U+1F61C",
        htmlEntity: "&#128540;"
    },
    {
        name: "🤔",
        unicode: "U+1F914",
        htmlEntity: "&#129300;"
    },
    {
        name: "😶",
        unicode: "U+1F636",
        htmlEntity: "&#128566;"
    },
    {
        name: "🙃",
        unicode: "U+1F643",
        htmlEntity: "&#128579;"
    },
    {
        name: "😏",
        unicode: "U+1F60F",
        htmlEntity: "&#128527;"
    },
    {
        name: "😪",
        unicode: "U+1F62A",
        htmlEntity: "&#128554;"
    },
    {
        name: "😬",
        unicode: "U+1F62C",
        htmlEntity: "&#128556;"
    },
    {
        name: "🥳",
        unicode: "U+1F973",
        htmlEntity: "&#129395;"
    }
];

const productCreate = {
    "product": {
        "brandId": 1,
        "name": "Unisex Heavy Blend™ Hooded Sweatshirt",
        "skuCode": "SS4647",
        "thumbnail": "uploads\\images\\product\\thumbnail-1723437658641NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=9b6ad258-bf38-46b3-85b6-91e58ffda6bb.png",
        "thumbnailSlide": "uploads\\images\\product\\thumbnail-1723437658641NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=9b6ad258-bf38-46b3-85b6-91e58ffda6bb.png",
        "shortDescription": "Made with a medium-heavy fabric (8.0 oz/yd² (271 g/m²)) that consists of 50% cotton and 50% polyester for that cozy feel and warmth you need in a hoodie.\r\nThe classic fit along with the pouch pocket and the tear-away label make for a highly comfortable, scratch-free wearing experience.",
        "longDescription": null,
        "imagesData": [
            "uploads\\images\\product\\thumbnail-1723437658638NWQwNzM4NzdkMTU1YjQwMDEwMTlmODI4LnBuZw==fb24423d-13c0-498e-a65a-3c27da1885bf.png",
            "uploads\\images\\product\\thumbnail-1723437658641NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=9b6ad258-bf38-46b3-85b6-91e58ffda6bb.png",
            "uploads\\images\\product\\thumbnail-1723437658646NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4LnBuZw==fdb7dc69-1cfb-467a-8271-ccef31ef4d1d.png"
        ],
        "categoryIdsData": [
            8
        ],
        "providerData": [
            {
                "providerId": 2,
                "shippingData": [
                    {
                        "label": "economy",
                        "value": 50,
                        "time": "6-7 days"
                    },
                    {
                        "label": "standard",
                        "value": 80,
                        "time": "4-5 days"
                    },
                    {
                        "label": "express",
                        "value": 150,
                        "time": "1-2 days"
                    }
                ],
                "ratingData": [
                    {
                        "label": "quality",
                        "value": 4.7
                    },
                    {
                        "label": "production",
                        "value": 4.5
                    },
                    {
                        "label": "stock",
                        "value": 5
                    }
                ],
                "providerRating": 4.5,
                "productionTime": 2,
                "providerBusinessId": 2,
                "productPrice": 450,
                "providerName": "jaykanth prints",
                "providerCity": "surat",
                "providerState": "gujarat",
                "designSidesData": [
                    {
                        "value": "1-LEFT ARM",
                        "label": "LEFT ARM",
                        "designCharge": 20,
                        "ratioData": {
                            "type": "square",
                            "x": 217,
                            "y": 155,
                            "width": 100,
                            "height": 183,
                            "rotation": 0
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753596ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTJOalUwWlRJME5ESmlaR1JsTWpreE5EazVNRGM0TlRjMExuQnVadz09NzE2Nzk4MmYtNTYyMC00ZTViLWE1MTQtOGIyZjc1ODE0YmY4LnBuZw==bbca0be1-0373-4502-aeb1-edec73463c92.png"
                    },
                    {
                        "value": "1-RIGHT ARM",
                        "label": "RIGHT ARM",
                        "designCharge": 20,
                        "ratioData": {
                            "type": "square",
                            "x": "206",
                            "y": "215",
                            "width": "100",
                            "height": "100",
                            "rotation": "0"
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753603YmFjazQucG5n78bee9b1-dc38-40ec-a072-59c1318e39f9.png"
                    },
                    {
                        "value": "1-FRONT",
                        "label": "FRONT",
                        "designCharge": 20,
                        "ratioData": {
                            "type": "square",
                            "x": 155,
                            "y": 94,
                            "width": 177,
                            "height": 308,
                            "rotation": 0
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753614ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NDZOakl5T1dJeU1HUTNZalJqWVRjM09URTBOMk14TUdJeUxuQnVadz09MDE0Zjg0NjItNjVkYi00NzEyLWJiYmMtOWQ2YTlhZTFhNjliLnBuZw==b337ec05-a26b-449b-baba-cc28597679e4.png"
                    },
                    {
                        "value": "1-BACK",
                        "label": "BACK",
                        "designCharge": 20,
                        "ratioData": {
                            "type": "square",
                            "x": "158",
                            "y": "58",
                            "width": "179",
                            "height": "179",
                            "rotation": "0"
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753592ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTBOalUwWlRJMllqQmlaR1JsTWpreE5EazVNRGM0TlRkaExuQnVadz09MzlkYzE0NzMtYTA4Yi00YTQwLTgxODctYjgwZTBmMWRjNDY5LnBuZw==972bdafb-fda1-498c-88c5-048d8772b575.png"
                    }
                ],
                "availableVariantData": [
                    {
                        "name": "size",
                        "label": "size",
                        "value": 1,
                        "data": [
                            {
                                "value": 5,
                                "label": "S",
                                "code": "S",
                                "price": 0
                            },
                            {
                                "value": 6,
                                "label": "M",
                                "code": "M",
                                "price": 50
                            },
                            {
                                "value": 7,
                                "label": "L",
                                "code": "L",
                                "price": 90
                            },
                            {
                                "value": 8,
                                "label": "XL",
                                "code": "XL",
                                "price": 150
                            },
                            {
                                "value": 9,
                                "label": "XXL",
                                "code": "XXL",
                                "price": 200
                            }
                        ]
                    },
                    {
                        "name": "color",
                        "label": "color",
                        "value": 2,
                        "data": [
                            {
                                "value": 11,
                                "label": "white",
                                "code": "#ffffff",
                                "price": 0
                            },
                            {
                                "value": 15,
                                "label": "black",
                                "code": "#000000",
                                "price": 35
                            },
                            {
                                "value": 17,
                                "label": "orange",
                                "code": "#ff5900",
                                "price": 35
                            },
                            {
                                "value": 19,
                                "label": "light blue",
                                "code": "#9aa9d6",
                                "price": 35
                            }
                        ]
                    }
                ]
            },
            {
                "providerId": 1,
                "shippingData": [
                    {
                        "label": "economy",
                        "value": 50,
                        "time": "6-7 days"
                    },
                    {
                        "label": "standard",
                        "value": 80,
                        "time": "4-5 days"
                    },
                    {
                        "label": "express",
                        "value": 150,
                        "time": "1-2 days"
                    }
                ],
                "ratingData": [
                    {
                        "label": "quality",
                        "value": 4.7
                    },
                    {
                        "label": "production",
                        "value": 4.5
                    },
                    {
                        "label": "stock",
                        "value": 5
                    }
                ],
                "providerRating": 4.5,
                "productionTime": 2,
                "providerBusinessId": 1,
                "productPrice": 350,
                "providerName": "vikram fabrics",
                "providerCity": "surat",
                "providerState": "gujarat",
                "designSidesData": [
                    {
                        "value": "1-LEFT ARM",
                        "label": "LEFT ARM",
                        "designCharge": 25,
                        "ratioData": {
                            "type": "square",
                            "x": 217,
                            "y": 155,
                            "width": 100,
                            "height": 183,
                            "rotation": 0
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753596ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTJOalUwWlRJME5ESmlaR1JsTWpreE5EazVNRGM0TlRjMExuQnVadz09NzE2Nzk4MmYtNTYyMC00ZTViLWE1MTQtOGIyZjc1ODE0YmY4LnBuZw==bbca0be1-0373-4502-aeb1-edec73463c92.png"
                    },
                    {
                        "value": "1-RIGHT ARM",
                        "label": "RIGHT ARM",
                        "designCharge": 25,
                        "ratioData": {
                            "type": "square",
                            "x": "206",
                            "y": "215",
                            "width": "100",
                            "height": "100",
                            "rotation": "0"
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753603YmFjazQucG5n78bee9b1-dc38-40ec-a072-59c1318e39f9.png"
                    },
                    {
                        "value": "1-FRONT",
                        "label": "FRONT",
                        "designCharge": 25,
                        "ratioData": {
                            "type": "square",
                            "x": 155,
                            "y": 94,
                            "width": 177,
                            "height": 308,
                            "rotation": 0
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753614ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NDZOakl5T1dJeU1HUTNZalJqWVRjM09URTBOMk14TUdJeUxuQnVadz09MDE0Zjg0NjItNjVkYi00NzEyLWJiYmMtOWQ2YTlhZTFhNjliLnBuZw==b337ec05-a26b-449b-baba-cc28597679e4.png"
                    },
                    {
                        "value": "1-BACK",
                        "label": "BACK",
                        "designCharge": 25,
                        "ratioData": {
                            "type": "square",
                            "x": "158",
                            "y": "58",
                            "width": "179",
                            "height": "179",
                            "rotation": "0"
                        },
                        "thumbnail": "uploads\\images\\product\\designImages-1723286753592ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTBOalUwWlRJMllqQmlaR1JsTWpreE5EazVNRGM0TlRkaExuQnVadz09MzlkYzE0NzMtYTA4Yi00YTQwLTgxODctYjgwZTBmMWRjNDY5LnBuZw==972bdafb-fda1-498c-88c5-048d8772b575.png"
                    }
                ],
                "availableVariantData": [
                    {
                        "name": "size",
                        "label": "size",
                        "value": 1,
                        "data": [
                            {
                                "value": 5,
                                "label": "S",
                                "code": "S",
                                "price": 0
                            },
                            {
                                "value": 6,
                                "label": "M",
                                "code": "M",
                                "price": 50
                            },
                            {
                                "value": 7,
                                "label": "L",
                                "code": "L",
                                "price": 100
                            },
                            {
                                "value": 8,
                                "label": "XL",
                                "code": "XL",
                                "price": 120
                            },
                            {
                                "value": 9,
                                "label": "XXL",
                                "code": "XXL",
                                "price": 150
                            },
                            {
                                "value": 10,
                                "label": "3XL",
                                "code": "3XL",
                                "price": 200
                            }
                        ]
                    },
                    {
                        "name": "color",
                        "label": "color",
                        "value": 2,
                        "data": [
                            {
                                "value": 11,
                                "label": "white",
                                "code": "#ffffff",
                                "price": 0
                            },
                            {
                                "value": 12,
                                "label": "green",
                                "code": "#00ff91",
                                "price": 25
                            },
                            {
                                "value": 13,
                                "label": "blue",
                                "code": "#3700ff",
                                "price": 25
                            },
                            {
                                "value": 14,
                                "label": "yellow",
                                "code": "#e1ff00",
                                "price": 25
                            }
                        ]
                    }
                ]
            }
        ],
        "relatedProductData": [
            {
                "id": 1,
                "name": "Unisex Heavy Blend™ Hooded Sweatshirt",
                "thumbnail": "uploads\\images\\product\\thumbnail-1723437658641NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=9b6ad258-bf38-46b3-85b6-91e58ffda6bb.png",
                "thumbnailSlide": "uploads\\images\\product\\thumbnail-1723437658641NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=9b6ad258-bf38-46b3-85b6-91e58ffda6bb.png",
                "brandId": 1,
                "brandName": "printfuse",
                "productFromPrice": "350",
                "variantData": [
                    {
                        "name": "size",
                        "data": [
                            5,
                            6,
                            7,
                            8,
                            9,
                            10
                        ],
                        "total": 6
                    },
                    {
                        "name": "color",
                        "data": [
                            11,
                            12,
                            13,
                            14
                        ],
                        "total": 4
                    }
                ]
            },
            {
                "id": 3,
                "name": "Unisex Heavy Blend™ Hooded Sweatshirt Update",
                "thumbnail": "uploads\\images\\product\\thumbnail-1723288046653NjUxMTNkMDZmNjI5OTk2MTIwMDExY2EzLnBuZw==d8a78d77-2dbb-449a-9697-7124416314a4.png",
                "thumbnailSlide": "uploads\\images\\product\\thumbnail-1723288046654NWQwNzNhOTVkMTU1YjQwMDExMTI2Yzk4ICgxKS5wbmc=a82cae1d-0e73-435a-941c-cf98476c3ca8.png",
                "brandId": 1,
                "brandName": "printfuse",
                "productFromPrice": "280",
                "variantData": [
                    {
                        "name": "size",
                        "data": [
                            5,
                            6,
                            7,
                            8,
                            9
                        ],
                        "total": 5
                    },
                    {
                        "name": "color",
                        "data": [
                            11,
                            12,
                            13,
                            14,
                            15,
                            17,
                            18,
                            19
                        ],
                        "total": 8
                    }
                ]
            },
            {
                "id": 7,
                "name": "Unisex Heavy Blend™ Hooded Sweatshirt Soft",
                "thumbnail": "uploads\\images\\product\\thumbnail-1723288116798NjVkYTRlODU2NzU4M2EyYzYyMDcyMjIzLnBuZw==6291bed0-7bcf-4bad-85d8-63ba7e191552.png",
                "thumbnailSlide": "uploads\\images\\product\\thumbnail-1723288116800NjM1ZmE3MDViOTM1NjdhZmYxMDgyMTEzLnBuZw==1e4cc37f-aa81-42e0-9ef3-10fa20ebf854.png",
                "brandId": 1,
                "brandName": "printfuse",
                "productFromPrice": "300",
                "variantData": [
                    {
                        "name": "size",
                        "data": [
                            5,
                            6,
                            7
                        ],
                        "total": 3
                    },
                    {
                        "name": "color",
                        "data": [
                            11,
                            12,
                            13,
                            14,
                            15,
                            17
                        ],
                        "total": 6
                    }
                ]
            }
        ]
    },
    "provider": {
        "providerId": 1,
        "shippingData": [
            {
                "label": "economy",
                "value": 50,
                "time": "6-7 days"
            },
            {
                "label": "standard",
                "value": 80,
                "time": "4-5 days"
            },
            {
                "label": "express",
                "value": 150,
                "time": "1-2 days"
            }
        ],
        "ratingData": [
            {
                "label": "quality",
                "value": 4.7
            },
            {
                "label": "production",
                "value": 4.5
            },
            {
                "label": "stock",
                "value": 5
            }
        ],
        "providerRating": 4.5,
        "productionTime": 2,
        "providerBusinessId": 1,
        "productPrice": 350,
        "providerName": "vikram fabrics",
        "providerCity": "surat",
        "providerState": "gujarat",
        "designSidesData": [
            {
                "value": "1-LEFT ARM",
                "label": "LEFT ARM",
                "designCharge": 25,
                "ratioData": {
                    "type": "square",
                    "x": 217,
                    "y": 155,
                    "width": 100,
                    "height": 183,
                    "rotation": 0
                },
                "thumbnail": "uploads\\images\\product\\designImages-1723286753596ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTJOalUwWlRJME5ESmlaR1JsTWpreE5EazVNRGM0TlRjMExuQnVadz09NzE2Nzk4MmYtNTYyMC00ZTViLWE1MTQtOGIyZjc1ODE0YmY4LnBuZw==bbca0be1-0373-4502-aeb1-edec73463c92.png"
            },
            {
                "value": "1-RIGHT ARM",
                "label": "RIGHT ARM",
                "designCharge": 25,
                "ratioData": {
                    "type": "square",
                    "x": "206",
                    "y": "215",
                    "width": "100",
                    "height": "100",
                    "rotation": "0"
                },
                "thumbnail": "uploads\\images\\product\\designImages-1723286753603YmFjazQucG5n78bee9b1-dc38-40ec-a072-59c1318e39f9.png"
            },
            {
                "value": "1-FRONT",
                "label": "FRONT",
                "designCharge": 25,
                "ratioData": {
                    "type": "square",
                    "x": 155,
                    "y": 94,
                    "width": 177,
                    "height": 308,
                    "rotation": 0
                },
                "thumbnail": "uploads\\images\\product\\designImages-1723286753614ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NDZOakl5T1dJeU1HUTNZalJqWVRjM09URTBOMk14TUdJeUxuQnVadz09MDE0Zjg0NjItNjVkYi00NzEyLWJiYmMtOWQ2YTlhZTFhNjliLnBuZw==b337ec05-a26b-449b-baba-cc28597679e4.png"
            },
            {
                "value": "1-BACK",
                "label": "BACK",
                "designCharge": 25,
                "ratioData": {
                    "type": "square",
                    "x": "158",
                    "y": "58",
                    "width": "179",
                    "height": "179",
                    "rotation": "0"
                },
                "thumbnail": "uploads\\images\\product\\designImages-1723286753592ZGVzaWduSW1hZ2VzLTE3MjMwMjgxNTc5NTBOalUwWlRJMllqQmlaR1JsTWpreE5EazVNRGM0TlRkaExuQnVadz09MzlkYzE0NzMtYTA4Yi00YTQwLTgxODctYjgwZTBmMWRjNDY5LnBuZw==972bdafb-fda1-498c-88c5-048d8772b575.png"
            }
        ],
        "availableVariantData": [
            {
                "name": "size",
                "label": "size",
                "value": 1,
                "data": [
                    {
                        "value": 5,
                        "label": "S",
                        "code": "S",
                        "price": 0
                    },
                    {
                        "value": 6,
                        "label": "M",
                        "code": "M",
                        "price": 50
                    },
                    {
                        "value": 7,
                        "label": "L",
                        "code": "L",
                        "price": 100
                    },
                    {
                        "value": 8,
                        "label": "XL",
                        "code": "XL",
                        "price": 120
                    },
                    {
                        "value": 9,
                        "label": "XXL",
                        "code": "XXL",
                        "price": 150
                    },
                    {
                        "value": 10,
                        "label": "3XL",
                        "code": "3XL",
                        "price": 200
                    }
                ]
            },
            {
                "name": "color",
                "label": "color",
                "value": 2,
                "data": [
                    {
                        "value": 11,
                        "label": "white",
                        "code": "#ffffff",
                        "price": 0
                    },
                    {
                        "value": 12,
                        "label": "green",
                        "code": "#00ff91",
                        "price": 25
                    },
                    {
                        "value": 13,
                        "label": "blue",
                        "code": "#3700ff",
                        "price": 25
                    },
                    {
                        "value": 14,
                        "label": "yellow",
                        "code": "#e1ff00",
                        "price": 25
                    }
                ]
            }
        ]
    },
    "variation": {
        "size": [
            {
                "value": 5,
                "label": "S",
                "code": "S",
                "price": 0
            },
            {
                "value": 6,
                "label": "M",
                "code": "M",
                "price": 50
            },
            {
                "value": 7,
                "label": "L",
                "code": "L",
                "price": 100
            },
            {
                "value": 8,
                "label": "XL",
                "code": "XL",
                "price": 120
            }
        ],
        "color": [
            {
                "value": 11,
                "label": "white",
                "code": "#ffffff",
                "price": 0
            },
            {
                "value": 12,
                "label": "green",
                "code": "#00ff91",
                "price": 25
            }
        ]
    },
    "priceList": [
        {
            "label": "product price",
            "value": 350
        }
    ]
};

const withdrawFilterListLocal = {
    title: "withDrawStatus",
    data: [
        {
            label: "REQUEST",
            value: "REQUEST"
        },
        {
            label: "PENDING",
            value: "PENDING"
        },
        {
            label: "ONHOLD",
            value: "ONHOLD"
        },
        {
            label: "ACCEPT",
            value: "ACCEPT"
        },
        {
            label: "COMPLETE",
            value: "COMPLETE"
        },
        {
            label: "CANCEL",
            value: "CANCEL"
        }
    ]
}

export {
    ProductSliderList,
    ProductSliderListTwo,
    sellerSideBarData,
    identityData,
    sellerSocialLinks,
    customFilter,
    orderList,
    subscriptionProduct,
    canvasSidebarData,
    productSingleData,
    sizeChart,
    colorList,
    faceEmojis,
    productCreate,
    tempSingleOrder,
    withdrawFilterListLocal
};