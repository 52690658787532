import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Dropdown, Modal, Placeholder, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { frontEnd_API, header, SERVER_URL } from '../../../Config/Config'
import { RiImageEditLine } from "react-icons/ri";
import { MdOutlineEditNote, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import CommonPagination from '../../../Components/CommonPagination'
import Loader from '../../../Components/Loader'

function ProductHome() {

    const [productExist, setProductExist] = useState(false)
    const [productData, setProductData] = useState([])
    const [statusLoader, setStatusLoader] = useState(false);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [multiSelect, setMultiSelect] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [filters, setFilters] = useState({});
    const [totalCount, setTotalCount] = useState()
    const navigate = useNavigate();
    const activeStore = useSelector((state) => state.store.value);
    const [loader, setLoader] = useState(true);

    const getProductData = async () => {
        try {
            const response = await axios?.put(frontEnd_API?.storeProduct, { ...filters }, header);
            if (response?.status === 200) {
                setProductData([...response?.data?.data]);
                setTotalCount(response?.data?.totalCount);
                setLoader(false);
                if (response?.data?.data?.length > 0) {
                    setProductExist(true)
                }
            }
        } catch (error) {
            console.log("error::", error);
            setProductExist(false)
            setLoader(false);
        }
    }

    const getProviderData = async () => {
        try {
            const response = await axios?.get(`${frontEnd_API?.storeProduct}/provider`, header);
            if (response?.status === 200) {
                setFilterList((prevData) => ({
                    ...prevData,
                    parentData: [
                        {
                            label: 'Provider Name',
                            title: 'providerId',
                            data: response?.data?.data
                        },
                        {
                            label: 'Status',
                            title: 'status',
                            data: [
                                {
                                    label: "Published",
                                    value:"1"
                                },
                                {
                                    label: "Unpublished",
                                    value:"0"
                                },
                            ]
                        },
                    ]

                }));
                console.log("getProviderData::", response?.data);
            }
        } catch (error) {
            console.log("error::", error);
        }
    }

    useEffect(() => {
        getProductData();
        getProviderData();
    }, [])

    useEffect(() => {
        getProductData();
        getProviderData();
    }, [filters])

    const deleteProduct = async (id) => {
        try {
            const tempData = {
                'id': (multiSelect?.length > 0) ? multiSelect : [id]
            };

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await axios.delete(`${frontEnd_API?.storeProduct}`, { data: tempData, ...header })
                    getProductData();
                    Swal.fire('Deleted!', 'Your product has been deleted.', 'success');
                    setLoader(false);
                }
            });
        }
        catch (e) {
            console.log("err::", e);
            setLoader(false);
        }
    }

    const handleProductStatus = async (item) => {

        let tempId = [];
        if (item?.id) {
            tempId = [item?.id]
        }
        else if (multiSelect?.length > 0) {
            tempId = multiSelect
        }

        try {
            const { data } = await axios.put(`${frontEnd_API?.storeProduct}/status`, {
                id: tempId,
                status: (multiSelect?.length > 0) ? item?.status : (item?.status == 1) ? 0 : 1
            }, header)
            console.log("data::", data);
            getProductData();
            setTimeout(() => {
                setStatusLoader(false);
                if (multiSelect?.length > 0) {
                    toast(`${(multiSelect?.length == productData?.length) ? 'All ' : 'Selected '}Product status changed to ${(item?.status === 1) ? "Published" : "Unpublished"}`)
                }
                else {
                    toast(`Product status changed to ${(item?.status === 1) ? "Unpublished" : "Published"}`)
                }
            }, 500);

        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    const handleCanvasEdit = async (item) => {
        try {
            const { data } = await axios.get(`${frontEnd_API?.storeProduct}/${item?.id}`, header)
            console.log("data::", data);
            if (data?.data) {
                setTimeout(() => {
                    if (item?.to == "canvas") {
                        navigate(`/product/${data?.data?.masterProductId}/${data?.data?.providerId}/canvas`, {
                            state: {
                                data: {
                                    'varientData': data?.data?.variantData,
                                    'editCanvas': data?.data?.designData,
                                    'updateProduct': data?.data
                                }
                            }
                        });
                    }
                    else {
                        navigate(`/product/${data?.data?.masterProductId}/${data?.data?.providerId}/canvas`, {
                            state: {
                                data: {
                                    'varientData': data?.data?.variantData,
                                    'editCanvas': data?.data?.designData,
                                    'updateProduct': data?.data
                                }
                            }
                        });
                    }
                    setCanvasLoader(false)
                }, 750)
            }
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    const handleCheck = (item) => {
        const check = multiSelect?.length > 0
            ? multiSelect.find((e) => e == item?.value)
            : null
        if (check) {
            const tempData = multiSelect.filter((e) => e !== item?.value);
            setMultiSelect(tempData);
        }
        else {
            const tempData = [...multiSelect, item?.value];
            setMultiSelect(tempData)
        }
    }

    useEffect(() => {
        console.log("filters::", filters);
    }, [filters])

    // Filtration Data List

    const [showFilter, setShowFilter] = useState(false);

    const handleChange = (item) => {
        const tempData = filters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        console.log("tempData::", newData);
        if (newData) {
            const check = filters[item?.title].filter((e) => e !== item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: [...tempData, item?.value]
            }))
        }

    }

    return (
        <div className='seller-product-home seller-bg-grey' style={{ minHeight: "100vh" }}>
            <div className='pt-5 px-2 px-lg-3 d-grid'>
                <Container>
                    <Row>
                        <Col className='col-12 p-0'>
                            <Card className='rounded-0 p-0'>
                                <CardHeader className='bg-transparent py-3'>
                                    <h5 className='mb-0 fw-bolder'>My Products</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row className='gy-3 px-2 px-sm-0 justify-content-center'>
                                        <Col className='col-12 col-lg-12 py-2 d-grid gap-3 custom-btn-scrollbar px-0 px-lg-3'>
                                            {/* Search Button */}
                                            <div className='d-flex align-items-center gap-2 d-md-grid'>
                                                <Container className='custom-seller-category-search px-0'>
                                                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                                                        <label className='bi bi-search' htmlFor='text'></label>
                                                        <input
                                                            type="text"
                                                            className='py-2 fs-sm-14 pe-2'
                                                            id='text'
                                                            placeholder='Search by product name or description'
                                                            autoComplete='off'
                                                            onChange={(e) => {
                                                                setFilters((prevData) => ({
                                                                    ...prevData,
                                                                    search: e?.target?.value,
                                                                }))
                                                                setLoader(true);
                                                            }}
                                                        />
                                                    </div>
                                                </Container>
                                                <div className='d-block d-md-none'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setShowFilter(!showFilter)}
                                                ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                                                {/* Filters */}
                                                <Modal
                                                    show={showFilter}
                                                    onHide={() => setShowFilter(!showFilter)}
                                                    backdrop="static"
                                                    keyboard={false}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Filter Invoices</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className='d-grid gap-3'>
                                                            {
                                                                (filterList?.parentData?.length > 0) &&
                                                                filterList?.parentData?.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                            <Dropdown className='d-inline w-100' autoClose="outside">
                                                                                <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                                    {item.label}
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                                    {
                                                                                        item?.data.map((subItem, subIndex) => {
                                                                                            return (
                                                                                                <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                                    <input type="checkbox"
                                                                                                        className='ui-checkbox'
                                                                                                        name={subItem.label}
                                                                                                        id={subItem.label}
                                                                                                        value={subItem.value}
                                                                                                        checked={filters[item?.title]?.includes(subItem.value)}
                                                                                                        onChange={() => {
                                                                                                            handleChange({
                                                                                                                value: subItem?.value,
                                                                                                                title: item?.title
                                                                                                            })
                                                                                                            setLoader(true);
                                                                                                        }} />

                                                                                                    <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                                </Dropdown.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => setShowFilter(false)}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                            <div className='d-none d-md-grid gap-2'>
                                                <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                                                <div className='d-none d-md-flex'>
                                                    {
                                                        (filterList?.parentData?.length > 0) &&
                                                        filterList?.parentData?.map((item, index) => {
                                                            return (
                                                                <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                                                        <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                                            {item.label}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                                                            {
                                                                                item?.data.map((subItem, subIndex) => {
                                                                                    return (
                                                                                        <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                                            <input type="checkbox"
                                                                                                className='ui-checkbox'
                                                                                                name={subItem.label}
                                                                                                id={subItem.label}
                                                                                                value={subItem.value}
                                                                                                checked={filters[item?.title]?.includes(subItem.value)}
                                                                                                onChange={() => {
                                                                                                    handleChange({
                                                                                                        value: subItem?.value,
                                                                                                        title: item?.title
                                                                                                    })
                                                                                                    setLoader(true);
                                                                                                }} />

                                                                                            <label htmlFor={subItem.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem.label}</label>
                                                                                        </Dropdown.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-12 col-lg-12'>
                                            <Row className='custom-table-responsive text-capitalize' style={{ minHeight: '35px' }}>
                                                <Col className={`col-12 ${(multiSelect?.length > 0) ? '' : 'col-lg-8'} d-grid px-0 px-lg-2`}>
                                                    <div className='d-flex justify-content-start justify-content-lg-between align-items-lg-center gap-2'>
                                                        <div className='check-holder ps-lg-2 flex-center-align'>
                                                            <input
                                                                type="checkbox"
                                                                className="ui-checkbox"
                                                                id='checkAll'
                                                                checked={productData?.length == multiSelect?.length ? true : false}
                                                                onChange={(check) => setMultiSelect(() => {
                                                                    const tempData = productData?.map((e) => e?.id)
                                                                    return (check?.target?.checked) ? tempData : []
                                                                })}
                                                            />
                                                        </div>
                                                        <div className={`img-holder d-flex align-items-center gap-2 ${(multiSelect?.length > 0) ? "" : "w-100"}`} style={{ aspectRatio: "auto" }}>
                                                            <label htmlFor='checkAll' className='pointer'>
                                                                {
                                                                    (multiSelect?.length > 0) ?
                                                                        <span className='fw-semibold fs-18 fs-sm-14'>{multiSelect?.length}/{productData?.length}</span>
                                                                        : 'Select all'
                                                                }
                                                            </label>
                                                        </div>
                                                        <Row className='w-100 gy-1 gy-lg-0 align-items-lg-center p-2 p-lg-0 d-flex flex-wrap'>
                                                            {(multiSelect?.length > 0) ?
                                                                <Col className='col-12 d-flex flex-wrap gap-2'>
                                                                    <Button
                                                                        className='py-1 fs-sm-12'
                                                                        variant="success"
                                                                        onClick={() => {
                                                                            setStatusLoader(true)
                                                                            handleProductStatus({
                                                                                status: 1
                                                                            })
                                                                            setMultiSelect([])
                                                                        }}
                                                                    >
                                                                        Publish
                                                                    </Button>
                                                                    <Button
                                                                        className='py-1 fs-sm-12'
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            setStatusLoader(true)
                                                                            handleProductStatus({
                                                                                status: 0
                                                                            })
                                                                            setMultiSelect([])
                                                                        }}
                                                                    >
                                                                        Unpublish
                                                                    </Button>
                                                                    <Button
                                                                        className='py-1 fs-sm-12'
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                            deleteProduct()
                                                                            setLoader(true)
                                                                            setMultiSelect([])
                                                                        }}
                                                                    >
                                                                        <span className='d-none d-lg-block'>Delete</span>
                                                                        <i className="bi bi-trash d-block d-lg-none"></i>
                                                                    </Button>
                                                                </Col>
                                                                : <>
                                                                    <Col className='col-12 col-lg-8 d-none d-lg-grid align-content-center gap-2'>
                                                                        Product
                                                                    </Col>
                                                                    <Col className='col-12 col-lg-4 d-none d-lg-block'>Inventory</Col>
                                                                </>
                                                            }
                                                        </Row>
                                                    </div>
                                                </Col>
                                                {
                                                    (multiSelect?.length == 0) &&
                                                    <Col className='col-12 col-lg-4 d-none d-lg-grid align-items-center'>
                                                        Status
                                                    </Col>
                                                }

                                            </Row>
                                            <hr className={`mb-0 d-none d-lg-block`} />
                                        </Col>
                                        {
                                            loader ?
                                                <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                    <Loader />
                                                </div>
                                                : productExist ?
                                                    <>
                                                        <Col className='col-12'>
                                                            <Row className='position-relative'>
                                                                {
                                                                    productData?.map((item, index) => {
                                                                        const tempId = multiSelect?.find((e) => e === item?.id)
                                                                        return (
                                                                            <>
                                                                                <Col key={index} className='col-12 col-sm-6 col-lg-12'>
                                                                                    <Row className='custom-table-responsive text-capitalize custom-product-item'>
                                                                                        <Col className='col-12 col-lg-8 d-grid px-0 px-lg-2'>
                                                                                            <div className='d-grid d-lg-flex justify-content-lg-between align-items-lg-center gap-2'>
                                                                                                <div className='product-top-corner-check check-holder flex-center-align ps-lg-2 pe-lg-1'>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="ui-checkbox"
                                                                                                        checked={(tempId) ? true : false}
                                                                                                        onChange={(e) => handleCheck({
                                                                                                            value: item?.id
                                                                                                        })}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className='img-holder'>
                                                                                                    <img src={SERVER_URL + item?.thumbnail} alt="" />
                                                                                                </div>
                                                                                                <Row className='w-100 gy-1 gy-lg-0 align-items-lg-center p-2 p-lg-0'>
                                                                                                    <Col className='col-12 col-lg-8 d-grid align-content-center gap-2'>
                                                                                                        <h6 className='fw-semibold m-0'>{item?.name}</h6>
                                                                                                        <span className='p-color fs-14 fs-sm-12'>{item?.businessName}</span>
                                                                                                        <div className='d-flex gap-1 flex-wrap align-items-center fs-14 fs-sm-12 p-color'>
                                                                                                            {
                                                                                                                item?.variantData.map((subItem, subIndex) => (
                                                                                                                    <>
                                                                                                                        <span key={subIndex} className='text-lowercase'>
                                                                                                                            <span className='pe-1'>
                                                                                                                                {subItem?.value}
                                                                                                                            </span>
                                                                                                                            {subItem?.label}
                                                                                                                        </span>
                                                                                                                        <GoDotFill className={`fs-12 fs-sm-10`} />
                                                                                                                    </>
                                                                                                                ))
                                                                                                            }
                                                                                                            <span>
                                                                                                                Total
                                                                                                                <span className='px-1'>
                                                                                                                    {item?.cartesianCount}
                                                                                                                </span>
                                                                                                                variants
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col className='col-12 col-lg-4 fs-14 fs-sm-12'>All in stock</Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <hr className='mb-0 d-lg-none' />
                                                                                        <Col className='col-12 col-lg-4 d-grid py-2 py-lg-0'>
                                                                                            <div className='flex-between-align'>
                                                                                                {
                                                                                                    (statusLoader) ?
                                                                                                        <Placeholder as={"Alert"}
                                                                                                            aria-hidden="true"
                                                                                                            animation="glow"
                                                                                                            className="w-100"
                                                                                                        >
                                                                                                            <Placeholder xs={8} />
                                                                                                        </Placeholder>
                                                                                                        : <Alert variant={(item?.status == 0) ? "secondary" : "success"} className='product-status-label py-1 px-2 fs-14 fs-sm-12 m-0'>
                                                                                                            {(item?.status == 0) ? "Unpublished" : "Published"}
                                                                                                        </Alert>
                                                                                                }

                                                                                                <div className='d-flex justify-content-end gap-4 gap-lg-2 custom-icon-list'>
                                                                                                    <span
                                                                                                        data-tooltip-id="my-tooltip"
                                                                                                        data-tooltip-content="Edit Design"
                                                                                                        className='fs-25'
                                                                                                        onClick={() => {
                                                                                                            handleCanvasEdit({
                                                                                                                id: item?.id,
                                                                                                                to: "canvas"
                                                                                                            });
                                                                                                            setCanvasLoader(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <RiImageEditLine />
                                                                                                    </span>
                                                                                                    <Tooltip id="my-tooltip" place="top" />
                                                                                                    <span
                                                                                                        data-tooltip-id="my-tooltip"
                                                                                                        data-tooltip-content="Update Details"
                                                                                                        className='fs-25'
                                                                                                        onClick={() => {
                                                                                                            handleCanvasEdit({
                                                                                                                id: item?.id,
                                                                                                                to: "details"
                                                                                                            });
                                                                                                            setCanvasLoader(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <MdOutlineEditNote />
                                                                                                    </span>
                                                                                                    <Tooltip id="my-tooltip" place="top" />
                                                                                                    <Dropdown className="p-0">
                                                                                                        <Dropdown.Toggle id="dropdown-basic" className="p-0">
                                                                                                            <span className='fs-20'>
                                                                                                                <BsThreeDots />
                                                                                                            </span>
                                                                                                        </Dropdown.Toggle>

                                                                                                        <Dropdown.Menu>

                                                                                                            {
                                                                                                                (statusLoader) ?
                                                                                                                    <Placeholder as={Dropdown.Item}
                                                                                                                        aria-hidden="true"
                                                                                                                        animation="glow"
                                                                                                                        className="w-100"
                                                                                                                    >
                                                                                                                        <Placeholder xs={12} className="w-100" />
                                                                                                                    </Placeholder>
                                                                                                                    : <Dropdown.Item
                                                                                                                        onClick={() => {
                                                                                                                            handleProductStatus({
                                                                                                                                id: item?.id,
                                                                                                                                status: item?.status
                                                                                                                            })
                                                                                                                            setStatusLoader(true)
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {(item?.status == 1) ? "Unpublish" : "Publish"}
                                                                                                                    </Dropdown.Item>
                                                                                                            }
                                                                                                            {
                                                                                                                (item?.status == 1) &&
                                                                                                                <Dropdown.Item href={`${(window.location.hostname === "localhost")
                                                                                                                    ? `http://localhost:3001`
                                                                                                                    : `https://${activeStore?.domain}`}/product/${item?.id}`} target='_blanck'>
                                                                                                                    See in store
                                                                                                                </Dropdown.Item>
                                                                                                            }
                                                                                                            <Dropdown.Item className='text-danger' onClick={() => {
                                                                                                                deleteProduct(item?.id)
                                                                                                                setLoader(true);
                                                                                                            }}>Delete</Dropdown.Item>
                                                                                                        </Dropdown.Menu>
                                                                                                    </Dropdown>

                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <hr className={`d-none d-lg-block ${(productData?.length === index + 1) ? 'd-lg-none' : ''}`} />
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    (canvasLoader) &&
                                                                    <div className='position-absolute h-100 w-100 flex-center-align half-border-rad' style={{ backgroundColor: "rgba(0,0,0,0.15" }}>
                                                                        <Spinner animation="border" variant="success" />
                                                                    </div>
                                                                }
                                                            </Row>
                                                        </Col>
                                                        <Col className='col-12 pt-2 pt-sm-3'>
                                                            <CommonPagination
                                                                totalCount={totalCount}
                                                                changePage={(data) => {
                                                                    setFilters((prevData) => ({
                                                                        ...prevData,
                                                                        limit: data?.limit,
                                                                        page: data?.page
                                                                    }))
                                                                }}
                                                            />
                                                        </Col>
                                                    </>
                                                    :
                                                    <div className='py-2 flex-center-align py-5'>
                                                        <div className='custom-transaction-tab d-grid justify-content-center gap-2 text-center'>
                                                            <div>
                                                                <MdOutlineProductionQuantityLimits className='display-4 color-temp' />
                                                            </div>
                                                            <h5 className='fw-semibold m-0'>No Products</h5>
                                                        </div>
                                                    </div>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </div >
    )
}

export default ProductHome
