import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { frontEnd_API, header, SERVER_URL, siteConfig } from '../../Config/Config';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Redirection from '../../Components/Redirection/Redirection';
import SearchBar from '../../Components/SearchBar';
import CommonPagination from '../../Components/CommonPagination';

function SellerProductListingPage() {
    const [category, setCategory] = useState({});
    const [subCategory, setSubCategory] = useState({});
    const location = useLocation();
    const params = useParams();
    const [show, setShow] = useState(false);
    const [filterProducts, setFilterProducts] = useState();
    const [filterList, setFilterList] = useState([]);
    const [filterValue, setFilterValue] = useState({});
    const [totalCount, setTotalCount] = useState();

    useEffect(() => {
        if (params.category) {
            axios.get(frontEnd_API.getcategory, header)
                .then((res) => {
                    let hyphenatedString = params.category;
                    let tempProduct = params.product;
                    let originalString = hyphenatedString.replace(/-/g, ' ').replace(/~/g, '-');
                    let originalProduct = tempProduct.replace(/-/g, ' ').replace(/~/g, '-');
                    const cdata = res?.data?.data;
                    const found = cdata.find((item) => item.label === originalString);
                    const foundProduct = found.children.find((item) => item.label === originalProduct);
                    setCategory(found);
                    setSubCategory(foundProduct);
                    setFilterValue((prevData) => ({
                        ...prevData,
                        "categoryId": [
                            found?.value,
                            foundProduct?.value,
                        ]
                    }))
                })
                .catch((e) => {
                    console.log("e::", e);
                });
        }
    }, [location, params?.category, params?.product]);

    useEffect(() => {
        axios.get(frontEnd_API.filters, header)
            .then((res) => {
                console.log("res::", res?.data?.data);
                setFilterList(res?.data?.data);
            })
            .catch((e) => {
                console.log("er::", e);
            })
    }, [])

    const getProductList = async () => {
        console.log({
            ...filterValue,
            search: params?.search
        });
        try {
            const { data } = await axios.put(frontEnd_API.filterProductList, {
                ...filterValue,
                search: params?.search
            }, header)
            setFilterProducts(data?.data);
            setTotalCount(data?.totalCount);
            console.log("data?.data::", data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getProductList();
    }, [category, subCategory, filterValue, params?.category, params?.product, params?.search])

    const handleFilterChange = (item = {}) => {
        console.log(item);
        const { key, value, checked } = item;
        setFilterValue((prevData) => ({
            ...prevData,
            [key]: (checked)
                ? [...(prevData[key] || []), value]
                : (prevData[key] || []).filter((v) => v !== value)
        }))
    }

    useEffect(() => {
        console.log("filterValue::", filterValue);
    }, [filterValue])

    document.title = `Product Listing | ${siteConfig?.name}`;
    return (
        <div>
            <div className='p-2'>
                {/* Search Button */}
                <div className='d-grid py-3 pb-4'>
                    <SearchBar
                        defaultValue={params?.search}
                    />
                </div>

                {/* Title bar */}
                <Container>
                    <div className='d-grid gap-2'>
                        <div className={`flex-between-align ${(category && subCategory) ? '' : 'py-3 pb-4'}`}>
                            {
                                (category && subCategory) ?
                                    <h4 className='fw-bold text-capitalize mb-0'>{(params?.search) ?
                                        <>
                                            <span className='fw-medium'>Search By : </span>
                                            {params?.search}
                                        </> : subCategory?.label}</h4>
                                    : <h4 className='fw-bold text-capitalize mb-0'>All Prroducts <span className='fs-10 fw-light'>(Cause of wrong URL)</span></h4>
                            }
                            <div className='d-none d-sm-flex flex-between-align gap-2'>
                                <span>Sort by</span>
                                <select name="" id="" className='p-2 px-4'>
                                    <option value="">Popularity</option>
                                    <option value="">latest</option>
                                    <option value="">Lowest Price</option>
                                    <option value="">Highest Price</option>
                                </select>
                            </div>
                        </div>
                        {
                            (category && subCategory) &&
                            <p className='fs-sm-12 fs-md-14'>{subCategory?.description}</p>
                        }
                    </div>
                </Container>

                {/* Content */}
                <Container className='seller-product-page'>
                    <Row className='g-4'>
                        <Col className='col-12 col-sm-4 col-lg-3 px-2 px-sm-1'>
                            <div className='seller-product-bar'>
                                <Accordion defaultActiveKey={[0]} className='d-none d-sm-block' alwaysOpen>
                                    {
                                        filterList.map((item, index) => (
                                            <Accordion.Item eventKey={index} key={index}>
                                                <Accordion.Header><span className='text-capitalize'>{item.label}</span></Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='d-grid gap-1 gap-sm-2'>
                                                        {item?.data?.map((subItem, subIndex) => (
                                                            <div className='d-flex align-items-center gap-2' key={subIndex}>
                                                                <input
                                                                    type={'checkbox'}
                                                                    id={subItem.label}
                                                                    name={subItem.label}
                                                                    className='custom-input-check-size'
                                                                    style={{ cursor: 'pointer' }}
                                                                    value={subItem.value}
                                                                    onChange={(e) => handleFilterChange(
                                                                        {
                                                                            key: item.name,
                                                                            value: e.target.value,
                                                                            checked: e.target.checked
                                                                        }
                                                                    )}
                                                                />
                                                                <label htmlFor={subItem.label} style={{ cursor: 'pointer' }}>{subItem.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                                <div className='d-flex d-sm-none flex-between-align gap-2'>
                                    <select name="" id="" className='seller-box p-2 px-4 w-100'>
                                        <option value="">Popularity</option>
                                        <option value="">latest</option>
                                        <option value="">Lowest Price</option>
                                        <option value="">Highest Price</option>
                                    </select>
                                    <Link className='seller-box p-2 px-3 link-to-normal' onClick={() => setShow(!show)}><i className="bi bi-funnel-fill"></i></Link>
                                    <Modal show={show} onHide={() => setShow(!show)} className='custom-modal-accordion-container'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Filter</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='modal-accordion-container'>
                                            <div className='modal-accordion'>
                                                <Accordion defaultActiveKey={['0']} className='' alwaysOpen>
                                                    {
                                                        filterList.map((item, index) => (
                                                            <Accordion.Item eventKey={index} key={index}>
                                                                <Accordion.Header><span className='text-capitalize'>{item.label}</span></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='d-grid gap-1 gap-sm-2'>
                                                                        {item?.data?.map((subItem, subIndex) => (
                                                                            <div className='d-flex align-items-center gap-2' key={subIndex}>
                                                                                <input
                                                                                    type={'checkbox'}
                                                                                    id={`${subItem.label}-1`}
                                                                                    name={subItem.label}
                                                                                    className='custom-input-check-size'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    value={subItem.value}
                                                                                    onChange={(e) => handleFilterChange(
                                                                                        {
                                                                                            key: item.name,
                                                                                            value: e.target.value,
                                                                                            checked: e.target.checked
                                                                                        }
                                                                                    )}
                                                                                />
                                                                                <label htmlFor={`${subItem.label}-1`} style={{ cursor: 'pointer' }}>{subItem.label}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ))
                                                    }
                                                </Accordion>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShow(!show)}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={() => setShow(!show)}>
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </Col>
                        <Col className={`col-12 col-sm-8 col-lg-9 px-1 ${(filterProducts?.length === 0) && 'd-grid align-content-center'}`}>
                            <Container>
                                <Row className='g-3' style={{ minHeight: "83vh" }}>
                                    {
                                        (filterProducts?.length !== 0 && filterProducts) ?
                                            filterProducts.map((item, index) => (
                                                <Col key={index} className='col-12 col-sm-6 col-lg-4 px-0 px-sm-2 seller-product-detail'>
                                                    <div className='seller-product-detail-container d-grid gap-2'>
                                                        <div className='seller-product-detail-img-container'>
                                                            <Redirection
                                                                redirectValue={{
                                                                    url: `/product/${item.id}`,
                                                                    data: { product: item }
                                                                }}>
                                                                <img src={SERVER_URL + item.thumbnail} className='img-one' alt="" />
                                                                <img src={SERVER_URL + item.thumbnailSlide} className='img-two' alt="" />
                                                            </Redirection>
                                                        </div>
                                                        <div className='seller-product-detail-cnt-container px-2 pb-2 d-grid gap-1'>
                                                            <Redirection
                                                                redirectValue={{
                                                                    url: `/product/${item.id}`,
                                                                    data: { product: item }
                                                                }}>
                                                                <Link className='fw-bold fs-sm-18'>{item?.name}</Link>
                                                            </Redirection>
                                                            <span className='fs-14 fs-sm-12'>By {item?.brandName}</span>
                                                            <div className='fw-semibold'>From ₹{item?.productFromPrice}</div>
                                                            <p className='color-info fs-14 fs-sm-12 m-0'>From ₹{item?.productFromPrice * .8} with Printfuse premuim</p>
                                                            <div className='fs-14 d-flex align-items-center'>
                                                                {
                                                                    (item?.variantData) &&
                                                                    item?.variantData?.map((variant, vIndex) => {
                                                                        return (
                                                                            <span key={vIndex}>
                                                                                <span>{variant?.total} {variant?.name} </span>
                                                                                {
                                                                                    (item?.variantData.length !== (vIndex + 1)) &&
                                                                                    <i className="bi bi-dot" />
                                                                                }
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                            : <h2 className='fw-semibold text-center d-grid d-sm-flex flex-center-align gap-sm-3'><i className="bi bi-slash-circle text-danger" />Not assigned</h2>
                                    }
                                    <Col className='col-12 d-grid'>
                                        <CommonPagination
                                            totalCount={totalCount}
                                            changePage={(data) => {
                                                setFilterValue((prevData) => ({
                                                    ...prevData,
                                                    limit: data?.limit,
                                                    page: data?.page
                                                }))
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default SellerProductListingPage;