import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { GiNotebook } from 'react-icons/gi'
import { MdOutlineDone, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import CommonPagination from '../../Components/CommonPagination';
import { TbFileInvoice, TbTruckReturn } from 'react-icons/tb';
import { Tooltip } from 'react-tooltip';
import { IoCloseOutline } from "react-icons/io5";
import Swal from 'sweetalert2';
import { BiSolidEdit } from "react-icons/bi";
import moment from 'moment';
import Loader from '../../Components/Loader';
import { BsFillInfoCircleFill } from "react-icons/bs";

function SellerReturns() {

  const [orders, setOrders] = useState();
  const [totalList, setTotalList] = useState();
  const [filters, setFilters] = useState();
  const [filterList, setFilterList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loader, setLoader] = useState(true);
  const [multiSelect, setMultiSelect] = useState([]);


  const getOrderList = async () => {
    try {

      const response = await axios.put(frontEnd_API.returnorder, { ...filters }, header)
      if (response?.status == 200) {
        setOrders(response?.data?.data);
        setTotalList(response?.data?.totalCount);
        console.log("response?.data?.data::", response?.data?.data);
        setLoader(false);
      }
    }
    catch (e) {
      console.log("e::", e);
      setTotalList(10);
      setOrders([]);
      setLoader(false);
    }
  }

  const getGlobalState = async () => {
    try {
      const { data } = await axios.get(frontEnd_API.getGlobal, header)
      setFilterList((prevData) => ({
        ...prevData,
        parentData: [
          {
            title: 'stateName',
            data: data?.data
          }
        ]
      }));
    }
    catch (e) {
      console.log("Err::", e);
    }
  }

  useEffect(() => {
    getOrderList();
  }, [filters])

  useEffect(() => {
    getGlobalState();
  }, [])

  const handleCheckOrder = (item) => {
    const tempData = filters[item?.title] ?? [];

    const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
    if (newData) {
      const check = filters[item?.title].filter((e) => e != item?.value);
      setFilters((prevData) => ({
        ...prevData,
        [item?.title]: check
      }))
    }
    else {
      if (item?.value == '') {
        const data = filterList?.parentData?.find((e) => e?.title == item?.title);
        if (data?.data?.length == filters[item?.title]?.length) {
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: []
          }));
        }
        else {
          const check = data?.data.map((prevData) => (
            prevData?.value
          ));
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: check
          }));
        }
      }
      else {
        setFilters((prevData) => ({
          ...prevData,
          [item?.title]: [...tempData, item?.value]
        }))
      }
    }

  }

  const handleReturnStatus = async (item) => {
    try {
      if (item?.value == "seller-cancel") {
        Swal.fire({
          title: `Are You sure to cancel this return request!`,
          text: `This action can't be reversed.`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#12715B',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.put(`${frontEnd_API?.returnorder}/status/${item?.id}`, {
              returnOrderStatus: item?.value,
            }, header)
            if (response?.status == 200) {
              Swal.fire(
                'Return Request Cancelled Successfully!',
                'Your selected request is cancelled!',
                'success'
              );
              getOrderList();
            }
          }
        });
      }
      else {
        Swal.fire({
          title: `Are You sure to accept this return request!`,
          text: `This action can't be reversed.`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#12715B',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.put(`${frontEnd_API?.returnorder}/status/${item?.id}`, {
              returnOrderStatus: item?.value,
            }, header)
            if (response?.status == 200) {
              Swal.fire(
                `Request ${(item?.value == "seller-cancel") ? "Cancelled" : "Approved"} Successfully!`,
                `Your selected request is ${(item?.value == "seller-cancel") ? "cancelled" : "approved"}!`,
                'success'
              );
              getOrderList();
            }
          }
        });
      }
    }
    catch (e) {
      Swal.fire(
        `Request failed`,
        `${e?.response?.data?.message}`,
        'error'
      );
      console.log("Err::", e);
    }
  }

  const handleExpireDays = (createdDate) => {
    const createdMoment = moment(createdDate, "DD/MM/YYYY");

    // Calculate the expiration date by adding 60 days
    const expireDate = createdMoment.clone().add(60, 'days');

    // Calculate the days left until expiration
    const today = moment();
    const daysLeft = expireDate.diff(today, 'days');
    return daysLeft
  }
  const handleExpireDate = (createdDate) => {
    const createdMoment = moment(createdDate, "DD/MM/YYYY");
    const expireDate = createdMoment.clone().add(60, 'days');
    return expireDate
  }

  const handleCheck = (item) => {
    const check = multiSelect?.length > 0
      ? multiSelect.find((e) => e?.returnOrderChildId == item?.returnOrderChildId && e?.returnOrderId == item?.returnOrderId)
      : null
    if (check) {
      const tempData = multiSelect.filter((e) => e?.returnOrderChildId != item?.returnOrderChildId && e?.returnOrderId != item?.returnOrderId);
      setMultiSelect(tempData);
    }
    else {
      const tempData = [...multiSelect, { returnOrderChildId: item?.returnOrderChildId, returnOrderId: item?.returnOrderId }];
      setMultiSelect(tempData)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("multiSelect::", multiSelect);
  }, [multiSelect])

  document.title = `Seller Return Orders | ${siteConfig?.name}`;
  return (
    <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
      <Container fluid className='seller-order-main-container pb-5'>
        <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
          <h2 className='fw-bold  m-0'>Return Orders</h2>
        </div>
        <div className='seller-order-container p-2 p-sm-3'>
          <Tabs
            defaultActiveKey="returnsRequest"
            id="fill-tab-example"
            className="mb-3 custom-tabs"
            fill
          >
            <Tab eventKey="returnsRequest" title="Returns Request">
              <div className='py-2 d-grid gap-3 custom-btn-scrollbar'>
                {/* Custom Filtration */}
                <div className='d-flex align-items-center gap-2 d-md-grid'>
                  <Container fluid className='custom-seller-category-search px-0'>
                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                      <label className='bi bi-search' htmlFor='text'></label>
                      <input
                        type="text"
                        className='py-2 fs-sm-14 pe-2'
                        id='text'
                        placeholder='Search by Return ID status'
                        autoComplete='off'
                        onChange={(e) => {
                          setFilters((prevData) => ({
                            ...prevData,
                            search: e?.target?.value
                          }))
                        }}
                      />
                    </div>
                  </Container>
                  <div className='d-block d-md-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowFilter(!showFilter)}
                  ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                  {/* <Modal
                    show={showFilter}
                    onHide={() => setShowFilter(!showFilter)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filter Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='d-grid gap-3'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid w-100 custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.title}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => handleCheckOrder({
                                                value: subItem?.value,
                                                title: item?.title
                                              })} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowFilter(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                    </Modal.Footer>
                  </Modal> */}
                </div>
                {/* <div className='d-none d-md-grid gap-2'>
                  <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                  <div className='d-none d-md-flex'>
                    {
                      (filterList?.parentData?.length > 0) &&
                      filterList?.parentData?.map((item, index) => {
                        return (
                          <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                            <Dropdown className='d-inline w-100' autoClose="outside">
                              <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                {item.title}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                {
                                  item?.data.map((subItem, subIndex) => {
                                    return (
                                      <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                        <input type="checkbox"
                                          className='ui-checkbox'
                                          name={subItem?.label}
                                          id={subItem?.label}
                                          value={subItem?.value}
                                          onChange={() => handleCheckOrder({
                                            value: subItem?.value,
                                            title: item?.title
                                          })} />

                                        <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                      </Dropdown.Item>
                                    )
                                  })
                                }
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )
                      })
                    }
                  </div>
                </div> */}

                <Container fluid className='custom-order-list'>
                  <Row className='d-flex custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0'>
                    <Col className='col-3 col-md-1 flex-between-align gap-2'>
                      <span className='w-100'>
                        <div className='product-top-corner-check check-holder flex-center-align ps-lg-2 pe-lg-1 justify-content-start' style={{ position: "static" }}>
                          <input
                            type="checkbox"
                            className="ui-checkbox"
                            checked={(orders?.filter((e) => (e?.newAssignOrderChildId == 0) && ["seller-accepted"].includes(e?.returnOrderStatus))?.length == 0) ? false : (orders?.filter((e) => (e?.newAssignOrderChildId == 0) && ["seller-accepted"].includes(e?.returnOrderStatus))?.length) == multiSelect?.length ? true : false}
                            onChange={(check) => setMultiSelect(() => {
                              const tempData = orders?.filter((e) => e?.newAssignOrderChildId == 0 && ["seller-accepted"].includes(e?.returnOrderStatus)).map((e) => ({
                                returnOrderId: e?.returnOrderId,
                                returnOrderChildId: e?.returnOrderChildId
                              }))
                              return (check?.target?.checked) ? tempData : []
                            })}
                          />
                        </div>
                      </span>
                    </Col>
                    {
                      multiSelect?.length > 0 ?
                        <Col className='col-9 col-md-11 d-grid'>
                          <Button className='custom-create-order-btn fw-semibold text-uppercase'
                            variant={"success"}
                            as={Link}
                            to={'/create-orders'}
                            state={{
                              stateActiveTab: "selectUser",
                              stateCartData: multiSelect
                            }}
                          >
                            Create Order
                          </Button>
                        </Col>
                        : <>
                          <Col className='col-9 d-flex d-md-none flex-between-align gap-2'>
                            <span className='w-100'>Select All</span>
                          </Col>
                          <Col className='col-1 d-none d-md-flex flex-between-align gap-2'>
                            <span className='w-100'>Date</span>
                          </Col>
                          <Col className='col-3 d-none d-md-flex flex-between-align gap-2'>
                            <span className='w-100'>User Name</span>
                            <span className='w-100'>Net Price</span>
                          </Col>
                          <Col className='col-3 d-none d-md-flex flex-between-align gap-2'>
                            <span className='w-100 text-center'>Reason</span>
                          </Col>
                          <Col className='col-4 d-none d-md-flex flex-between-align gap-2'>
                            <span className='w-100 text-center'>Status</span>
                            <span className='w-100 text-center'>View</span>
                          </Col>
                        </>
                    }
                  </Row>
                  {/*  */}
                  {
                    loader ?
                      <div className='w-100 aspect-4-1 aspect-md-2-1'>
                        <Loader />
                      </div>
                      :
                      (!orders?.length > 0)
                        ? <div className='py-5'>
                          <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                            <div>
                              <TbTruckReturn />
                            </div>
                            <h5 className='fw-semibold'>No return orders yet</h5>
                            <p className='p-color fs-12'>return will appear here after your user fulfill return orders request.</p>
                          </div>
                        </div>
                        :
                        <>
                          {
                            orders.map((item, index) => {
                              const expireDays = handleExpireDays(moment(item?.returnOrderDate).format('DD/MM/YYYY'));
                              // const expireDate = handleExpireDate(moment(item?.returnOrderDate).format('DD/MM/YYYY'));
                              const tempId = multiSelect?.find((e) => e?.returnOrderId === item?.returnOrderId)
                              return (
                                <Row
                                  key={index}
                                  className={`custom-order-list-bar-item py-3 flex-between-align gy-3 gy-md-0 mb-2 mb-md-0`}
                                  style={{ backgroundColor: (["seller-cancel"].includes(item?.returnOrderStatus)) ? "#faebec" : (item?.newAssignOrderChildId != 0) && "#ebf6f1" }}
                                >
                                  <Col className='col-12 col-md-2 d-flex gap-2'>
                                    <div className='w-100'>

                                      {
                                        (item?.newAssignOrderChildId == 0 && ["seller-accepted"].includes(item?.returnOrderStatus)) ?
                                          <div
                                            className='product-top-corner-check check-holder flex-center-align ps-lg-2 pe-lg-1 justify-content-start'
                                            style={{ position: "static" }}
                                          >
                                            <input
                                              type="checkbox"
                                              className="ui-checkbox"
                                              checked={(tempId) ? true : false}
                                              onChange={(e) => handleCheck(item)}
                                            />
                                          </div>
                                          : <span>
                                            <BsFillInfoCircleFill
                                              className='ms-2'
                                              data-tooltip-id="my-info"
                                              data-tooltip-content={(!["seller-accepted", "seller-cancel"].includes(item?.returnOrderStatus)) ? "Accept return first !" : ["seller-cancel"].includes(item?.returnOrderStatus) ? "Return request rejected !" : "Order already created !"}
                                            />
                                            <Tooltip id="my-info" place="right" />
                                          </span>
                                      }

                                    </div>
                                    <div className='w-100'>
                                      <span className='d-md-none'>Date : </span>
                                      <span className='fs-12'>{moment(item?.returnOrderDate).format('DD/MM/YYYY')}</span>
                                    </div>
                                    {/* <div className='w-100  text-end text-md-center'>
                                      <span className='d-md-none'>No. : </span>
                                      {
                                        (parseInt(item?.id?.length) > 5) ?
                                          `#${item?.id.toString().slice(0, 3) + '...'}`
                                          : `#${item?.id}`
                                      }
                                    </div> */}
                                  </Col>
                                  <Col className='col-12 col-md-3 d-flex align-items-center gap-2 fs-sm-12'>
                                    <div className='w-100'>
                                      <span className='d-md-none fw-semibold fs-12'>Name : </span>
                                      {(item?.returnOrderPickUpName?.length > 8) ?
                                        item?.returnOrderPickUpName.toString().slice(0, 8) + '...'
                                        : item?.returnOrderPickUpName
                                      }
                                    </div>
                                    <div className='w-100 text-end text-md-start'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.returnOrderNetAmount}</span>
                                    </div>
                                  </Col>
                                  <Col className='col-16 col-md-3 d-flex gap-1'>
                                    <div className='w-100 d-grid'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={"danger"}
                                      >
                                        {item?.returnOrderReason}
                                      </Alert>
                                    </div>
                                  </Col>
                                  <Col className='col-12 col-md-4 custom-order-dropdown flex-between-align gap-3'>
                                    <div className='w-100'>
                                      {
                                        (["pending", "seller-cancel"].includes(item?.returnOrderStatus)) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={item?.returnOrderStatus == "pending" ? "info" : "danger"}
                                          >
                                            {item?.returnOrderStatus}
                                          </Alert>
                                          :
                                          (item?.newAssignOrderChildId != 0) ?
                                            <Alert
                                              className='m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              variant={"success"}
                                            >
                                              Order Created
                                            </Alert>
                                            :
                                            <Alert
                                              className='m-0 text-center fw-semibold py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                              variant={
                                                (expireDays > 30) ? "warning" :
                                                  (expireDays > 0) ? "danger" :
                                                    "secondary"
                                              }
                                            >
                                              {`${expireDays} days left`}
                                            </Alert>
                                      }
                                    </div>
                                    <div className='w-100 justify-content-end justify-content-md-center flex-center-align gap-2'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary'>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {(item?.returnOrderStatus == "pending") ?
                                            <>
                                              <Dropdown.Item className='text-success'
                                                onClick={() => handleReturnStatus({
                                                  value: "seller-accepted",
                                                  id: item?.id
                                                })}
                                              >
                                                <span><MdOutlineDone /> Accept Return Request</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item className='text-danger'
                                                onClick={() => handleReturnStatus({
                                                  value: "seller-cancel",
                                                  id: item?.id
                                                })}
                                              >
                                                <span><IoCloseOutline /> Cancel Return Request</span>
                                              </Dropdown.Item>
                                            </>
                                            :
                                            <Dropdown.Item disabled={true} className='py-0'>
                                              <span className='fs-12'>Wait untill provider change</span>
                                            </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        to={`/returns/${item?.returnOrderNumber}`}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </div>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </>
                  }
                </Container>
                <CommonPagination
                  totalCount={totalList}
                  changePage={(data) => {
                    setFilters((prevData) => ({
                      ...prevData,
                      limit: data?.limit,
                      page: data?.page
                    }))
                  }}
                />
              </div>
            </Tab>
            <Tab eventKey="rtoRts" title="RTO & RTS">
              <Container fluid className='custom-seller-category-search px-0 py-2'>
                <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                  <label className='bi bi-search' htmlFor='text'></label>
                  <input type="text" className='py-2' id='text' placeholder='Search by order number, customer, or product name' autoComplete='off' />
                </div>

              </Container>
              <Container fluid>
                <Row>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Order</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Created</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-4'>
                    <span className='fs-12 fw-semibold p-color'>Customer</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Production cost</span>
                  </Col>
                  <Col className='d-none d-md-block col-md-2'>
                    <span className='fs-12 fw-semibold p-color'>Status</span>
                  </Col>
                </Row>
              </Container>
              <div className='py-2 flex-center-align py-5'>
                <div className='custom-transaction-tab d-grid justify-content-center text-center'>
                  <div>
                    <MdOutlineProductionQuantityLimits />
                  </div>
                  <h5 className='fw-semibold'>No RTO or RTS yet !</h5>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Container >
    </div >
  )
}

export default SellerReturns