import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Redirection from '../../Components/Redirection/Redirection';
import axios from 'axios';
import { frontEnd_API, header, SERVER_URL, siteConfig } from '../../Config/Config';
import SearchBar from '../../Components/SearchBar';

function SellerCategoryPage() {

    const [category, setCategory] = useState({});
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        if (location?.state?.data) {
            setCategory(location?.state?.data);
            console.log(location?.state?.data);
        }
        else {
            axios.get(frontEnd_API.getcategory, header)
                .then((res) => {
                    console.log("res::", res);
                    let hyphenatedString = params.category;
                    let originalString = hyphenatedString.replace(/-/g, ' ');
                    const cdata = res?.data?.data
                    const found = cdata.find((item) => item.label === originalString);
                    setCategory(found);
                    console.log(found, originalString);
                })
                .catch((e) => {
                    console.log("e::", e);
                })
        }
    }, [location, params.category])

    document.title = `Category Page | ${siteConfig?.name}`;

    return (
        <div className='custom-seller-category py-3 py-lg-4 px-0 px-lg-2'>
            {/* Search Button */}
            <div className='pb-4'>
                <SearchBar />
            </div>

            {/* Header description */}
            <Container>
                <Row>
                    <Col>
                        <h2 className='fw-semibold text-capitalize'>{category?.label}</h2>
                        <p className='fs-sm-12 p-color fs-14'>{category?.description}</p>
                    </Col>
                </Row>
            </Container>

            {/* List Of Sub Category */}
            <Container className='py-3'>
                <Row className='g-3 g-sm-3'>
                    {
                        category?.children?.map((item, index) => {
                            return (
                                <Col className={`col-12 col-sm-6 col-lg-4`} key={index}>
                                    <Redirection
                                        redirectValue={{
                                            url: `${item.label}`,
                                            data: { category, 'subCategory': item }
                                        }}
                                    >
                                        <Link className='half-border-rad custom-sub-category'>
                                            <div className='custom-sub-category-content px-2 py-3 px-md-3'>
                                                <h3 className='fw-semibold fs-23 fs-sm-20 text-capitalize'>{item.label}</h3>
                                            </div>
                                            <div className='custom-sub-category-img'>
                                                <img src={(item?.thumbnail) ? SERVER_URL + item?.thumbnail : require(`../../Assets/Images/${(index + 1)}.png`)} alt="" />
                                            </div>
                                        </Link>
                                    </Redirection>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default SellerCategoryPage
