import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { frontEnd_API, header, SERVER_URL, siteConfig } from '../../Config/Config';
import { useSelector } from 'react-redux';
import { ProductSliderList, ProductSliderListTwo } from '../../Data/localData';
import Redirection from '../../Components/Redirection/Redirection';
import CommonSlider from '../../Components/CommonSlider';
import SearchBar from '../../Components/SearchBar';
import Loader from '../../Components/Loader';

function SellerHomeCategory() {

    const user = useSelector((state) => state.user.value);
    const [category, setCategory] = useState([]);
    const [sliders, setSliders] = useState();
    useEffect(() => {
        const customHeader = {
            headers: {
                'token': `Bearer ${user?.token}`,
                'Content-Type': 'application/json'
            }
        }
        axios.get(frontEnd_API.getcategory, customHeader)
            .then((res) => {
                setCategory(res?.data?.data);
                console.log(res?.data?.data);
            })
            .catch((e) => {
                console.log("e::", e);
            })
    }, [user?.token])

    // const settings = {
    //     dots: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 5,
    //     // initialSlide: 0,
    //     responsive: [
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 4,
    //             }
    //         },
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 3,
    //                 infinite: true,
    //             }
    //         },
    //         {
    //             breakpoint: 400,
    //             settings: {
    //                 slidesToShow: 3,
    //             }
    //         }
    //     ]
    // };

    const newSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
    };

    const getSliderList = async () => {
        try {
            const response = await axios.get(frontEnd_API?.listsection, header)
            if (response?.status == 200) {
                setSliders(response?.data?.data)
            }
            console.log("setSliders::", response?.data?.data);
        }
        catch (e) {
            console.log("Err::", e);
        }
    }

    useEffect(() => {
        getSliderList();
    }, [])

    document.title = `Category Home | ${siteConfig?.name}`;

    return (
        <div className='custom-seller-category py-3 py-lg-4 px-0 px-lg-2'>

            {/* Search Button */}
            <SearchBar />
            {/* <Loader /> */}
            {/* Explore Printfuse Best */}
            {
                (category) &&
                <Container className='py-4'>
                    <Row className='gy-3 gy-sm-3'>
                        <Col className='col-12'>
                            <div className='pb-3'>
                                <h4 className='fw-semibold'>Explore Printifuse's bests</h4>
                                <p className='mb-0 fs-sm-12' style={{ width: "70%" }}>Here are some of the most popular product categories in our catalog.</p>
                            </div>
                        </Col>
                        {
                            category?.map((item, index) => {
                                return (
                                    <Col className='col-12 col-sm-6 col-lg-4' key={index}>
                                        <Redirection
                                            redirectValue={{
                                                url: `/category/${item.label}`,
                                                data: item
                                            }}
                                        >
                                            <Link className='half-border-rad custom-sub-category'>
                                                <div className='custom-sub-category-content px-2 py-3 px-md-3'>
                                                    <h3 className='fw-semibold fs-23 fs-sm-20 text-capitalize'>{item.label}</h3>
                                                </div>
                                                <div className='custom-sub-category-img'>
                                                    <img src={(item?.thumbnail) ? SERVER_URL + item?.thumbnail : require(`../../Assets/Images/${(index + 1)}.png`)} alt="" />
                                                </div>
                                            </Link>
                                        </Redirection>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            }

            {/* Banner */}
            <Container className='custom-seller-category-banner-final p-2 py-4 pt-5'>
                <div className='seller-category-slider-new-item d-grid d-sm-flex'>
                    <div className='seller-category-slider-new-img d-flex d-sm-none'>
                        <img src={require('../../Assets/Images/back1.png')} alt="" />
                    </div>
                    <div className='seller-category-slider-new-content p-2 p-sm-3 p-md-4 p-lg-5 gap-2 gap-sm-1 gap-md-2'>
                        <h2 className='fw-bold fs-lg-20 fs-md-12 mb-1'>Bring Your Unique Designs to Life on Quality Tees</h2>
                        <p className='fs-lg-14 fs-md-10 fs-sm-10 mb-2'>Create personalized t-shirts with ease. Design your own prints and shop unique styles at Print Everywhere You Want.</p>
                        <Link className='custom-design-btn fw-bold fs-md-12 fs-sm-14 px-2 py-1 py-lg-2' to={"/category/men's-clothing/t~shirt"}>Start Designing</Link>
                    </div>
                    <div className='seller-category-slider-new-img d-none d-sm-flex'>
                        <img src={require('../../Assets/Images/back1.png')} alt="" />
                    </div>
                </div>
            </Container>

            {
                sliders?.length > 0 &&
                sliders.map((item) => (
                    <>
                        {
                            (item?.type == "product-slider") &&
                            <CommonSlider
                                data={item?.data}
                                title={item?.label}
                                description={item?.description}
                                fromAPI={true}
                                seeClose={true}
                            />
                        }{
                            (item?.type == "blog-slider") &&
                            <Container className='seller-category-slider-new px-3 px-sm-2 p-2 py-4'>
                                <div>
                                    <h4 className='fw-semibold mb-3'>{item?.label}</h4>
                                </div>
                                <Slider {...newSettings}>
                                    {
                                        item?.data?.length > 0 &&
                                        item?.data.map((subItem) => (
                                            <div className='seller-category-slider-new-item d-grid d-sm-flex'>
                                                <div className='seller-category-slider-new-img'>
                                                    <img src={SERVER_URL + subItem?.thumbnail} alt="" />
                                                </div>
                                                <div className='seller-category-slider-new-content p-2 p-sm-3 p-md-4 p-lg-5'>
                                                    <h5 className='fw-semibold fs-sm-18 text-capitalize'>{subItem?.blogCategory?.name}</h5>
                                                    <p className='fs-sm-14 mb-1 mb-sm-2'>{subItem?.description}</p>
                                                    <span className='p-color fs-14 text-end'>- By {subItem?.authorName}</span>
                                                    {/* <Link>See item</Link> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </Container>
                        }
                    </>
                ))
            }

        </div >
    )
}

export default SellerHomeCategory