import ErrorPage from "../Components/ErrorPage";
import SignupPage from "../View/SignUp_LogIn/SignupPage";
import SigninPage from "../View/SignUp_LogIn/SigninPage";
import SellerCreateStore from "../View/SellerDashboard/SellerCreateStore";
// import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvasFABRIC-Old";
// import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvasKONVA";
import ProductCanvas from "../View/SellerDashboard/Products/ProductCanvas-05092024";
import SellerDashboard from "../View/SellerDashboard/SellerDashboard";
import SellerOrders from "../View/SellerDashboard/SellerOrders";
import SellerCategoryPage from "../View/SellerDashboard/SellerCategoryPage";
import SellerHomeCategory from "../View/SellerDashboard/SellerHomeCategory";
import SellerProductListingPage from "../View/SellerDashboard/SellerProductListingPage";
import SellerWallet from "../View/SellerDashboard/SellerWallet";
import SellerSingleOrderPage from "../View/SellerDashboard/SellerSingleOrderPage";
import SellerInsights from "../View/SellerDashboard/SellerInsights";
import SellerSubscription from "../View/SellerDashboard/SellerSubscription";
import SellerStoreListing from "../View/SellerDashboard/SellerStoreListing";
import SellerAccount from "../View/SellerDashboard/SellerAccount";
import SellerAccountSetting from "../View/SellerDashboard/SellerAccountSetting";
import ProductHome from "../View/SellerDashboard/Products/ProductHome";
import SellerSingleProduct from "../View/SellerDashboard/SellerSingleProduct";
import SellerStoreSetting from "../View/SellerDashboard/SellerStoreSetting";
import ProductCreate from "../View/SellerDashboard/Products/ProductCreate";
import SellerAccountFiles from "../View/SellerDashboard/SellerAccountFiles";
import SellerReturns from "../View/SellerDashboard/SellerReturns";
import SellerSingleReturnPage from "../View/SellerDashboard/SellerSingleReturnPage";
import NotificationList from "../Components/NotificationList";
import SellerCreateOrder from "../View/SellerDashboard/SellerCreateOrder";

const routes = [
    {
        path: '*',
        element: <ErrorPage />
    },
    {
        path: '/signin',
        element: <SigninPage />
    },
    {
        path: '/signup',
        element: <SignupPage />
    },
    {
        path: '/from-home/:domain',
        element: <SigninPage />
    },
    {
        path: '/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/app/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/',
        element: <SellerDashboard />
    },
    {
        path: '/dashboard',
        element: <SellerDashboard />
    },
    {
        path: '/products',
        element: <ProductHome />
    },
    {
        path: '/category',
        element: <SellerHomeCategory />
    },
    {
        path: '/category/:category',
        element: <SellerCategoryPage />
    },
    {
        path: '/category/:category/:product',
        element: <SellerProductListingPage />
    },
    {
        path: '/product/:productId',
        element: <SellerSingleProduct />
    },
    {
        path: '/product/:productId/:providerId/canvas',
        element: <ProductCanvas />
    },
    {
        path: '/product/:productId/:providerId/create-product',
        element: <ProductCreate />
    },
    {
        path: '/search-result/:search',
        element: <SellerProductListingPage />
    },
    {
        path: '/search-result',
        element: <SellerProductListingPage />
    },
    {
        path: '/create-orders',
        element: <SellerCreateOrder />
    },
    {
        path: '/orders',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus/:orderStatus',
        element: <SellerOrders />
    },
    {
        path: '/orders/:groupStatus/:orderStatus/:orderId',
        element: <SellerSingleOrderPage />
    },
    {
        path: '/returns',
        element: <SellerReturns />
    },
    {
        path: '/returns/:returnOrderNumber',
        element: <SellerSingleReturnPage />
    },
    {
        path: '/create-store',
        element: <SellerCreateStore />
    },
    {
        path: '/store-setting',
        element: <SellerStoreSetting />
    },
    {
        path: '/payment',
        element: <SellerWallet />
    },
    {
        path: '/insights',
        element: <SellerInsights />
    },
    {
        path: '/subscription',
        element: <SellerSubscription />
    },
    {
        path: '/my-store',
        element: <SellerStoreListing />
    },
    {
        path: '/account/details',
        element: <SellerAccount />
    },
    {
        path: '/account/settings',
        element: <SellerAccountSetting />
    },
    {
        path: '/account/uploaded-files',
        element: <SellerAccountFiles />
    },
    {
        path: '/notification',
        element: <NotificationList />
    },
]


export default routes;