import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Dropdown, Modal, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import { GiNotebook } from 'react-icons/gi'
import { MdKeyboardDoubleArrowRight, MdOutlineDone, MdOutlineProductionQuantityLimits } from "react-icons/md";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import CommonPagination from '../../Components/CommonPagination';
import { TbFileInvoice } from 'react-icons/tb';
import { Tooltip } from 'react-tooltip';
import { IoCloseOutline } from "react-icons/io5";
import Swal from 'sweetalert2';
import { BiSolidEdit } from "react-icons/bi";
import moment from 'moment';
import Loader from '../../Components/Loader';
import OrderSubCategoryList from '../../Components/OrderSubCategoryList';
import Flatpickr from "react-flatpickr";
import FilterList from '../../Components/FilterList';
import { BsBank } from 'react-icons/bs';
import { Input } from 'reactstrap';

function SellerOrders() {


  const navigate = useNavigate();
  const [orders, setOrders] = useState();
  const [loader, setLoader] = useState(true);
  const params = useParams();
  // const [showFilter, setShowFilter] = useState(false);
  // const [calendar, setCalendar] = useState(false);
  // const [filters, setFilters] = useState([]);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection'
  //   }
  // ]);

  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setFilters((prev) =>
  //     prev.includes(value)
  //       ? prev.filter((item) => item !== value)
  //       : [...prev, value]
  //   );
  // }

  // const handleDateChange = (e) => {
  //   console.log(e);
  //   setState([e]);
  // }

  // useEffect(() => {
  //   console.log(filters);
  // }, [filters])

  // useEffect(() => {
  //   setStartDate((state[0].startDate) && `${state[0]?.startDate.getDate()}/${state[0]?.startDate.getMonth() + 1}/${state[0]?.startDate.getFullYear()}`)

  //   setEndDate((state[0].endDate) && `${state[0]?.endDate.getDate()}/${state[0]?.endDate.getMonth() + 1}/${state[0]?.endDate.getFullYear()}`)
  // }, [state, startDate, endDate])

  // const handleClick = (val) => {
  //   setFilters((prevData) => prevData.filter(value => value !== val))
  //   setState([
  //     {
  //       startDate: '',
  //       endDate: '',
  //       key: 'selection'
  //     }
  //   ]);
  // }

  const [totalList, setTotalList] = useState();
  const [filters, setFilters] = useState();
  const [filterList, setFilterList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [orderCategory, setOrderCategory] = useState([]);
  const [tabHandler, setTabHandler] = useState({
    groupStatus: "",
    orderStatus: ""
  });

  const [handleDate, setHandleDate] = useState(1);
  const tempDate = [
    {
      label: "Last 1 month",
      value: 1
    },
    {
      label: "Last 3 month",
      value: 3
    },
    {
      label: "Last 6 month",
      value: 6
    },
    {
      label: "Last 1 year",
      value: 12
    },
    {
      label: "Custom range",
      value: 0
    }
  ]

  const getOrderList = async () => {
    try {
      const body = {
        ...filters,
        groupStatus: [params?.groupStatus ?? "pending"],
        orderStatus: [params?.orderStatus ?? "all"]
      }
      const response = await axios.put(frontEnd_API.orders, body, header)
      if (response?.status == 200) {
        setOrders(response?.data?.data);
        setTotalList(response?.data?.totalCount);
        console.log("response?.data?.data::", response?.data?.data);
        setLoader(false);
      }
    }
    catch (e) {
      console.log("e::", e);
      setTabHandler((prevData) => ({
        ...prevData,
        // orderStatus: e
      }))
      setTotalList(10);
      setOrders([]);
      setLoader(false);
    }
  }

  const getGlobalState = async () => {
    try {
      const { data } = await axios.get(frontEnd_API.getGlobal, header)
      setFilterList((prevData) => ({
        ...prevData,
        parentData: []
      }));
      setLoader(false);
    }
    catch (e) {
      console.log("Err::", e);
      setLoader(false);
    }
  }

  useEffect(() => {
    getOrderList();
  }, [filters, params])

  const getOrderStatusList = async () => {
    try {
      const { data } = await axios.get(frontEnd_API?.orderstatus, header)
      if (data?.data?.length > 0) {
        const tempData = data?.data.map((item) => ({
          group: item?.group,
          data: (
            delete item?.group,
            item
          )
        }))

        const groupedData = Object.values(tempData.reduce((acc, item) => {
          if (!acc[item.group]) {
            acc[item.group] = { group: item.group, data: [] };
          }
          acc[item.group].data.push(item.data);
          return acc;
        }, {}));

        setOrderCategory(groupedData)

        navigate(`/orders/${(params?.groupStatus && params?.orderStatus) ? params?.groupStatus + "/" + params?.orderStatus : groupedData[0]?.group + "/all"} `);

        setTabHandler((prevData) => ({
          ...prevData,
          groupStatus: params?.groupStatus ?? groupedData[0]?.group,
          orderStatus: params?.orderStatus ?? groupedData[0]?.data[0]?.label
        }))
      }
    }
    catch (e) {
      console.log("Err::", e);
    }
  }

  useEffect(() => {
    if (params?.groupStatus) {
      setTabHandler((prevData) => ({
        ...prevData,
        groupStatus: params?.groupStatus,
        orderStatus: params?.orderStatus
      }))
    }
  }, [params?.groupStatus])

  useEffect(() => {
    getGlobalState();
    getOrderStatusList();
  }, [])

  const handleCheckOrder = (item) => {
    const tempData = filters[item?.title] ?? [];

    const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
    if (newData) {
      const check = filters[item?.title].filter((e) => e != item?.value);
      setFilters((prevData) => ({
        ...prevData,
        [item?.title]: check
      }))
    }
    else {
      if (item?.value == '') {
        const data = filterList?.parentData?.find((e) => e?.title == item?.title);
        if (data?.data?.length == filters[item?.title]?.length) {
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: []
          }));
        }
        else {
          const check = data?.data.map((prevData) => (
            prevData?.value
          ));
          setFilters((prevData) => ({
            ...prevData,
            [item?.title]: check
          }));
        }
      }
      else {
        setFilters((prevData) => ({
          ...prevData,
          [item?.title]: [...tempData, item?.value]
        }))
      }
    }
  }

  const handleOrderStatus = async (item) => {

    const data = {
      ...item,
      orderStatus:item?.value
    }
    console.log('valuess handleOrderStatus :: ', item, data, selectedOrder)
    // return
    try {
      if (item?.value == "seller-cancelled") {
        Swal.fire({
          title: `Are You sure to cancel this order!`,
          text: `This action can't be reversed.`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#12715B',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, {
              orderStatus: item?.value,
            }, header)
            if (response?.status == 200) {
              Swal.fire(
                'Order Cancelled Successfully!',
                'Your selected order is cancelled!',
                'success'
              );
              setLoader(false);
              getOrderList();
            }

            console.log('sss ::: ', response)
          }
        });
      }
      else {
        const response = await axios.put(`${frontEnd_API?.orders}/status/${item?.id}`, selectedOrder, header)
        console.log("response :: ", response?.config?.data)
        if (response?.status == 200) {
          Swal.fire(
            `Order ${(item?.value == "seller-cancelled") ? "Cancelled" : "Approved"} Successfully!`,
            `Your selected order is ${(item?.value == "seller-cancelled") ? "cancelled" : "approved"}!`,
            'success'
          );
          setSelectedOrder({})
          setCourierModalStatus(false)
          setLoader(false);
          getOrderList();
        }
      }
    }
    catch (e) {
      Swal.fire(
        `Request failed`,
        `${e?.response?.data?.message}`,
        'error'
      );
      setLoader(false);
      console.log("Err::", e);
    }
  }

  const handleDateChange = (value) => {
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(value, 'months').format('YYYY-MM-DD');
    setHandleDate(value);
    console.log("[startDate, endDate]::", [startDate, endDate]);

    setFilters((prevData) => ({
      ...prevData,
      dateRange: [startDate, endDate]
    }))
  }

  // manage order status section start -----
  const [courierModalStatus, setCourierModalStatus] = useState(false)
  const [courierData, setCourierData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({})
  const manageOrderStatus = async (obj = {}) => {
    if (obj?.from != '') {
      console.log('sss ::', obj?.from)
      delete obj?.orderStatus

      obj.orderStatus = obj.value

      setSelectedOrder({...obj})
      console.log("ddd condition :: ", obj?.orderCourierCompanyId, [null].includes(obj?.orderCourierCompanyId))
      if ([null, undefined, 'undefined'].includes(obj?.orderCourierCompanyId)) {
        const { data } = await axios?.get(frontEnd_API?.orders + '/courier/' + obj?.orderId, header)
        console.log('courierData ::' , data)
        setCourierData([...data?.data])
        setCourierModalStatus(true)
      }
    }
  }
  console.log('setSelectedOrder ::', selectedOrder)
  // -----


  useEffect(() => {
    console.log("filters::", filters);
  }, [filters])

  document.title = `Seller Orders | ${siteConfig?.name}`;

  return (
    <div className='seller-order p-0' style={{ minHeight: "100vh" }}>
      <Container fluid className='seller-order-main-container pb-5 px-2 px-sm-3 px-md-5'>
        <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
          <h3 className='fw-semibold  m-0'>Manage Orders</h3>
          <div className='flex-between-align gap-2'>
            <Dropdown className='w-100'>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-two w-100 py-2 fw-semibold fs-sm-14'>
                Create order
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/create-orders'}>Manual</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='seller-order-container p-2 p-sm-3'>
          <Tab.Container activeKey={tabHandler?.groupStatus ? tabHandler?.groupStatus : orderCategory[0]?.group}>
            <Nav fill className='custom-tabs mb-2' variant="pills"
              onSelect={(e) => {
                navigate(`/orders/${e}/${tabHandler?.orderStatus}`)
                setTabHandler((prevData) => ({
                  ...prevData,
                  groupStatus: e
                }))
              }}>
              {
                orderCategory?.length > 0 &&
                orderCategory.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={item?.group} className='flex-center-align gap-1'>
                      <span className='text-capitalize'>{item?.group}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={orderCategory[0]?.group} title={orderCategory[0]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0) && [
                        { label: "All", value: "all" },
                        { label: "COD", value: "cod" },
                        { label: "prepaid", value: "prepaid" },
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <FilterList
                      filterList={filterList}
                      isDateFilter={true}
                      onChangeData={(e) => {
                        setFilters(e);
                      }}
                    />
                  </>

                  <Container fluid className='custom-order-list'>
                    <Row className='d-none d-md-flex text-secondary-emphasis fs-12 fw-semibold custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0 overflow-hidden bg-body-tertiary'>
                      <Col className='col-2 flex-between-align gap-2'>
                        <span className='w-100 '> Order ID</span>
                        <span className='w-100'>Date</span>
                      </Col>
                      <Col className='col-2 col-xl-1'>Customer</Col>
                      <Col className='d-none d-xl-block col-xl-1'>Mobile</Col>
                      <Col className='col-2 col-xl-1'>Order Value</Col>
                      <Col className='col-2 text-xl-center'>Provider</Col>
                      <Col className='col-xl-1 d-none text-center d-xl-block'>Payment</Col>
                      <Col className='col-2 text-xl-center'>Status</Col>
                      <Col className='col-2 text-center'>Action</Col>
                    </Row>
                    {/* Order List Starts here... */}
                    {
                      loader ?
                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                          <Loader />
                        </div>
                        : (!orders?.length > 0)
                          ? <div className='py-5'>
                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                              <div>
                                <GiNotebook />
                              </div>
                              <h5 className='fw-semibold'>No invoices yet</h5>
                              <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                            </div>
                          </div>
                          :
                          <>
                            {
                              orders.map((item, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                    style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                  >
                                    {/* <Col className='col-12 col-md-1 d-flex gap-2'>
                                <span className='d-md-none'>Order No. : </span>
                                {
                                  (parseInt(item?.orderId?.length) > 5) ?
                                    <span>#{item?.orderId.toString().slice(0, 3) + '...'}</span>
                                    : <span>#{item?.orderId}</span>
                                }
                              </Col> */}
                                    <Col className='col-12 col-md-2 d-flex gap-2'>
                                      <div className='w-100'>
                                        <span className='d-md-none'>ID : </span>
                                        {
                                          (parseInt(item?.id?.length) > 5) ?
                                            `#${item?.id.toString().slice(0, 3) + '...'}`
                                            : `#${item?.id}`
                                        }
                                      </div>
                                      <div className='w-100'>
                                        <span className='d-md-none'>Date : </span>
                                        <span className='fs-12'>{moment(item?.orderDate).format('DD/MM/YYYY')}</span>
                                      </div>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Customer : </span>
                                      {
                                        (item?.userData?.name) ?
                                          (item?.userData?.name?.length > 15) ?
                                            <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                            : <span>{item?.userData?.name}</span>
                                          : <span className='text-secondary fs-14'>--Not Available--</span>
                                      }
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex align-items-center gap-2 fs-sm-12'>
                                      {/* <span className='d-md-none fw-semibold fs-12'>Value : </span> */}
                                      <span className='fs-14'>{item?.userData?.mobile}</span>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.orderNetAmount}</span>
                                    </Col>
                                    <Col className='col-12 col-md-2 d-flex flex-wrap align-items-center justify-content-xl-center gap-1'>
                                      <span className='d-md-none fw-semibold fs-12'>Provider : </span>
                                      <div className='d-flex flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10 justify-content-xl-center'>
                                        {
                                          item?.providerData.map((provider, pIndex) => (
                                            <span key={pIndex} className='text-center'>{provider?.providerBusinessName} X {provider?.totalProduct}
                                              {((pIndex + 1) != item?.providerData?.length) && ", "}</span>
                                          ))
                                        }
                                      </div>
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex justify-content-xl-center align-items-center gap-2 fs-sm-12'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                        <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                      </Alert>
                                    </Col>
                                    <Col className='col-6 col-md-2 d-flex justify-content-xl-center'>
                                      {
                                        (item?.returnOrderId == 0) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={
                                              (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                (["seller-accepted", "provider-accepted"].includes(item?.orderStatus)) ? "primary" :
                                                  (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                    (["provider-completed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                      (["seller-cancelled", "provider-cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                        (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                          "secondary"
                                            }
                                          >
                                            {item?.orderStatus.replace("-", " ")}
                                          </Alert>
                                          : <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                            variant={"danger"}
                                          >
                                            Order in return
                                          </Alert>
                                      }
                                    </Col>
                                    <Col className='col-6 col-md-2 custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-evenly gap-3'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                                              <>
                                                {

                                                  (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                                                  < Dropdown.Item className='text-success'
                                                    onClick={() => manageOrderStatus({ ...item, value:'seller-accepted', from:'accept', id:item?.orderId})}
                                                  >
                                                    <span><MdOutlineDone /> Accept Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item className='text-danger'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-cancelled",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><IoCloseOutline /> Cancel Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["seller-cancelled"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item disabled={true} className='py-0'>
                                                    <span className='fs-14 text-danger'>You cancelled this order.</span>
                                                  </Dropdown.Item>
                                                }
                                              </>
                                              :
                                              <Dropdown.Item disabled={true} className='py-0'>
                                                <span className='fs-12'>Wait untill provider change</span>
                                              </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        // to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
                                        to={(params?.groupStatus) ? `${item?.orderId}` : `${item?.orderId}`}
                                        state={{ data: item }}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </>
                    }
                  </Container>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={orderCategory[1]?.group} title={orderCategory[1]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                      <Container fluid className='custom-seller-category-search px-0'>
                        <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                          <label className='bi bi-search' htmlFor='text'></label>
                          <input
                            type="text"
                            className='py-2 fs-sm-14 pe-2'
                            id='text'
                            placeholder='Search by order ID'
                            autoComplete='off'
                            onChange={(e) => {
                              setFilters((prevData) => ({
                                ...prevData,
                                search: e?.target?.value
                              }))
                              setLoader(true);
                            }}
                          />
                        </div>
                      </Container>
                      <div className='d-md-none'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowFilter(!showFilter)}
                      ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                      {/* Filters */}
                      <Modal
                        show={showFilter}
                        onHide={() => setShowFilter(!showFilter)}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filter Invoices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='d-grid gap-3'>
                            {
                              (filterList?.parentData?.length > 0) &&
                              filterList?.parentData?.map((item, index) => {
                                return (
                                  <div key={index} className={`d-grid w-100 custom-select-option`}>
                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                      <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                        {item.label}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                        {
                                          item?.data.map((subItem, subIndex) => {
                                            return (
                                              <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                <input type="checkbox"
                                                  className='ui-checkbox'
                                                  name={subItem?.label}
                                                  id={subItem?.label}
                                                  value={subItem?.value}
                                                  onChange={() => {
                                                    handleCheckOrder({
                                                      value: subItem?.value,
                                                      title: item?.title
                                                    })
                                                    setLoader(true);
                                                  }} />

                                                <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                              </Dropdown.Item>
                                            )
                                          })
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowFilter(false)}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className='d-none d-md-grid gap-2'>
                      <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                      <div className='d-none d-md-flex'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => {
                                                handleCheckOrder({
                                                  value: subItem?.value,
                                                  title: item?.title
                                                })
                                                setLoader(true);
                                              }} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </>

                  <Container fluid className='custom-order-list'>
                    <Row className='d-none d-md-flex text-secondary-emphasis fs-12 fw-semibold custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0 overflow-hidden bg-body-tertiary'>
                      <Col className='col-2 flex-between-align gap-2'>
                        <span className='w-100 '> Order ID</span>
                        <span className='w-100'>Date</span>
                      </Col>
                      <Col className='col-2 col-xl-1'>Customer</Col>
                      <Col className='d-none d-xl-block col-xl-1'>Mobile</Col>
                      <Col className='col-2 col-xl-1'>Order Value</Col>
                      <Col className='col-2 text-xl-center'>Provider</Col>
                      <Col className='col-xl-1 d-none text-center d-xl-block'>Payment</Col>
                      <Col className='col-2 text-xl-center'>Status</Col>
                      <Col className='col-2 text-center'>Action</Col>
                    </Row>
                    {/* Order List Starts here... */}
                    {
                      loader ?
                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                          <Loader />
                        </div>
                        : (!orders?.length > 0)
                          ? <div className='py-5'>
                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                              <div>
                                <GiNotebook />
                              </div>
                              <h5 className='fw-semibold'>No invoices yet</h5>
                              <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                            </div>
                          </div>
                          :
                          <>
                            {
                              orders.map((item, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                    style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                  >
                                    <Col className='col-12 col-md-2 d-flex gap-2'>
                                      <div className='w-100'>
                                        <span className='d-md-none'>ID : </span>
                                        {
                                          (parseInt(item?.id?.length) > 5) ?
                                            `#${item?.id.toString().slice(0, 3) + '...'}`
                                            : `#${item?.id}`
                                        }
                                      </div>
                                      <div className='w-100'>
                                        <span className='d-md-none'>Date : </span>
                                        <span className='fs-12'>{moment(item?.orderDate).format('DD/MM/YYYY')}</span>
                                      </div>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Customer : </span>
                                      {
                                        (item?.userData?.name) ?
                                          (item?.userData?.name?.length > 15) ?
                                            <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                            : <span>{item?.userData?.name}</span>
                                          : <span className='text-secondary fs-14'>--Not Available--</span>
                                      }
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex align-items-center gap-2 fs-sm-12'>
                                      {/* <span className='d-md-none fw-semibold fs-12'>Value : </span> */}
                                      <span className='fs-14'>{item?.userData?.mobile}</span>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.orderNetAmount}</span>
                                    </Col>
                                    <Col className='col-12 col-md-2 d-flex flex-wrap align-items-center justify-content-xl-center gap-1'>
                                      <span className='d-md-none fw-semibold fs-12'>Provider : </span>
                                      <div className='d-flex flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10 justify-content-xl-center'>
                                        {
                                          item?.providerData.map((provider, pIndex) => (
                                            <span key={pIndex} className='text-center'>{provider?.providerBusinessName} X {provider?.totalProduct}
                                              {((pIndex + 1) != item?.providerData?.length) && ", "}</span>
                                          ))
                                        }
                                      </div>
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex justify-content-xl-center align-items-center gap-2 fs-sm-12'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                        <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                      </Alert>
                                    </Col>
                                    <Col className='col-6 col-md-2 d-flex justify-content-xl-center'>
                                      {
                                        (item?.returnOrderId == 0) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={
                                              (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                (["seller-accepted", "provider-accepted"].includes(item?.orderStatus)) ? "primary" :
                                                  (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                    (["provider-completed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                      (["seller-cancelled", "provider-cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                        (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                          "secondary"
                                            }
                                          >
                                            {item?.orderStatus.replace("-", " ")}
                                          </Alert>
                                          : <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                            variant={"danger"}
                                          >
                                            Order in return
                                          </Alert>
                                      }
                                    </Col>
                                    <Col className='col-6 col-md-2 custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-evenly gap-3'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                                              <>
                                                {

                                                  (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                                                  < Dropdown.Item className='text-success'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-accepted",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><MdOutlineDone /> Accept Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item className='text-danger'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-cancelled",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><IoCloseOutline /> Cancel Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["seller-cancelled"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item disabled={true} className='py-0'>
                                                    <span className='fs-14 text-danger'>You cancelled this order.</span>
                                                  </Dropdown.Item>
                                                }
                                              </>
                                              :
                                              <Dropdown.Item disabled={true} className='py-0'>
                                                <span className='fs-12'>Wait untill provider change</span>
                                              </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
                                        state={{ data: item }}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </>
                    }
                  </Container>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={orderCategory[2]?.group} title={orderCategory[2]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                      <Container fluid className='custom-seller-category-search px-0'>
                        <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                          <label className='bi bi-search' htmlFor='text'></label>
                          <input
                            type="text"
                            className='py-2 fs-sm-14 pe-2'
                            id='text'
                            placeholder='Search by order ID'
                            autoComplete='off'
                            onChange={(e) => {
                              setFilters((prevData) => ({
                                ...prevData,
                                search: e?.target?.value
                              }))
                              setLoader(true);
                            }}
                          />
                        </div>
                      </Container>
                      <div className='d-md-none'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowFilter(!showFilter)}
                      ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                      {/* Filters */}
                      <Modal
                        show={showFilter}
                        onHide={() => setShowFilter(!showFilter)}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filter Invoices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='d-grid gap-3'>
                            {
                              (filterList?.parentData?.length > 0) &&
                              filterList?.parentData?.map((item, index) => {
                                return (
                                  <div key={index} className={`d-grid w-100 custom-select-option`}>
                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                      <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                        {item.label}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                        {
                                          item?.data.map((subItem, subIndex) => {
                                            return (
                                              <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                <input type="checkbox"
                                                  className='ui-checkbox'
                                                  name={subItem?.label}
                                                  id={subItem?.label}
                                                  value={subItem?.value}
                                                  onChange={() => {
                                                    handleCheckOrder({
                                                      value: subItem?.value,
                                                      title: item?.title
                                                    })
                                                    setLoader(true);
                                                  }} />

                                                <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                              </Dropdown.Item>
                                            )
                                          })
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowFilter(false)}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className='d-none d-md-grid gap-2'>
                      <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                      <div className='d-none d-md-flex'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => {
                                                handleCheckOrder({
                                                  value: subItem?.value,
                                                  title: item?.title
                                                })
                                                setLoader(true);
                                              }} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </>

                  <Container fluid className='custom-order-list'>
                    <Row className='d-none d-md-flex text-secondary-emphasis fs-12 fw-semibold custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0 overflow-hidden bg-body-tertiary'>
                      <Col className='col-2 flex-between-align gap-2'>
                        <span className='w-100 '> Order ID</span>
                        <span className='w-100'>Date</span>
                      </Col>
                      <Col className='col-2 col-xl-1'>Customer</Col>
                      <Col className='d-none d-xl-block col-xl-1'>Mobile</Col>
                      <Col className='col-2 col-xl-1'>Order Value</Col>
                      <Col className='col-2 text-xl-center'>Provider</Col>
                      <Col className='col-xl-1 d-none text-center d-xl-block'>Payment</Col>
                      <Col className='col-2 text-xl-center'>Status</Col>
                      <Col className='col-2 text-center'>Action</Col>
                    </Row>
                    {/* Order List Starts here... */}
                    {
                      loader ?
                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                          <Loader />
                        </div>
                        : (!orders?.length > 0)
                          ? <div className='py-5'>
                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                              <div>
                                <GiNotebook />
                              </div>
                              <h5 className='fw-semibold'>No invoices yet</h5>
                              <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                            </div>
                          </div>
                          :
                          <>
                            {
                              orders.map((item, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                    style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                  >
                                    {/* <Col className='col-12 col-md-1 d-flex gap-2'>
                                <span className='d-md-none'>Order No. : </span>
                                {
                                  (parseInt(item?.orderId?.length) > 5) ?
                                    <span>#{item?.orderId.toString().slice(0, 3) + '...'}</span>
                                    : <span>#{item?.orderId}</span>
                                }
                              </Col> */}
                                    <Col className='col-12 col-md-2 d-flex gap-2'>
                                      <div className='w-100'>
                                        <span className='d-md-none'>ID : </span>
                                        {/* {
                                          (parseInt(item?.id?.length) > 5) ?
                                            `#${item?.id.toString().slice(0, 3) + '...'}`
                                            : `#${item?.id}`
                                        } */}
                                        #{item?.orderNumber}
                                      </div>
                                      <div className='w-100'>
                                        <span className='d-md-none'>Date : </span>
                                        <span className='fs-12'>{moment(item?.orderDate).format('DD/MM/YYYY')}</span>
                                      </div>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Customer : </span>
                                      {
                                        (item?.userData?.name) ?
                                          (item?.userData?.name?.length > 15) ?
                                            <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                            : <span>{item?.userData?.name}</span>
                                          : <span className='text-secondary fs-14'>--Not Available--</span>
                                      }
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex align-items-center gap-2 fs-sm-12'>
                                      {/* <span className='d-md-none fw-semibold fs-12'>Value : </span> */}
                                      <span className='fs-14'>{item?.userData?.mobile}</span>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.orderNetAmount}</span>
                                    </Col>
                                    <Col className='col-12 col-md-2 d-flex flex-wrap align-items-center justify-content-xl-center gap-1'>
                                      <span className='d-md-none fw-semibold fs-12'>Provider : </span>
                                      <div className='d-flex flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10 justify-content-xl-center'>
                                        {
                                          item?.providerData.map((provider, pIndex) => (
                                            <span key={pIndex} className='text-center'>{provider?.providerBusinessName} X {provider?.totalProduct}
                                              {((pIndex + 1) != item?.providerData?.length) && ", "}</span>
                                          ))
                                        }
                                      </div>
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex justify-content-xl-center align-items-center gap-2 fs-sm-12'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                        <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                      </Alert>
                                    </Col>
                                    <Col className='col-6 col-md-2 d-flex justify-content-xl-center'>
                                      {
                                        (item?.returnOrderId == 0) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={
                                              (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                (["seller-accepted", "provider-accepted"].includes(item?.orderStatus)) ? "primary" :
                                                  (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                    (["provider-completed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                      (["seller-cancelled", "provider-cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                        (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                          "secondary"
                                            }
                                          >
                                            {item?.orderStatus.replace("-", " ")}
                                          </Alert>
                                          : <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                            variant={"danger"}
                                          >
                                            Order in return
                                          </Alert>
                                      }
                                    </Col>
                                    <Col className='col-6 col-md-2 custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-evenly gap-3'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                                              <>
                                                {

                                                  (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                                                  < Dropdown.Item className='text-success'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-accepted",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><MdOutlineDone /> Accept Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item className='text-danger'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-cancelled",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><IoCloseOutline /> Cancel Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["seller-cancelled"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item disabled={true} className='py-0'>
                                                    <span className='fs-14 text-danger'>You cancelled this order.</span>
                                                  </Dropdown.Item>
                                                }
                                              </>
                                              :
                                              <Dropdown.Item disabled={true} className='py-0'>
                                                <span className='fs-12'>Wait untill provider change</span>
                                              </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
                                        state={{ data: item }}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </>
                    }
                  </Container>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={orderCategory[3]?.group} title={orderCategory[3]?.group}>

                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                      <Container fluid className='custom-seller-category-search px-0'>
                        <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                          <label className='bi bi-search' htmlFor='text'></label>
                          <input
                            type="text"
                            className='py-2 fs-sm-14 pe-2'
                            id='text'
                            placeholder='Search by order ID'
                            autoComplete='off'
                            onChange={(e) => {
                              setFilters((prevData) => ({
                                ...prevData,
                                search: e?.target?.value
                              }))
                              setLoader(true);
                            }}
                          />
                        </div>
                      </Container>
                      <div className='d-md-none'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowFilter(!showFilter)}
                      ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                      {/* Filters */}
                      <Modal
                        show={showFilter}
                        onHide={() => setShowFilter(!showFilter)}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filter Invoices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='d-grid gap-3'>
                            {
                              (filterList?.parentData?.length > 0) &&
                              filterList?.parentData?.map((item, index) => {
                                return (
                                  <div key={index} className={`d-grid w-100 custom-select-option`}>
                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                      <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                        {item.label}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                        {
                                          item?.data.map((subItem, subIndex) => {
                                            return (
                                              <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                <input type="checkbox"
                                                  className='ui-checkbox'
                                                  name={subItem?.label}
                                                  id={subItem?.label}
                                                  value={subItem?.value}
                                                  onChange={() => {
                                                    handleCheckOrder({
                                                      value: subItem?.value,
                                                      title: item?.title
                                                    })
                                                    setLoader(true);
                                                  }} />

                                                <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                              </Dropdown.Item>
                                            )
                                          })
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowFilter(false)}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className='d-none d-md-grid gap-2'>
                      <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                      <div className='d-none d-md-flex'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => {
                                                handleCheckOrder({
                                                  value: subItem?.value,
                                                  title: item?.title
                                                })
                                                setLoader(true);
                                              }} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </>

                  <Container fluid className='custom-order-list'>
                    <Row className='d-none d-md-flex text-secondary-emphasis fs-12 fw-semibold custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0 overflow-hidden bg-body-tertiary'>
                      <Col className='col-2 flex-between-align gap-2'>
                        <span className='w-100 '> Order ID</span>
                        <span className='w-100'>Date</span>
                      </Col>
                      <Col className='col-2 col-xl-1'>Customer</Col>
                      <Col className='d-none d-xl-block col-xl-1'>Mobile</Col>
                      <Col className='col-2 col-xl-1'>Order Value</Col>
                      <Col className='col-2 text-xl-center'>Provider</Col>
                      <Col className='col-xl-1 d-none text-center d-xl-block'>Payment</Col>
                      <Col className='col-2 text-xl-center'>Status</Col>
                      <Col className='col-2 text-center'>Action</Col>
                    </Row>
                    {/* Order List Starts here... */}
                    {
                      loader ?
                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                          <Loader />
                        </div>
                        : (!orders?.length > 0)
                          ? <div className='py-5'>
                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                              <div>
                                <GiNotebook />
                              </div>
                              <h5 className='fw-semibold'>No invoices yet</h5>
                              <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                            </div>
                          </div>
                          :
                          <>
                            {
                              orders.map((item, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                    style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                  >
                                    <Col className='col-12 col-md-2 d-flex gap-2'>
                                      <div className='w-100'>
                                        <span className='d-md-none'>ID : </span>
                                        {
                                          (parseInt(item?.id?.length) > 5) ?
                                            `#${item?.id.toString().slice(0, 3) + '...'}`
                                            : `#${item?.id}`
                                        }
                                      </div>
                                      <div className='w-100'>
                                        <span className='d-md-none'>Date : </span>
                                        <span className='fs-12'>{moment(item?.orderDate).format('DD/MM/YYYY')}</span>
                                      </div>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Customer : </span>
                                      {
                                        (item?.userData?.name) ?
                                          (item?.userData?.name?.length > 15) ?
                                            <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                            : <span>{item?.userData?.name}</span>
                                          : <span className='text-secondary fs-14'>--Not Available--</span>
                                      }
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex align-items-center gap-2 fs-sm-12'>
                                      {/* <span className='d-md-none fw-semibold fs-12'>Value : </span> */}
                                      <span className='fs-14'>{item?.userData?.mobile}</span>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.orderNetAmount}</span>
                                    </Col>
                                    <Col className='col-12 col-md-2 d-flex flex-wrap align-items-center justify-content-xl-center gap-1'>
                                      <span className='d-md-none fw-semibold fs-12'>Provider : </span>
                                      <div className='d-flex flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10 justify-content-xl-center'>
                                        {
                                          item?.providerData.map((provider, pIndex) => (
                                            <span key={pIndex} className='text-center'>{provider?.providerBusinessName} X {provider?.totalProduct}
                                              {((pIndex + 1) != item?.providerData?.length) && ", "}</span>
                                          ))
                                        }
                                      </div>
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex justify-content-xl-center align-items-center gap-2 fs-sm-12'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                        <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                      </Alert>
                                    </Col>
                                    <Col className='col-6 col-md-2 d-flex justify-content-xl-center'>
                                      {
                                        (item?.returnOrderId == 0) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={
                                              (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                (["seller-accepted", "provider-accepted"].includes(item?.orderStatus)) ? "primary" :
                                                  (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                    (["provider-completed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                      (["seller-cancelled", "provider-cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                        (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                          "secondary"
                                            }
                                          >
                                            {item?.orderStatus.replace("-", " ")}
                                          </Alert>
                                          : <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                            variant={"danger"}
                                          >
                                            Order in return
                                          </Alert>
                                      }
                                    </Col>
                                    <Col className='col-6 col-md-2 custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-evenly gap-3'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                                              <>
                                                {

                                                  (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                                                  < Dropdown.Item className='text-success'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-accepted",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><MdOutlineDone /> Accept Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item className='text-danger'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-cancelled",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><IoCloseOutline /> Cancel Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["seller-cancelled"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item disabled={true} className='py-0'>
                                                    <span className='fs-14 text-danger'>You cancelled this order.</span>
                                                  </Dropdown.Item>
                                                }
                                              </>
                                              :
                                              <Dropdown.Item disabled={true} className='py-0'>
                                                <span className='fs-12'>Wait untill provider change</span>
                                              </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
                                        state={{ data: item }}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </>
                    }
                  </Container>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={orderCategory[4]?.group} title={orderCategory[4]?.group}>
                <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>
                  {/* Sub Category */}
                  <div className='d-flex pt-2 pt-sm-3'>
                    <OrderSubCategoryList
                      listData={(orderCategory?.length > 0 && tabHandler?.groupStatus) && [
                        { label: "All", value: "all" },
                        ...orderCategory.find((e) => e?.group == tabHandler?.groupStatus)?.data
                      ]}
                      onTabHandleChange={tabHandler?.groupStatus}
                      onCategoryChange={(e) => {
                        console.log("e::", e);
                        navigate(`/orders/${tabHandler?.groupStatus}/${e}`)
                        setTabHandler((prevData) => ({
                          ...prevData,
                          orderStatus: e
                        }))
                      }}
                    />
                  </div>

                  {/* Custom Filtration */}
                  <>
                    <div className='d-flex align-items-center gap-2 d-md-grid'>
                      <Container fluid className='custom-seller-category-search px-0'>
                        <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                          <label className='bi bi-search' htmlFor='text'></label>
                          <input
                            type="text"
                            className='py-2 fs-sm-14 pe-2'
                            id='text'
                            placeholder='Search by order ID'
                            autoComplete='off'
                            onChange={(e) => {
                              setFilters((prevData) => ({
                                ...prevData,
                                search: e?.target?.value
                              }))
                              setLoader(true);
                            }}
                          />
                        </div>
                      </Container>
                      <div className='d-md-none'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowFilter(!showFilter)}
                      ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                      {/* Filters */}
                      <Modal
                        show={showFilter}
                        onHide={() => setShowFilter(!showFilter)}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filter Invoices</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='d-grid gap-3'>
                            {
                              (filterList?.parentData?.length > 0) &&
                              filterList?.parentData?.map((item, index) => {
                                return (
                                  <div key={index} className={`d-grid w-100 custom-select-option`}>
                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                      <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                        {item.label}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                        {
                                          item?.data.map((subItem, subIndex) => {
                                            return (
                                              <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                <input type="checkbox"
                                                  className='ui-checkbox'
                                                  name={subItem?.label}
                                                  id={subItem?.label}
                                                  value={subItem?.value}
                                                  onChange={() => {
                                                    handleCheckOrder({
                                                      value: subItem?.value,
                                                      title: item?.title
                                                    })
                                                    setLoader(true);
                                                  }} />

                                                <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                              </Dropdown.Item>
                                            )
                                          })
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowFilter(false)}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className='d-none d-md-grid gap-2'>
                      <span className='fs-14 ps-2 ms-1'>Sort By:</span>
                      <div className='d-none d-md-flex'>
                        {
                          (filterList?.parentData?.length > 0) &&
                          filterList?.parentData?.map((item, index) => {
                            return (
                              <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1) ? 'w-25' : 'w-100'} custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                  <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                    {item.label}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='w-100 gap-1 check-holder'>
                                    {
                                      item?.data.map((subItem, subIndex) => {
                                        return (
                                          <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                            <input type="checkbox"
                                              className='ui-checkbox'
                                              name={subItem?.label}
                                              id={subItem?.label}
                                              value={subItem?.value}
                                              onChange={() => {
                                                handleCheckOrder({
                                                  value: subItem?.value,
                                                  title: item?.title
                                                })
                                                setLoader(true);
                                              }} />

                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </>

                  <Container fluid className='custom-order-list'>
                    <Row className='d-none d-md-flex text-secondary-emphasis fs-12 fw-semibold custom-order-list-filter-bar py-3 flex-between-align mb-2 mb-md-0 overflow-hidden bg-body-tertiary'>
                      <Col className='col-2 flex-between-align gap-2'>
                        <span className='w-100 '> Order ID</span>
                        <span className='w-100'>Date</span>
                      </Col>
                      <Col className='col-2 col-xl-1'>Customer</Col>
                      <Col className='d-none d-xl-block col-xl-1'>Mobile</Col>
                      <Col className='col-2 col-xl-1'>Order Value</Col>
                      <Col className='col-2 text-xl-center'>Provider</Col>
                      <Col className='col-xl-1 d-none text-center d-xl-block'>Payment</Col>
                      <Col className='col-2 text-xl-center'>Status</Col>
                      <Col className='col-2 text-center'>Action</Col>
                    </Row>
                    {/* Order List Starts here... */}
                    {
                      loader ?
                        <div className='w-100 aspect-4-1 aspect-md-2-1'>
                          <Loader />
                        </div>
                        : (!orders?.length > 0)
                          ? <div className='py-5'>
                            <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                              <div>
                                <GiNotebook />
                              </div>
                              <h5 className='fw-semibold'>No invoices yet</h5>
                              <p className='p-color fs-12'>Invoices will appear here after you fulfill orders.</p>
                            </div>
                          </div>
                          :
                          <>
                            {
                              orders.map((item, index) => {
                                return (
                                  <Row
                                    key={index}
                                    className='custom-order-list-bar-item py-3 flex-between-align gy-2 gy-md-0 mb-2 mb-md-0'
                                    style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                  >
                                    <Col className='col-12 col-md-2 d-flex gap-2'>
                                      <div className='w-100'>
                                        <span className='d-md-none'>ID : </span>
                                        {
                                          (parseInt(item?.id?.length) > 5) ?
                                            `#${item?.id.toString().slice(0, 3) + '...'}`
                                            : `#${item?.id}`
                                        }
                                      </div>
                                      <div className='w-100'>
                                        <span className='d-md-none'>Date : </span>
                                        <span className='fs-12'>{moment(item?.orderDate).format('DD/MM/YYYY')}</span>
                                      </div>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Customer : </span>
                                      {
                                        (item?.userData?.name) ?
                                          (item?.userData?.name?.length > 15) ?
                                            <span>{item?.userData?.name.toString().slice(0, 3) + '...'}</span>
                                            : <span>{item?.userData?.name}</span>
                                          : <span className='text-secondary fs-14'>--Not Available--</span>
                                      }
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex align-items-center gap-2 fs-sm-12'>
                                      {/* <span className='d-md-none fw-semibold fs-12'>Value : </span> */}
                                      <span className='fs-14'>{item?.userData?.mobile}</span>
                                    </Col>
                                    <Col className='col-6 col-md-2 col-xl-1 d-flex align-items-center gap-2 fs-sm-12'>
                                      <span className='d-md-none fw-semibold fs-12'>Value : </span>
                                      <span>₹{item?.orderNetAmount}</span>
                                    </Col>
                                    <Col className='col-12 col-md-2 d-flex flex-wrap align-items-center justify-content-xl-center gap-1'>
                                      <span className='d-md-none fw-semibold fs-12'>Provider : </span>
                                      <div className='d-flex flex-wrap gap-2 m-0 p-0 fs-12 fs-sm-10 justify-content-xl-center'>
                                        {
                                          item?.providerData.map((provider, pIndex) => (
                                            <span key={pIndex} className='text-center'>{provider?.providerBusinessName} X {provider?.totalProduct}
                                              {((pIndex + 1) != item?.providerData?.length) && ", "}</span>
                                          ))
                                        }
                                      </div>
                                    </Col>
                                    <Col className='col-xl-1 d-none d-xl-flex justify-content-xl-center align-items-center gap-2 fs-sm-12'>
                                      <Alert
                                        className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                        variant={(item?.orderPaymentMode == "COD") ? "warning" : "success"}>
                                        <span>{(item?.orderPaymentMode == "COD") ? item?.orderPaymentMode : "Prepaid"}</span>
                                      </Alert>
                                    </Col>
                                    <Col className='col-6 col-md-2 d-flex justify-content-xl-center'>
                                      {
                                        (item?.returnOrderId == 0) ?
                                          <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-12 fs-sm-10'
                                            variant={
                                              (["delivered"].includes(item?.orderStatus)) ? "success" :
                                                (["seller-accepted", "provider-accepted"].includes(item?.orderStatus)) ? "primary" :
                                                  (["pending"].includes(item?.orderStatus)) ? "warning" :
                                                    (["provider-completed", "dispatch", "on-way"].includes(item?.orderStatus)) ? "info" :
                                                      (["seller-cancelled", "provider-cancelled", "user-cancelled"].includes(item?.orderStatus)) ? "danger" :
                                                        (["provider-packed"].includes(item?.orderStatus)) ? "secondary" :
                                                          "secondary"
                                            }
                                          >
                                            {item?.orderStatus.replace("-", " ")}
                                          </Alert>
                                          : <Alert
                                            className='m-0 text-center fw-semibold text-capitalize py-1 px-2 px-sm-3 fs-14 fs-sm-12'
                                            variant={"danger"}
                                          >
                                            Order in return
                                          </Alert>
                                      }
                                    </Col>
                                    <Col className='col-6 col-md-2 custom-order-dropdown d-flex align-items-center justify-content-end justify-content-md-evenly gap-3'>
                                      <Dropdown className="p-0">
                                        <Dropdown.Toggle id="dropdown-basic" className='px-2 py-1' variant='secondary' disabled={item?.returnOrderId != 0}>
                                          <span className='fs-16'>
                                            <BiSolidEdit />
                                          </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            (["pending", "seller-accepted", "seller-cancelled"].includes(item?.orderStatus)) ?
                                              <>
                                                {

                                                  (["pending", !"seller-accepted"].includes(item?.orderStatus)) &&
                                                  < Dropdown.Item className='text-success'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-accepted",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><MdOutlineDone /> Accept Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["pending", !"seller-cancelled", "seller-accepted"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item className='text-danger'
                                                    onClick={() => {
                                                      handleOrderStatus({
                                                        value: "seller-cancelled",
                                                        id: item?.orderId
                                                      })
                                                      setLoader(true);
                                                    }}
                                                  >
                                                    <span><IoCloseOutline /> Cancel Order</span>
                                                  </Dropdown.Item>
                                                }
                                                {
                                                  (["seller-cancelled"].includes(item?.orderStatus)) &&
                                                  <Dropdown.Item disabled={true} className='py-0'>
                                                    <span className='fs-14 text-danger'>You cancelled this order.</span>
                                                  </Dropdown.Item>
                                                }
                                              </>
                                              :
                                              <Dropdown.Item disabled={true} className='py-0'>
                                                <span className='fs-12'>Wait untill provider change</span>
                                              </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="More Details"
                                        variant='success'
                                        className='more-btn'
                                        to={(params?.groupStatus) ? `/orders/${tabHandler}/${item?.orderId}` : `${item?.orderId}`}
                                        state={{ data: item }}
                                      >
                                        <TbFileInvoice />
                                      </Link>
                                      <Tooltip id="my-tooltip" place="top" />
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </>
                    }
                  </Container>
                  <CommonPagination
                    totalCount={totalList}
                    changePage={(data) => {
                      setFilters((prevData) => ({
                        ...prevData,
                        limit: data?.limit,
                        page: data?.page
                      }))
                    }}
                  />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        </div>
      </Container >

      {/* courier list modal start */}
      <Modal
        show={courierModalStatus}
        onHide={() => setCourierModalStatus(!courierModalStatus)}
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        // className='mh-75'
        style={{
          maxHeight:'75vh'
        }}
        centered
        scrollable
      >
        <Modal.Header className='d-flex flex-column'>
          <Modal.Title className='text-center w-100 fs-sm-14'>Select Courier</Modal.Title>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <div className='d-flex flex-column jusity-content-start align-items-start'>
              <label className='fs-12 fw-bold text-secondary'>Order Number</label>
              <div className='fs-12 fw-bold text-success'>{selectedOrder?.orderNumber}</div>
            </div>
            <div className='d-flex flex-column jusity-content-end align-items-start'>
              <div className='d-flex flex-column jusity-content-end align-items-end'>
                <label className='fs-12 fw-bold text-secondary'>Order Date</label>
                <div className='fs-12 fw-bold text-success'>{selectedOrder?.orderDate}</div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='custom-modal-radio-select'>
            <div className="custom-radio-group">
            {
              courierData?.map((row, index) => <>
                <Card
                  className={`pointer border ${selectedOrder?.orderCourierCompanyId == row?.id ? 'border-2 border-success' : 'border-secondary'} rounded-2 py-1 px-2 position-relative`}
                  onClick={() => {
                    selectedOrder.orderCourierCompanyId = row?.id
                    selectedOrder.shippingCharge = row?.rate
                    selectedOrder.orderCourierDetail = row
                    setSelectedOrder({...selectedOrder})
                  }}
                >
                  <label className='position-absolute top-0 start-0 translate-middle'>
                    <Input
                      type='radio'
                      className={selectedOrder?.orderCourierCompanyId != row?.id ? 'd-none' : ''}
                      checked={selectedOrder?.orderCourierCompanyId == row?.id}
                    />
                  </label>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='fs-14 fw-bold'>{row?.courierName}</div>
                    <div className='d-flex gap-1'>
                      <div className='d-flex flex-column align-items-end text-secondary'>
                      {
                        row?.codCharges > 0 && (
                          <>
                            <div className='fs-12 fw-bold'>{row?.codCharges?.toFixed(2)}</div>
                            <div className='fs-10'>COD Charges</div>
                          </>
                        )
                      }
                      </div>
                      <div className='fs-14 bg-success px-3 py-1 rounded-2 text-white text-center'>{row?.rate}</div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center py-1'>
                    <div className='d-flex flex-column jusity-content-end align-items-start'>
                      <label className='fs-12 fw-bold text-secondary'>Estimated Date</label>
                      <div className='fs-12 fw-bold text-success'>{row?.estimatedDate}</div>
                    </div>
                    <div className='d-flex flex-column jusity-content-end align-items-end'>
                      <label className='fs-12 fw-bold text-secondary'>Estimated Days</label>
                      <div className='fs-12 fw-bold text-success'>{row?.estimatedDays}</div>
                    </div>
                  </div>
                </Card>
                <label key={index} className="custom-radio-container d-none">
                  <input
                      type="radio"
                      name="custom-radio"
                      defaultValue="option2"
                  />
                  <span className="custom-radio-checkmark" />
                  <div className='d-grid w-100 flex-wrap gap-1 fs-14 fs-sm-12 text-uppercase'>
                      {/* <span className='d-flex align-items-center flex-wrap gap-2 gap-sm-2'><BsBank /> {item?.bankName} <span className='d-flex gap-2'> - <span>{item?.accountNumber}</span></span></span>
                      <span className='d-flex align-items-center gap-2'><FaUser /> {item?.accountHolderName}</span> */}
                  </div>
                </label>
              </>)
            }
            </div>
        </Modal.Body>
        <Modal.Footer className='flex-center-align'>
            <Button
              variant="success"
              onClick={() => {
                if (selectedOrder?.orderCourierCompanyId > 0) {
                  handleOrderStatus(selectedOrder)
                }
              }}
              disabled={selectedOrder?.orderCourierCompanyId < 1}
            >
              Applied
            </Button>
            <Button variant="secondary" onClick={() => setCourierModalStatus(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      {/* courier list modal close */}
    </div >
  )
}

export default SellerOrders
